import React, { FC, useId, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import moment from "moment";
import { Stack } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { setTrainTravelers, updateTrainListFiltersFrontCriteria, updateTrainListLoaded, updateTrainListLoading, updateTrainListOutbound, updateTrainListReturn, updateTrainListVisibleItem } from "./redux/reducer";
import { searchNextDate } from "./utils/dates/searchNextDate";
import { combineDateAndTimeWithLocalTimezone } from "./utils/dates/combineDateAndTime";
import { useTranslation } from "react-i18next";
import { getTrainFilterCriteria } from "./utils/mapping/getTrainCriteria";
import { getTrainListForUi } from "./utils/mapping/getTrainForUI";
import axios from "axios";
import { searchTrains } from "./services/trainServices";
import { useShowError } from "../Utils/showError";
import { TrainListFiltersFrontCriteria } from "./objects/trainList";

const useStyles = makeStyles((theme) => ({
    dayPicker: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        scrollSnapType: 'x mandatory',
        height: '60px',
        borderRadius: '0.6rem',
        border: '0.5px solid rgba(0, 0, 0, 0.3)',
        // width: '150px',
        flex: '0 1 14%',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        },
        '&:before': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            width: '20px',
            height: '4px',
            backgroundColor: 'white',
            zIndex: 1,
            left: 'calc(50% - 10px)',
        }

    },
    dayPickerSelected: {
        backgroundColor: 'rgb(20, 112, 138) !important',
        color: 'white',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(20, 112, 138, 0.9)'
        }
    },
    dayPickerDisabled: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        cursor: 'auto',
    }
}));

export const TrainListDayPicker: FC<{ is_departure?: boolean; minDate?: Date }> = ({ is_departure, minDate }) => {
    const showError = useShowError();
    const { i18n } = useTranslation();
    moment.locale(i18n.language);
    const id = useId();
    const classes = useStyles();
    const dispatch = useDispatch();
    const selected_date = useSelector((state: AppState) => is_departure ? state.train.train_list_criteria.filters.departure : state.train.train_list_criteria.filters.return);
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const min_date = minDate || new Date();
    const available_date = useMemo(() => {
        const baseDate = moment(selected_date);
        let maxIndex = 3;
        while ((baseDate.isAfter(moment(min_date), 'day')) && maxIndex > 0) {
            baseDate.add(-1, 'days');
            maxIndex--;
        }

        const dates = Array.from({ length: 7 }, (_, i) => baseDate.clone().add(i, 'days'));
        return dates;
    }, [selected_date]);

    const onSearch = async (params: {
        departure?: Date,
        return_hour?: string,
        return_date?: Date
    }) => {
        try {
            const { departure, return_hour, return_date } = params;
            dispatch(updateTrainListLoading(true));
            const searchParams = getTrainFilterCriteria({
                ...train_list_filters_criteria,
                departure: departure ?? train_list_filters_criteria.departure,
                return_hour: return_hour ?? train_list_filters_criteria.return_hour,
                return: return_date ?? train_list_filters_criteria.return
            });
            const requests = searchParams.map(params => searchTrains(params));
            const responses = await Promise.all(requests);
            const data = responses[0]?.data; // TODO : update when new provider arrive
            // const setListAction = !is_departure ? updateTrainListReturn : updateTrainListOutbound;
            const { train_trip_departure, train_trip_return, travelers } = getTrainListForUi(data);
            dispatch(updateTrainListOutbound(train_trip_departure));
            dispatch(updateTrainListReturn(train_trip_return ?? []));
            dispatch(setTrainTravelers(travelers));
            dispatch(updateTrainListLoaded(true));
            dispatch(updateTrainListVisibleItem(5));
            dispatch(updateTrainListLoading(false));
        } catch (error) {
            if (!axios.isCancel(error)) {
                showError(error as Error);
                console.error(error);
                dispatch(updateTrainListLoading(false));
            }
        }
    };

    const updateFilters = (updates: Partial<TrainListFiltersFrontCriteria>) => {
        dispatch(updateTrainListFiltersFrontCriteria({
            ...train_list_filters_criteria,
            ...updates
        }));
    };

    const updateDate = (date: moment.Moment) => {
        if (is_departure) {
            if (train_list_filters_criteria.return) {
                const { return_hour, return_date } = searchNextDate(date.toDate(), train_list_filters_criteria.departure_hour);
                if (moment(combineDateAndTimeWithLocalTimezone(train_list_filters_criteria.return, train_list_filters_criteria.return_hour)).isBefore(moment(combineDateAndTimeWithLocalTimezone(date.toDate(), train_list_filters_criteria.departure_hour)))) {
                    onSearch({ departure: date.toDate(), return_hour, return_date });
                    updateFilters({ departure: date.toDate(), return_hour: return_hour, return: return_date });
                } else {
                    onSearch({ departure: date.toDate() });
                    updateFilters({ departure: date.toDate() });
                }
            } else {
                onSearch({ departure: date.toDate() });
                updateFilters({ departure: date.toDate() });
            }
        }
        // else {
        //     onSearch({
        //         return_date: date.toDate()
        //     });
        //     updateFilters({ return: date.toDate() });
        // }
    }

    return (<>
        {
            available_date.map((date: moment.Moment) => <Stack onClick={() => updateDate(date)} className={clsx(classes.dayPicker, { [classes.dayPickerSelected]: moment(selected_date).isSame(date, 'day') }, { [classes.dayPickerDisabled]: !is_departure })} key={`${id}-${date.format('DD-MM-YYYY')}`}>{date.format('ddd DD')}</Stack>)
        }
    </>);
};