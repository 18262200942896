import React, { FC, useState } from "react";
import { TrainForm } from "./objects/trainForm";
import { addTrainCart, updateTrainDepartureSelected, updateTrainReturnSelected } from "./redux/reducer";
import { AppState } from "../../Reducers/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useShowError } from "../Utils/showError";
import { Stack, Typography, Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, paperClasses } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { addTrainToCart, createGroupTravelers } from "./services/tripService";
import { createCartPayload } from "./utils/cart/createCartPayload";
import { useSnackbar } from "notistack";
import { browserHistory } from "react-router";

const dialog_sx = {
    [`.${paperClasses.root}`]: {
        borderRadius: '1.5rem !important', // Set your desired border radius
    },
};

interface TrainListContentFormAddToCartProps {
    setTrainFormAction: (train_form: TrainForm | null) => void;
    train_form: TrainForm;
    is_departure: boolean;
    open: boolean;
    onClose: () => void;
}
export const TrainListContentFormAddToCart: FC<TrainListContentFormAddToCartProps> = ({ train_form, is_departure, open, setTrainFormAction, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showError = useShowError();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState<boolean>(false);
    const travelers = useSelector((state: AppState) => state.train.train_list_criteria.filters.travelers);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);

    const onValidate = async () => {
        try {
            setLoading(true);
            const traveler_response = await createGroupTravelers(travelers.map(traveler => traveler.id));
            const train_forms = is_departure? [train_form!]: [train_departure_selected!, train_form!]
            const card_response = await addTrainToCart(createCartPayload(train_forms, traveler_response.data.id));
            dispatch(addTrainCart(card_response.data));
            enqueueSnackbar(t('shared.product-added-to-cart'), { variant: 'success' });
            dispatch(updateTrainReturnSelected(null));
            dispatch(updateTrainDepartureSelected(null));
            dispatch(setTrainFormAction(null));
            browserHistory.push(`/${window.url_name}/apps/cart`);
        } catch (error) {
            enqueueSnackbar(t('shared.product-not-added-to-cart'), { variant: 'success' });
            showError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    return <Dialog maxWidth={!isMobile ? "sm" : undefined} fullScreen={isMobile} fullWidth sx={!isMobile ? dialog_sx : undefined} open={open}>
        {<DialogTitle sx={{ backgroundColor: 'black' }} >
            <Stack justifyContent={'space-between'} direction='row'>
                <Stack><Typography variant="subtitle1" fontSize={20} color="white">{t("shared.add-to-cart")}</Typography></Stack>
                <Button disabled={loading} onClick={onClose}>{t("shared.close")}</Button>
            </Stack>
        </DialogTitle >}
        <DialogContent dividers>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="subtitle1">{t('shared.confirm-add-to-cart')}</Typography>
            </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: 2, backgroundColor: isMobile ? '#F3F3F8' : undefined }}>
            <LoadingButton loading={loading} disabled={loading} variant="contained" className="train-date-validate-button" onClick={onValidate}>{t("shared.validate")}</LoadingButton>
        </DialogActions>
    </Dialog>
};