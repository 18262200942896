import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, {FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../Reducers/Reducers';
import { deleteTrainListFlitersFrontReturn, addTrainListFlitersFrontReturn } from './redux/reducer';
import { Add, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import * as date_locales from 'date-fns/locale';
import { TrainSearchDatesRangePicker } from './trainSearchDatesRangePicker';
import { combineDateAndTimeWithLocalTimezone } from './utils/dates/combineDateAndTime';
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    closeIconButton: {
        width: '15px !important', height: '15px !important'
        // [theme.breakpoints.down('sm')]: {width: '15px !important', height: '15px !important'},
    },
    stackContentResponsive: {
        gap: theme.spacing(0),
        flexDirection: 'row  !important' as unknown as undefined,
        flex:'0 1 30%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          flex:'0 1 100%',
        },
      },
}));

export const TrainSearchDates: FC<{return_required?: boolean}> = ({return_required}) => {
    const locales_cpy : { [key: string]: any } = date_locales;
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const [is_date_modal_open, setIsDateModalOpen] = useState<boolean>(false);

    const deleteReturn = (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
        dispatch(deleteTrainListFlitersFrontReturn());
    };
    const addReturn = () => {
        setIsDateModalOpen(true);
        dispatch(addTrainListFlitersFrontReturn());
    };

    const onCloseModal = () => {
        setIsDateModalOpen(false);
    };
    const changeDeparturetDate = () => {
        setIsDateModalOpen(true);
    };
    const changeReturnDate = () => {
        setIsDateModalOpen(true);
    };

    return  (<>
        <Stack flex={'0 1 50%'} >
            <Button className='date-criteria' onClick={changeDeparturetDate}>
                <Typography variant='caption' sx={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600}}>{t('global.go')} :</Typography> 
                <Typography variant='caption' sx={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600, whiteSpace: 'nowrap'}}>{format(combineDateAndTimeWithLocalTimezone(train_list_filters_criteria.departure, train_list_filters_criteria.departure_hour) as Date, "eee dd MMM, HH'h'", {locale: locales_cpy[i18n.language]}) }</Typography>
            </Button>
        </Stack>
        <Stack  flex={'0 1 50%'}>
            {
                !train_list_filters_criteria.can_return ?
                <Button className='return-date-criteria' startIcon={<Add sx={{color: 'rgba(0, 0, 0, 0.6)'}}/>} onClick={addReturn}>{t('shared.add-return')}</Button>
                : <Button className='return-date-criteria' onClick={changeReturnDate}>
                    <Stack style={{width: '100%', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Typography variant='caption' sx={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600}}>{ t('global.Return')} :</Typography>
                        <Typography variant='caption' sx={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600, whiteSpace: 'nowrap'}}>{format(combineDateAndTimeWithLocalTimezone(train_list_filters_criteria.return, train_list_filters_criteria.return_hour) as Date, "eee dd MMM, HH'h'", {locale: locales_cpy[i18n.language]}) }</Typography>
                    </Stack>
                    {!return_required && <IconButton size="small" sx={{padding: 0}} onClick={deleteReturn}>
                        <Close className={classes.closeIconButton}/>
                    </IconButton>}
                </Button>
            }
        </Stack>

        <TrainSearchDatesRangePicker is_open={is_date_modal_open} onClose={onCloseModal} return_required={return_required}/>
    </>);
    
}