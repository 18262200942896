import GoogleMapsLoader from "google-maps";

let google: GoogleMapsLoader.google;

let key = "";
const config = JSON.parse(localStorage.getItem("config") ?? '{}');
for (let i = 0; i < config.keys.length; i++) {
    if (config.keys[i].identifier === "google_api") {
        key = config.keys[i].value;
    }
}
GoogleMapsLoader.KEY = key;
GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
GoogleMapsLoader.LANGUAGE = "fr";
GoogleMapsLoader.load(function (google_loaded: GoogleMapsLoader.google) {
    google = google_loaded;
});

export async function getCityFromPlaceId(placeId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const detailsService = new google.maps.places.PlacesService(document.createElement('div'));
        detailsService.getDetails({ placeId, fields: ['address_components'] }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place && place.address_components) {
                const cityComponent = place.address_components.find(component =>
                    component.types.includes('locality')
                );
                resolve(cityComponent ? cityComponent.long_name : '');
            } else {
                resolve('');
            }
        });
    });
}
