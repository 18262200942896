//Dependencies
import { useTranslation } from 'react-i18next';
import React, { useState, Fragment, useEffect } from 'react';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//Components
import CartAccommodationManualCancellationRulesRoom from './CartAccommodationManualCancellationRulesRoom';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

let request_count = 0;

const CartAccommodationManualCancellationRules = ({ accommodation, onManualCancellationRules }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [validate, setValidate] = useState(false);
    const [request, setRequest] = useState(true);

    const onValidateManualCancellationRules = () => {
        setValidate(true);
    };
    const onCancelManualCancellationRules = () => {
        onManualCancellationRules();
    };
    const onRequestDone = () => {
        request_count += 1;
        setRequest(Math.random());
    };
    useEffect(() => {
        if (validate && request_count === accommodation.rooms.length) {
            onManualCancellationRules();
        }
    }, [request]);
    useEffect(() => {
        request_count = 0;
    }, []);
    return (
        <Grid container spacing={ 2 }>
            {
                accommodation.rooms.map((room, room_index) => (
                    <Fragment>
                        <Grid item xs={ 12 }>
                            <br/>
                            <Typography><strong>{ accommodation.hotel && accommodation.hotel[0] && accommodation.hotel[0].type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('global.room') } { room_index + 1 } :</strong> { room.description }</Typography>
                        </Grid>
                        <CartAccommodationManualCancellationRulesRoom room={ room } validate={ validate } onRequestDone={ onRequestDone }/>
                    </Fragment>
                )
                )
            }
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ marginTop: 2 }}>
                    <Button onClick={ onCancelManualCancellationRules }>{ t('global.cancel') }</Button>
                    <Button onClick={ onValidateManualCancellationRules }>{ t('global.validate') }</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartAccommodationManualCancellationRules;
