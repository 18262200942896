import React, { FC, useMemo } from "react";
import { TrainSegment, TrainTrip } from "./objects/train";
import { useTranslation } from "react-i18next";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";
import { Schedule } from "@mui/icons-material";
import { ServiceOffersEntity, SolutionOffer } from "./objects/backendData/trainBackendDate";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { MarketingInformationIcon, MarketingInformationIconPAO } from "./objects/backendData/trainMarketingInformationIcon";
import { ServiceTypeIcons } from "./components/services/serviceTypeIcon";
import { getProviderIcon } from "./utils/provider/getProviderIcon";

const useStyles = makeStyles((theme) => ({
    stackContainerResponsive: {
        flexDirection: 'column',
        flex: '0 1 70%',
        borderRadius: '0.6rem',
        border: '0.5px solid rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }
    },
    priceBox: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: '0 1 15%',
        border: '0.5px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '0.6rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }
    }
}))

export const TrainListItem: FC<{ train_trip: TrainTrip, onSelectFirstClass: (train_trip: TrainTrip, offer: SolutionOffer) => void, onSelectSecondClass: (train_trip: TrainTrip, offer: SolutionOffer) => void }> = ({ train_trip, onSelectFirstClass, onSelectSecondClass }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const currencies = useSelector((state: AppState) => state.train.currencies);

    const offers = useMemo(() => train_trip.solution.offers, [train_trip.solution.offers]);
    const common_available_services = useMemo(() => {
        const allOffers = train_trip?.solution?.offers || [];
        if (allOffers.length === 0) return [];

        const serviceTypeCounts = new Map<string, number>();

        // Count occurrences of each service_type across all offers
        allOffers.forEach((offer) => {
            const uniqueServiceTypes = new Set(offer.service_offers.map(service => service.service_type));
            uniqueServiceTypes.forEach(serviceType => {
                serviceTypeCounts.set(serviceType, (serviceTypeCounts.get(serviceType) || 0) + 1);
            });
        });

        // Collect services that have service_type visible in all offers
        const commonServices: ServiceOffersEntity[] = [];
        allOffers[0]!.service_offers.forEach((service) => {
            if (serviceTypeCounts.get(service.service_type) === allOffers.length) {
                commonServices.push(service);
            }
        });

        return commonServices;
    }, [train_trip?.solution?.offers]);

    const first_price = useMemo(() => offers.length > 0 ? offers[0]?.prices[0] : null, [offers]);
    const second_price = useMemo(() => offers.length > 1 ? offers[1]?.prices[0] : null, [offers]);

    return (<Stack direction='row' spacing={2}>
        <Stack className={classes.stackContainerResponsive} onClick={() => offers.length > 0 && onSelectSecondClass(train_trip, offers[0]!)}>
            <Stack direction={'column'} spacing={1} padding={'1rem'}>
                <Stack direction={'row'} spacing={0.5}>
                    <Typography variant='body2' fontWeight={'bold'} fontSize={'1rem'}>{moment.utc(train_trip.solution.departure).format('HH:mm')}</Typography>
                    <Typography variant='body2' fontSize={'1rem'}>{train_trip.solution.origin.label}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant='body2' fontWeight={'bold'} fontSize={'1rem'}>{moment.utc(train_trip.solution.arrival).format('HH:mm')}</Typography>
                    <Typography variant='body2' fontSize={'1rem'}>{train_trip.solution.destination.label}</Typography>
                </Stack>
            </Stack>
            <Divider />
            <Stack direction='row' spacing={1} sx={{ minHeight: '2.625rem', padding: '0 1rem' }} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                    <Schedule sx={{ width: '15px', height: '15px', color: 'rgb(94, 104, 120)' }} />
                    <Typography variant="caption" sx={{ color: 'rgb(94, 104, 120)', lineHeight: '0 !important' }}>{`${Math.floor(require('moment').duration(train_trip.solution.duration).asHours())}h${require('moment').duration(train_trip.solution.duration).minutes()}m`}</Typography>
                    {train_trip.solution.segments.length === 1 && <>
                        <Typography variant="caption" sx={{ color: 'rgb(94, 104, 120)', lineHeight: '0 !important' }}>{t("shared.direct")} </Typography>
                        <img src={getProviderIcon(train_trip.solution.segments[0]!.marketingInformation, {operatingCarrier: train_trip.solution.segments[0]!.operatingCarrier, marketingCarrier: train_trip.solution.segments[0]!.marketingCarrier})} style={{ height: '1rem' }} />
                    </>}
                </Stack>

                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    {common_available_services.map((service) => {
                        const Icon = ServiceTypeIcons[service.service_type];
                        return <Tooltip key={`offer-service-${service.service_id}-${service.service_type}`} title={t(`train.service.${service.service_type}`)}><Icon sx={{ width: '15px', height: '15px', color: 'rgb(0,0,0,0.3)' }} /></Tooltip>
                    })}
                </Stack>
            </Stack>
        </Stack>
        {!isMobile && <>
            <Stack className={classes.priceBox} onClick={() => offers.length > 0 && onSelectSecondClass(train_trip, offers[0]!)}>{first_price ? new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                minimumFractionDigits: (first_price?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                currency: currencies[first_price.purchase_currency_id]?.iso_code || 'EUR'
            }).format(first_price?.purchase_price ?? 0) : '-'}</Stack>
            <Stack className={classes.priceBox} onClick={() => offers.length > 1 && onSelectFirstClass(train_trip, offers[1]!)}>{second_price ? new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                minimumFractionDigits: (second_price?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                currency: currencies[second_price.purchase_currency_id]?.iso_code || 'EUR'
            }).format(second_price?.purchase_price ?? 0) : '-'}</Stack>
        </>}
    </Stack>);
}