import React, { FC, Fragment, useEffect, useState } from "react";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { Alert, AlertTitle, Button, Chip, Divider, Stack, Tooltip } from '@mui/material';
import { CartConstructionProductsTableItemProviderQuotationStatus } from "../CartMaterial/CartConstructionProductsTableItemProviderQuotationStatus";
import { CartPackageItemChangeProviderQuotationStatusButtons } from "../CartMaterial/CartPackageItemChangeProviderQuotationStatusButtons";
import { useTranslation } from "react-i18next";
import { ArrowDownward, ArrowRightAlt, ArrowUpward, Close, ErrorOutline, ExpandLess, ExpandMore, ReportProblem } from "@mui/icons-material";
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import axios from "axios";
import GetCookie from "../Common/Functions/GetCookie";
import { editTrainCartById, removeTrainCartById } from "./redux/reducer";
import { useCartDetailStyles } from "./utils/cart/cartDetail/cartDetailStyle";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";
import clsx from "clsx";
import CartDeleteModal from "../CartMaterial/CartDeleteModal";
import { useSnackbar } from "notistack";
import { CartTrainNote } from "./trainCartNote";
import { TrainCartManualCancellationRules } from "./trainCartManualCancellationRules";
import { TrainCartCardDetailFare } from "./trainCartCardDetailFare";


interface TrainCartCardDetailProps {
    train: TrainCartFromBackend;
}

export const TrainCartCardDetail: FC<TrainCartCardDetailProps> = ({ train }) => {
    const classes = useCartDetailStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const user = useSelector((state: AppState) => state.user?.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    // const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                traveler_modification: null
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
        }).catch(error => {
            console.log(error);
        });
    };

    const onDelete = () => {
        const { headers } = CheckBeforeRequest();
        if (train.booking_status && train.booking_status.status_booking === 'CANCELLED') {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
                data: {
                    is_displayed: false
                }
            }).then((response) => {
                dispatch(editTrainCartById(response.data));
                enqueueSnackbar(t('cart-material.train-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.train-not-deleted'), { variant: 'error' });
            });
        } else {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`
            }).then(() => {
                dispatch(removeTrainCartById(train.id));
                enqueueSnackbar(t('cart-material.train-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.train-not-deleted'), { variant: 'error' });
            });
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const onAddNote = () => {
        setShowNote(!showNote);
    };

    const onDeleteNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                custom_information: null
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
            enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
        });
    };

    const confirmCustomInformationRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                moved_with_recalc_custom_information: false
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
        }).catch(error => {
            console.log(error);
        });
    };


    const onManualCancellationRules = () => {
        setShowManualCancellationRules(!showManualCancellationRules);
    };

    const onDeleteManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                no_refund: true,
                cancel_condition_text: null
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
            setShowManualCancellationRules(!showManualCancellationRules);
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(!showManualCancellationRules);
        });
    };

    const confirmCancelConditionRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                moved_with_recalc_cancel_condition_text: false
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (showNote) {
            const element = document.getElementById(`train-${train.id}-note`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });

    useEffect(() => {
        if (showManualCancellationRules) {
            const element = document.getElementById(`train-${train.id}-manual-cancellation-rules`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });


    return (<Grid item xs container direction={'column'} spacing={1}>
        <Grid item>
            <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                {
                    user?.client_full?.type !== 2 &&
                    <Grid item>
                        <Stack
                            display="inline-flex"
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <CartConstructionProductsTableItemProviderQuotationStatus
                                item={train}
                            />
                            <CartPackageItemChangeProviderQuotationStatusButtons
                                item={{
                                    ...train,
                                    type: 'trains',
                                    isCustom: train.is_custom
                                }}
                            />
                        </Stack>
                    </Grid>
                }
                <Grid item>
                    <Grid container direction={"row-reverse"} spacing={4}>
                        {
                            user?.client_full?.type !== 2 &&
                            <Grid item style={{ fontWeight: 'bold' }}>
                                <Grid container direction={'column'} justify={'center'} alignItems={'flex-end'}>
                                    {
                                        train.traveler_modification &&
                                        <Grid item>
                                            <Chip
                                                color="warning"
                                                label={
                                                    train.traveler_modification === 'SET_IN_TRIP' ?
                                                        t('cart-material.add-to-cart-traveler') :
                                                        t('cart-material.add-to-option-traveler')
                                                }
                                                size="small"
                                                icon={<ErrorOutline color="inherit" />}
                                                onDelete={confirmTravelerModification}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs spacing={2}>
            <Grid className={classes.gridContainer} container item>
                <Grid className={classes.mainRow} container item xs={12} justify={'space-between'} spacing={2} alignItems={'center'}>
                    {
                        train.trip_legs.length > 0 && <>
                            {train.trip_legs.map((leg, leg_id) => <Grid key={`leg-offer-${leg_id}`} item xs={12}>
                                <Typography variant="h5" style={{ display: 'flex', alignItems: 'center', gap: 1 }}>{leg.origin_label} <ArrowRightAlt /> {leg.destination_label}</Typography>
                                {leg.offer.fare_offers.map((fare, fare_index) => <TrainCartCardDetailFare
                                    key={`fare-offer-fares-${leg_id}-${fare.id}`}
                                    fare={fare}
                                    fare_index={fare_index}
                                    fares_lenght={leg.offer.fare_offers.length}
                                    trip_leg={leg}
                                    offer={leg.offer}
                                    travelers_group={train.group_passenger}
                                />)}
                                {leg_id + 1 < train.trip_legs.length && <Stack >
                                    <Divider sx={{ marginBottom: '1rem' }} />
                                </Stack>}
                            </Grid>)}
                        </>
                    }
                    <Grid item xs={2}>
                        {
                            !isProductPackaged({
                                product: train,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <Fragment>
                                {
                                    train.is_available ? (
                                        <Fragment>
                                            {
                                                train.price_change && (train.price_change.price_variation !== 0 || !train.price_change.is_available) &&
                                                <Grid container justify={'flex-end'} alignItems={'center'}>
                                                    {
                                                        train.price_change.is_available &&
                                                        <Grid item>
                                                            {train.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                        </Grid>
                                                    }
                                                    <Grid item>
                                                        <Typography className={train.price_change.price_variation > 0 || !train.price_change.is_available ? classes.red : classes.green}>
                                                            {
                                                                !train.price_change.is_available ?
                                                                    t('cart-material.product-unavailable') :
                                                                    train.price_change.price_variation.toLocaleString(
                                                                        language,
                                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                    )
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Typography className={classes.unavailable} align={'center'}>{t('cart-material.product-unavailable')}</Typography>
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                        }
                    </Grid>
                    {
                        (user?.client_full?.type !== 2 || !train.deletion_restriction) &&
                        (train.booking_status === null || train.booking_status.status_booking === 'ERROR' || (quotation_code !== 'marcovasco' && train.booking_status.status_booking === 'CANCELLED')) &&
                        <Tooltip title={t('cart-material.delete-product')}><Close className={classes.delete} onClick={toggleDeleteModal} /></Tooltip>
                    }
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    {
                        train.booking_status &&
                        train.booking_status.status_booking === 'CONFIRMED' &&
                        train.booking_process_state !== 'CANCEL' &&
                        train.booking_status.last_ticketing_date &&
                        <Typography className={clsx(classes.booking, classes.booked)}>
                            {
                                train.booking_status.last_ticketing_date &&
                                <Fragment>
                                    <br />
                                    {t('cart-material.train-last-ticketing-date')} : {window.moment.utc(train.booking_status.last_ticketing_date).format('DD/MM/YYYY')}
                                </Fragment>
                            }
                        </Typography>
                    }
                </Grid>
            </Grid>
            {
                train.booking_status && train.booking_status.status_booking === 'UNAVAILABLE' &&
                <Grid item xs={12}>
                    <Alert variant={'filled'} severity={'error'}>{t('cart-material.booking-unavailable')} : {train.booking_status.item_reference}</Alert>
                </Grid>
            }
            {
                train.booking_status && (train.booking_status.status_booking === 'ERROR' || train.booking_status.status_booking === 'UNAVAILABLE') && train.booking_errors && train.booking_errors.length > 0 &&
                <Grid item xs={12}>
                    <Alert variant={'filled'} severity={'error'}>
                        <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                        {train.booking_errors.map(error => <Typography key={`booking-error-${error.created_date}`}>{window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}</Typography>)}
                    </Alert>
                </Grid>
            }

            {/* {
                errors && errors.found.start && errors.error?.start &&
                <Grid item xs={12}>
                    <Alert variant={'filled'} severity={'error'}>
                        {t('cart-material.itinerary-error-1')}{' '}
                        {errors.error?.start.destination?.data?.name}{' '}
                        {t('global.from')}{' '}
                        {window.moment.utc(errors.error?.start.start_date).format('DD/MM/YYYY')}{' '}
                        {t('global.to')}{' '}
                        {window.moment.utc(errors.error?.start.end_date).format('DD/MM/YYYY')}.{' '}
                        {t('cart-material.itinerary-error-2')}.
                    </Alert>
                </Grid>
            } */}

            {/* {
                showManualBooking &&
                <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                    <CarttrainManualBooking train={train} onManualBooking={onManualBooking} />
                </Grid>
            } */}
            {
                showNote ? (
                    <Grid id={`train-${train.id}-note`} item xs={12}>
                        <CartTrainNote train={train} onAddNote={onAddNote} />
                    </Grid>
                ) : train.custom_information && (
                    <Grid item xs={12}>
                        <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                            <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                <Grid item>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item>
                                            <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                {t('global.edit')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ width: '100%' }}>
                                    {
                                        train.moved_with_recalc_custom_information &&
                                        <Tooltip title={t('cart-material.recalc-product-note')}>
                                            <div
                                                className={clsx(classes.traverlerModification, classes.delete)}
                                                onClick={confirmCustomInformationRecalc}
                                                style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                            >
                                                <Grid container alignItems={'center'} justify={'space-between'}>
                                                    <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                    <Grid item>{t('cart-material.recalc')}</Grid>
                                                    <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                </Grid>
                                            </div>
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        }>
                            <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                            {train.custom_information}
                        </Alert>
                    </Grid>
                )
            }
            {
                user?.client_full?.type !== 2 && train.provider_wanted_price &&
                <Grid item xs={12}>
                    <Alert variant={'filled'} severity={'error'} action={
                        // <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                        <Button color={"inherit"} size={'small'} disabled >{t('global.see')}</Button>
                    }>
                        <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                    </Alert>
                </Grid>
            }
            {
                showManualCancellationRules ?
                    <Grid id={`train-${train.id}-manual-cancellation-rules`} item xs={12}>
                        <TrainCartManualCancellationRules train={train} onManualCancellationRules={onManualCancellationRules} />
                    </Grid> : (train.cancel_condition_text || (['verdie'].includes(quotation_code) && !!train.cancel_info)) &&
                    <Grid item xs={12}>
                        <Alert className={`${['verdie'].includes(quotation_code) ? classes.cancelConditionAlertVerdie : classes.cancelConditionAlert}`} variant={'filled'} severity={'info'} action={
                            <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                <Grid item>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        {
                                            //!['verdie'].includes(quotation_code) &&
                                            <Fragment>
                                                <Grid item>
                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                        {t('global.edit')}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                                </Grid>
                                            </Fragment>
                                        }
                                        {
                                            ['verdie'].includes(quotation_code) &&
                                            <Grid item onClick={() => setOpenCancel(!openCancel)}>{openCancel ? <ExpandLess style={{ cursor: 'pointer' }} /> : <ExpandMore style={{ cursor: 'pointer' }} />}</Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    train.moved_with_recalc_cancel_condition_text &&
                                    <Grid item style={{ width: '100%' }}>
                                        <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                            <div
                                                className={clsx(classes.traverlerModification, classes.delete)}
                                                onClick={confirmCancelConditionRecalc}
                                                style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                            >
                                                <Grid container alignItems={'center'} justify={'space-between'}>
                                                    <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                    <Grid item>{t('cart-material.recalc')}</Grid>
                                                    <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                </Grid>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>
                        }>
                            <AlertTitle onClick={() => setOpenCancel(!openCancel)} style={{ cursor: 'pointer' }}>
                                {
                                    !['verdie'].includes(quotation_code) ?
                                        t('cart-material.manual-cancellation-rules') :
                                        t('cart-material.provider-cancellation-rules')
                                } : {train.no_refund ? t('cart-material.not-cancellable') : t('cart-material.cancellable-with-conditions')}
                            </AlertTitle>
                            <Collapse in={openCancel}>
                                {train.cancel_condition_text ? (train.cancel_condition_text === t('cart-material.not-cancellable') ? '' : train.cancel_condition_text) : (train.cancel_info !== "" ? train.cancel_info : "")}
                            </Collapse>
                        </Alert>
                    </Grid>
            }
        </Grid>
        <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
    </Grid>);
}