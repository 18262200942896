import { Stack, Typography } from "@mui/material";
import React, { FC, useId, useMemo } from "react";
import { TrainSegment } from "./objects/train";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { TrainIcon } from "./assets/trainIcon";
import { MarketingInformationIcon, MarketingInformationIconPAO } from "./objects/backendData/trainMarketingInformationIcon";
import { ServiceTypeIcons } from "./components/services/serviceTypeIcon";
import { ServiceOffersEntity } from "./objects/backendData/trainBackendDate";
import { getProviderIcon } from "./utils/provider/getProviderIcon";

const useStyles = makeStyles((theme) => ({
    segmentsContainer: {
        flexDirection: 'column',
        gap: theme.spacing(1),
        borderRadius: '0.6rem',
        // border: '0.5px solid rgba(0, 0, 0, 0.3)',
        backgroundColor: 'white',
        padding: '1rem'
    },
    segmentDetailContainer: {
        flexDirection: 'column',
        gap: theme.spacing(2),
        position: 'relative',
        flex: 1
    },
    segmentLineContainer: {
        left: '-2rem',
        position: 'absolute',
        height: 'calc(100% - 0.1875rem)',
        top: '0.1875rem'
    },
    segmentLine: {
        backgroundColor: 'rgb(118, 43, 226)',
        border: '0px',
        borderRadius: '2rem',
        height: '100%',
        position: 'relative',
        width: '1rem',
    },
    timelineDepartureTime: {
        height: '1rem',
        width: '1rem',
        borderRadius: '50%',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(243, 243, 248)',
        border: '2px solid rgb(118, 43, 226)',
        left: '-2rem',
        position: 'absolute',
        top: '0.1875rem',
    },
    timeLineDepartureLink: {
        backgroundColor: 'rgb(118, 43, 226)',
        height: '0.1875rem',
        left: '-0.0625rem',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-100%, -50%)',
        width: '0.625rem',
    },
    timeLineDepartureHour: {
        margin: '0px',
        fontSize: '0.8125rem',
        lineHeight: '1.125rem',
        fontWeight: 500,
        backgroundColor: 'rgb(118, 43, 226)',
        borderRadius: '0.875rem',
        color: 'rgb(243, 243, 248)',
        left: '0.0625rem',
        padding: '0.1875rem 0.5rem 0px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-100%, -50%)',
        width: 'max-content',
    },
    segmentDuration: {
        margin: '0px',
        fontSize: '1rem',
        lineHeight: '1.375rem',
        fontWeight: 500,
        color: 'rgb(94, 104, 120)',
        textDecoration: 'initial',
        left: '-2.9375rem',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-100%, -50%)',
    },
    segmentOriginLabel: {
        marginBottom: '1.5rem !important',
    },
    segmentDestinationLabel: {
        marginTop: '2rem !important',
    }
}));

const TrainListContentFormTarifSegmentsDetail: FC<{ segment: TrainSegment }> = ({ segment }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const train_return_form = useSelector((state: AppState) => state.train.train_return_form);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const train_form = useMemo(() => (is_departure ? train_departure_form : train_return_form), [is_departure, train_departure_form, train_return_form]);
    // const offer = useMemo(() => train_form?.offer, [train_form]);
    // const available_services = useMemo(() => offer?.service_offers.filter((service) => !!service.segments?.find(seg => seg === segment.id)) || [], [offer]);
    const common_available_segment_services = useMemo(() => {
        const allOffers = train_form?.solution?.offers || [];
        if (allOffers.length === 0) return [];

        const serviceTypeCounts = new Map<string, number>();

        // Count occurrences of each service_type across all offers
        allOffers.forEach((offer) => {
            const uniqueServiceTypes = new Set(offer.service_offers.filter(service => !!service.segments?.find(seg => seg === segment.id)).map(service => service.service_type));
            uniqueServiceTypes.forEach(serviceType => {
                serviceTypeCounts.set(serviceType, (serviceTypeCounts.get(serviceType) || 0) + 1);
            });
        });

        // Collect services that have service_type visible in all offers
        const commonServices: ServiceOffersEntity[] = [];
        allOffers[0]!.service_offers.filter(service => !!service.segments?.find(seg => seg === segment.id)).forEach((service) => {
            if (serviceTypeCounts.get(service.service_type) === allOffers.length) {
                commonServices.push(service);
            }
        });

        return commonServices;
    }, [train_form?.solution?.offers]);

    const segment_duration = `${Math.floor(require('moment').duration(segment.duration).asHours())}h${require('moment').duration(segment.duration).minutes()}m`;

    return (
        <Stack direction={'row'}>
            <Stack sx={{ width: '6.125rem', marginRight: '1rem' }}></Stack>
            <Stack className={classes.segmentDetailContainer}>
                <div className={classes.segmentLineContainer}><div className={classes.segmentLine} /> </div>
                <div className={classes.timelineDepartureTime}>
                    <div className={classes.timeLineDepartureLink}>
                        <Typography variant="caption" className={classes.timeLineDepartureHour}>{moment(segment.departure).format('HH:mm')}</Typography>
                    </div>
                </div>
                <Stack sx={{ position: 'relative' }}>
                    <Typography variant="h5" className={classes.segmentOriginLabel}>{segment.origin.label}</Typography>
                </Stack>
                <Typography variant="body2" className={classes.segmentDuration}>{segment_duration}</Typography>
                <Stack sx={{ backgroundColor: 'rgb(243, 243, 248)', borderRadius: "0.875rem", padding: "1rem", flexDirection: 'column' }}>
                    <Stack direction={'column'} spacing={2}>
                        {/* provider */}
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <TrainIcon />
                            <img src={getProviderIcon(segment!.marketingInformation, {operatingCarrier: segment?.operatingCarrier, marketingCarrier: segment?.marketingCarrier})} style={{ height: '1rem' }} />
                        </Stack>
                        {/* vehicle */}
                        <Stack>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: "1.1rem" }}>{segment.vehicle.type} <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>n° {segment.vehicle.reference}</Typography></Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={1} sx={{ marginTop: '2.5rem !important' }}>
                            {common_available_segment_services.map((service) => {
                                const Icon = ServiceTypeIcons[service.service_type];
                                return <Stack direction={'row'} spacing={2} key={`offer-service-detail-${service.service_id}-${service.service_type}`}><Icon sx={{ width: '24px', height: '24px' }} /> <Typography variant="body2">{t(`train.service.${service.service_type}`)}</Typography></Stack>
                            })}
                        </Stack>
                    </Stack>
                </Stack>
                <Stack sx={{ position: 'relative' }} className={classes.segmentDestinationLabel}>
                    <div className={classes.timelineDepartureTime}>
                        <div className={classes.timeLineDepartureLink}>
                            <Typography variant="caption" className={classes.timeLineDepartureHour}>{moment(segment.arrival).format('HH:mm')}</Typography>
                        </div>
                    </div>
                    <Stack>
                        <Typography variant="h5">{segment.destination.label}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
export const TrainListContentFormTarifSegments: FC<{ segments: TrainSegment[] }> = ({ segments }) => {
    const id = useId();
    const classes = useStyles();
    const { t } = useTranslation();

    return (<>
        <Stack><Typography variant={'h5'}>{t('summary.trip_detail')}</Typography></Stack>
        <Stack className={classes.segmentsContainer}>
            {segments.map((segment, idx) => (<TrainListContentFormTarifSegmentsDetail segment={segment} key={`${id}-${idx}`} />))}
        </Stack>
    </>);
}