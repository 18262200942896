import { Button, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { useTrainSearchStyles } from './style/trainSearchStyle';
import { TrainSearchDestinations } from './trainSearchDestinations';
import { TrainSearchDates } from './trainSearchDates';
import { TrainSearchTravelers } from './trainSearchTravelers';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateTrainListFiltersFrontCriteria, updateTrainListLoaded, updateTrainListLoading } from './redux/reducer';
import { AppState } from '../../Reducers/Reducers';
import { useSnackbar } from "notistack";
import { browserHistory } from 'react-router';
import { useGetDefaultFilter } from './services/hooks/defaultFilters/getDefaultFilters';
import { TrainSearchItinararyResume } from './trainSearchItineraryResume';
import { TrainSearchProviders } from './trainSearchProviders';


const useStyles = makeStyles((theme) => ({
    stackContentResponsive: {
        gap: theme.spacing(1),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 50%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackContainerResponsive: {
        gap: theme.spacing(1),
        flexDirection: 'row  !important' as unknown as undefined,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column  !important' as unknown as undefined,
        },
    },
    stackFieldContainerResponsive: {
        gap: theme.spacing(1),
        flex: '0 1 100%',
        flexDirection: 'row  !important' as unknown as undefined,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column  !important' as unknown as undefined,
        },

    },
    stackDestination: {
        gap: theme.spacing(3),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 42%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackDestinationNoSearchButton: {
        flex: '0 1 42% !important',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100% !important',
        },
    },
    stackDates: {
        gap: theme.spacing(0),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 35%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackDatesNoSearchButton: {
        flex: '0 1 35% !important',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100% !important',
        },
    },
    stackTravelers: {
        gap: theme.spacing(0),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 20%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackTravelersNoSearchButton: {
        flex: '0 1 20% !important',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100% !important',
        },
    },
    stackProvider: {
        gap: theme.spacing(0),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 42%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackProviderNoSearchButton: {
        flex: '0 1 42% !important',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100% !important',
        },
    },
    button: {
        backgroundColor: 'rgb(0, 97, 121) !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center !important',

        height: '3rem',
        textOverflow: 'ellipsis',
        width: '100%',
        color: 'white !important',
        border: '0.5px solid rgba(0, 0, 0, 0.3) !important',
        borderRadius: '0.875rem !important',
        cursor: 'pointer',
        padding: '0.75rem 1.125rem 0.75rem 1rem !important',
        '&:disabled': {
            backgroundColor: 'rgba(0, 97, 121, 0.7) !important',
            color: 'rgba(255, 255, 255, 0.7)!important',
            cursor: 'not-allowed !important',
        },
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: "20%",
        left: "50%",
        marginLeft: -12
    },
}));

const TrainSearchResponsive: FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useTrainSearchStyles();
    return !isMobile ? (
        <Paper elevation={0} variant="outlined" className={clsx({
            [classes.formShadow]: true
        })}
        >
            {children}
        </Paper>
    ) : children
};

export const TrainSearch: FC<{ actionOnSearch?: () => void; no_search_button?: boolean; return_required?: boolean }> = ({ actionOnSearch, no_search_button, return_required }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const classes = useStyles();
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const train_list_loading = useSelector((state: AppState) => state.train.train_list_loading);

    const {travelers_error, providers_error} = useSelector((store: AppState) => store.train.train_list_criteria.filters);

    const controlSearch = () => {
        const errors: Record<string, string> = {};
        if (!train_list_filters_criteria.origin) {
            errors['origin_error'] = t('shared.place-of-departure-required');
        }
        if (!train_list_filters_criteria.destination) {
            errors['destination_error'] = t('shared.destination-required');
        }
        if(train_list_filters_criteria.travelers?.length === 0) {
            errors['travelers_error'] = t('train.traveler-required');
        }
        if(train_list_filters_criteria.providers?.length === 0) {
            errors['providers_error'] = t('shared.custom-providers-required');
        }
        Object.entries(errors).forEach(([key, value]) => {
            dispatch(updateTrainListFiltersFrontCriteria({ ...train_list_filters_criteria, [key]: value }));
            enqueueSnackbar(value, { variant: 'error' });
        });

        if (Object.keys(errors).length > 0) return false; else return true;
    };
    const onSearch = async () => {
        const can_search = controlSearch();
        if (!can_search) return;
        if (actionOnSearch) {
            dispatch(updateTrainListLoading(true));
            actionOnSearch();
        }
    };

    return <TrainSearchResponsive>
        <Stack gap={2} id="train-search-bar">
            <Stack gap={1} className={classes.stackContainerResponsive}>
                <Stack className={classes.stackFieldContainerResponsive}>
                    <Stack className={clsx(classes.stackDestination, { [classes.stackDestinationNoSearchButton]: no_search_button })} >
                        <TrainSearchDestinations return_required={return_required} />
                    </Stack>
                    <Stack className={clsx(classes.stackDates, { [classes.stackDatesNoSearchButton]: no_search_button })} >
                        <TrainSearchDates return_required={return_required} />
                    </Stack>
                    <Stack className={clsx(classes.stackTravelers, { [classes.stackTravelersNoSearchButton]: no_search_button })} >
                        <TrainSearchTravelers return_required={return_required}/>
                    </Stack>
                    <Stack className={clsx(classes.stackProvider, { [classes.stackProviderNoSearchButton]: no_search_button })} >
                        <TrainSearchProviders return_required={return_required}/>
                    </Stack>
                </Stack>
                {!no_search_button && <Stack justifyContent={'center'} direction={'row'}>
                    <Stack className={classes.stackContentResponsive} >
                        <Button className={classes.button} startIcon={<Search sx={{ color: 'white' }} />} onClick={onSearch} disabled={train_list_loading || return_required}>{t('shared.search')}</Button>

                        {train_list_loading && <CircularProgress size={24} sx={{ color: 'white' }} className={`${classes.buttonProgress}`} />}
                    </Stack>
                </Stack>}
            </Stack>
        </Stack>
    </TrainSearchResponsive>
}
const TrainSearchRoot = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const searchAction = () => {
        browserHistory.push(`/${window.url_name}/apps/train/list`);
    };

    useGetDefaultFilter();


    useEffect(() => {
        if (window.location.href.includes('/train/search')) {
            dispatch(updateTrainListLoaded(false));
        }
    }, [window.location.href]);

    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={3}>
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <Typography align={'center'} variant={'h5'} className={'product-title'} gutterBottom > {t('train.train-reservation')} </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: '100%' }}>
                <TrainSearchItinararyResume />
            </Grid>
            <Grid item xs={12}>
                <TrainSearch actionOnSearch={searchAction} />
            </Grid>
        </Container>
    );
};

export default TrainSearchRoot;
