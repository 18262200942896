import { Drawer, paperClasses } from "@mui/material";
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import React, { useMemo } from "react";
import { updateTrainDepartureForm, updateTrainReturnForm } from "./redux/reducer";
import { makeStyles } from "@material-ui/core";
import { TrainListContentFormTarif } from "./trainListContentFormTarif";
import { TrainListContentFormTarifOfferPricingCoonditions } from "./trainListContentFormTarifOfferPricingCoonditions";
import { TrainListContentFormPreference } from "./trainListContentFormTarifPreference";

const useStyles = makeStyles((theme) => ({
    drawerForm: {
        [`& .${paperClasses.root}`]: {
            width: '605px !important'
        },
        [theme.breakpoints.down('sm')]: {
            [`& .${paperClasses.root}`]: {
                width: '100vw !important'
            },
        },
    },
}));

export const TrainListContentForm = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const train_return_form = useSelector((state: AppState) => state.train.train_return_form);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const open_drawer = useMemo(() => ((!!train_departure_selected && !!train_return_form) || (!!train_departure_form && !train_return_form)), [train_departure_selected, train_return_form, train_departure_form]);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const train_form = useMemo(() => (is_departure ? train_departure_form : train_return_form), [is_departure, train_departure_form, train_return_form]);
    const setTrainFormAction = useMemo(() => (is_departure ? updateTrainDepartureForm : updateTrainReturnForm), [is_departure]);

    const closeDrawer = () => {
        if (is_departure) {
            dispatch(updateTrainDepartureForm(null));
        } else {
            dispatch(updateTrainReturnForm(null));
        }
    };

    return train_form && (
        <Drawer
            anchor={'right'}
            open={open_drawer}
            className={classes.drawerForm}
        >
            {!train_form.selected_offer && !train_form.offer_price_condition && <TrainListContentFormTarif closeDrawer={closeDrawer} train_form={train_form} setTrainFormAction={setTrainFormAction} is_departure={is_departure} />}
            {!train_form.selected_offer && train_form.offer_price_condition && <TrainListContentFormTarifOfferPricingCoonditions closeDrawer={closeDrawer} train_form={train_form} setTrainFormAction={setTrainFormAction} is_departure={is_departure} />}
            {train_form.selected_offer && ((!is_departure && can_return) || (is_departure && !can_return)) && <TrainListContentFormPreference closeDrawer={closeDrawer} train_form={train_form} setTrainFormAction={setTrainFormAction} is_departure={is_departure} />}
        </Drawer>
    )
}