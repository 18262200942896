import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import React, { useEffect, useMemo } from "react";
import { setTrainTravelers, updateTrainDepartureForm, updateTrainDepartureSelected, updateTrainListLoaded, updateTrainListLoading, updateTrainListLoadMoreLoading, updateTrainListOutbound, updateTrainListReturn, updateTrainListVisibleItem, updateTrainReturnForm } from "./redux/reducer";
import { IconButton, Stack, Typography } from "@mui/material";
import { TrainSearch } from "./trainSearch";
import { TrainListDayPicker } from "./trainListDayPicker";
import { TrainListItem } from "./trainListItem";
import { TrainListItemLoading } from "./trainListItemLoading";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { TrainTrip } from "./objects/train";
import { SolutionOffer } from "./objects/backendData/trainBackendDate";
import { Close, ArrowBack } from "@mui/icons-material";
import { getTrainFilterCriteria } from "./utils/mapping/getTrainCriteria";
import { searchTrains } from "./services/trainServices";
import { getTrainListForUi } from "./utils/mapping/getTrainForUI";
import axios from "axios";
import { useShowError } from "../Utils/showError";

export const TrainListContent = ({
    title,
    train_trip_list,
    minDate,
    no_search_button,
    return_required,
}: {
    title: string;
    train_trip_list: any[];
    minDate?: Date;
    no_search_button?: boolean;
    return_required?: boolean;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const showError = useShowError();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const train_list_loading = useSelector((state: AppState) => state.train.train_list_loading);
    const train_list_load_more_loading = useSelector((state: AppState) => state.train.train_list_load_more_loading);
    const train_list_visible_item = useSelector((state: AppState) => state.train.train_list_visible_item);
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);

    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected]);


    const onSearchAction = async () => {
        try {
            dispatch(updateTrainListLoading(true));
            const searchParams = getTrainFilterCriteria(train_list_filters_criteria);
            const requests = searchParams.map(params => searchTrains(params));
            const responses = await Promise.all(requests);
            const data = responses[0]?.data; // TODO : update when new provider arrive
            // const setListAction = !is_departure ? updateTrainListReturn : updateTrainListOutbound;
            const { train_trip_departure, train_trip_return, travelers } = getTrainListForUi(data);
            dispatch(updateTrainListOutbound(train_trip_departure));
            if(can_return) dispatch(updateTrainListReturn(train_trip_return ?? []));
            dispatch(setTrainTravelers(travelers));
            dispatch(updateTrainListLoaded(true));
            dispatch(updateTrainListVisibleItem(5));
            dispatch(updateTrainListLoading(false));
        } catch (error) {
            if (!axios.isCancel(error)) {
                showError(error as Error);
                console.error(error);
                dispatch(updateTrainListLoading(false));
            }
        }
    };

    const loadMore = () => {
        if (train_list_visible_item < train_trip_list.length && !train_list_load_more_loading) {
            dispatch(updateTrainListLoadMoreLoading(true));
            setTimeout(() => {
                dispatch(updateTrainListVisibleItem(train_list_visible_item + 5));
                dispatch(updateTrainListLoadMoreLoading(false));
            }, 800);
        }
    };

    const getTrainFormOffer = (train_form: TrainTrip, offer: SolutionOffer) => {
        if (is_departure) {
            dispatch(updateTrainDepartureForm({ ...train_form, offer }));
        } else {
            dispatch(updateTrainReturnForm({ ...train_form, offer }));
        }
    };

    const handleScroll = () => {
        const nearBottom =
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 10;

        if (nearBottom) {
            loadMore();
        }
    };
    const goBackOnDeparture = () => {
        dispatch(updateTrainDepartureForm({ ...train_departure_form!, selected_offer: undefined }));
        dispatch(updateTrainDepartureSelected(null));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadMore]);

    return (
        <>
            <Typography variant={'h5'} className={'product-title'} sx={{ textAlign: 'start', paddingTop: '24px', justifyContent: 'flex-start' }} gutterBottom>
                {!is_departure && <IconButton onClick={goBackOnDeparture}><ArrowBack sx={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 'bold' }} /></IconButton>} {title}
            </Typography>
            <TrainSearch actionOnSearch={onSearchAction} return_required={return_required} />
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} margin={'0.5rem 0'} sx={{ scrollSnapType: 'x mandatory', overflowX: 'auto', marginBottom: '1rem' }}>
                <TrainListDayPicker minDate={minDate} is_departure={is_departure} />
            </Stack>
            <Stack direction={'column'} gap={2} sx={{ overflowY: 'auto', position: 'relative', minHeight: '200px', paddingBottom: '1rem' }}>
                {train_list_loading && (
                    <div
                        style={{ width: '100%', height: '200px', position: 'absolute', zIndex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="train-loader" style={{ margin: 'auto' }} />
                    </div>
                )}
                {!train_list_loading && train_trip_list.length < 1 && (
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {t('shared.no-train-for-filters')}
                    </Typography>
                )}
                {!train_list_loading && train_trip_list.length > 0 && !isMobile && (
                    <Stack direction={'row'} spacing={2}>
                        <Stack flex={'0 1 70%'}></Stack>
                        <Stack flex={'0 1 15%'} justifyContent={'center'} direction={'row'}>{t('train.first-offer')}</Stack>
                        <Stack flex={'0 1 15%'} justifyContent={'center'} direction={'row'}>{t('train.second-offer')}</Stack>
                    </Stack>
                )}
                {!train_list_loading &&
                    train_trip_list.slice(0, train_list_visible_item).map((train: any, index: number) => (<TrainListItem train_trip={train} key={`train-list-search-item-${index}`} onSelectFirstClass={getTrainFormOffer} onSelectSecondClass={getTrainFormOffer} />))}
                {train_list_load_more_loading && <TrainListItemLoading />}
            </Stack>
        </>
    );
};