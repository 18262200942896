import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { useSnackbar } from 'notistack';
import { CartProductCard, CartProductCardProps } from "../CartMaterial/CartProductCard";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { ManualProductTrainCart } from "../Itinerary/objects/manualProductTrainCart";
import { TrainCartMainContent } from "./trainCartMainContent";
import { CardContent, Collapse } from "@mui/material";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";
import { CartConstructionProductsTableItemMargin } from "../CartMaterial/CartConstructionProductsTableItemMargin";
import { TrainCartCardFooter } from "./trainCartCardFooter";
import { TrainCartCardDetail } from "./trainCartCardDetail";

type TrainCartCardProps = ({
    type: 'normal',
    train: TrainCartFromBackend
}
    // | {
    //     type: 'manual',
    //     train: ManualProductTrainCart
    // }
) & Pick<CartProductCardProps, 'alwaysOpen'>

export const TrainCartCard: FC<TrainCartCardProps> = (props) => {
    return (
        <>
            <CartProductCard
                direction="vertical"
                type="train"
                item={props.train}
                margin={<MarginWrapper train={props.train} />}
                pictures={[]}
                alwaysOpen={props.alwaysOpen}
                disablePictures
                mainContent={(sideActions) => <TrainCartMainContent sideActions={sideActions} train={props.train} type={props.type} />}
                footer={<TrainCartCardFooter train={props.train} />
                }
            >
                <TrainCartCardDetail train={props.train} />
            </CartProductCard>
        </>
    );

}

type MarginWrapperProps = {
    train: TrainCartFromBackend
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                !isProductPackaged({
                    product: props.train,
                    stackInfos: trip?.stack_info ?? null
                })
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="train"
                    item={props.train}
                />
            </CardContent>
        </Collapse>
    );
}
