import { autocompleteClasses, FormControl, FormHelperText, IconButton, Popper, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../Reducers/Reducers';
import { AutocompleteTemplate } from './components/inputs/autocompleteTemplate';
import { TrainListFiltersFrontCriteria, TrainLocation } from './objects/trainList';
import { updateTrainListFiltersFrontCriteria, updateTrainListFiltersSwapDestination } from './redux/reducer';
import { SwapHoriz, SubwayOutlined, HomeWorkOutlined, PlaceOutlined } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';

const input_style = {
    [`.${autocompleteClasses.inputRoot}`]: {
        maxHeight: '3rem !important'
    }
};

const useStyles = makeStyles((theme) => ({
    stackContentResponsive: {
        gap: theme.spacing(3),
        flexDirection: 'row  !important' as unknown as undefined,
        flex: '0 1 35%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
    },
    stackContainerResponsive: {
        gap: theme.spacing(1),
        flexDirection: 'row  !important' as unknown as undefined,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column  !important' as unknown as undefined,
        },
    }
}));
const CustomPopper = (props: any) => (
    <Popper {...props} style={{ width: "300px", borderRadius: '0.6rem' }} />
);
export const TrainSearchDestinations: FC<{ return_required?: boolean }> = ({ return_required }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);

    const setTrainListFilterCriteria = (key: keyof TrainListFiltersFrontCriteria, value: any) => {
        dispatch(updateTrainListFiltersFrontCriteria({ ...train_list_filters_criteria, [key]: value, [`${key}_error`]: undefined }));
    };

    const swapDestination = () => {
        dispatch(updateTrainListFiltersSwapDestination());
    };

    const getOptionTypeIcon = (type: 'station' | 'city' | string) => {
        switch (type) {
            case 'station':
                return <SubwayOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
            case 'city':
                return <HomeWorkOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
            default:
                return <PlaceOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
        }
    }

    return (< >
        <Stack flex={'0 1 50%'}>
            <FormControl fullWidth error={!!train_list_filters_criteria.origin_error}>
                <AutocompleteTemplate
                    value_parameters={{
                        value: train_list_filters_criteria.origin,
                        setValue: (origin: TrainLocation) => { origin && setTrainListFilterCriteria('origin', origin); },
                        getOptionLabel: (option: TrainLocation) => option?.label ?? option?.local_label ?? '-',
                        renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: TrainLocation) => <li {...props} key={option?.id ?? '-'} style={{ display: 'flex', gap: '25px', alignItems: 'center', borderBottom: 'solid 0.5px rgba(0, 0, 0, 0.1)' }}>
                            {getOptionTypeIcon(option.type)}
                            <Stack direction={'column'} gap={0.5}>
                                <Typography variant="caption">{t(`train.${option.type}`)}</Typography>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {option?.label ?? option?.local_label ?? '-'}
                                </Typography>
                            </Stack>
                        </li>,
                        required: true,
                        label: t('shared.place-of-departure'),
                        error: !!train_list_filters_criteria.origin_error,
                        disabled: return_required,
                        // placeholder: t('shared.place-of-departure'),
                    }}
                    request_parameters={{
                        url_path: `${API_HREF}client/${window.id_owner}/trains/places/`,
                        search_fields: ['place'],
                        search_length_before_request: 1
                    }}
                    sx={input_style}
                    endAdornment={null}
                    startAdornment={<PlaceOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />}
                    CustomPoper={CustomPopper}
                />
                <FormHelperText>{train_list_filters_criteria.origin_error}</FormHelperText>
            </FormControl>
        </Stack>
        <IconButton onClick={swapDestination} disabled={return_required} className={'destination-swap'}> <SwapHoriz /> </IconButton>
        <Stack flex={'0 1 50%'}>
            <FormControl fullWidth error={!!train_list_filters_criteria.destination_error}>
                <AutocompleteTemplate
                    value_parameters={{
                        value: train_list_filters_criteria.destination,
                        setValue: (destination: TrainLocation) => { destination && setTrainListFilterCriteria('destination', destination); },
                        getOptionLabel: (option: TrainLocation) =>  option?.label ?? option?.local_label ?? '-',
                        renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: TrainLocation) => <li {...props} key={option?.id ?? '-'} style={{ display: 'flex', gap: '25px', alignItems: 'center', borderBottom: 'solid 0.5px rgba(0, 0, 0, 0.1)' }}>
                            {getOptionTypeIcon(option.type)}
                            <Stack direction={'column'} gap={0.5}>
                                <Typography variant="caption">{t(`train.${option.type}`)}</Typography>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    { option?.label ?? option?.local_label ?? '-'}
                                </Typography>
                            </Stack>
                        </li>,
                        required: true,
                        label: t('shared.destination'),
                        error: !!train_list_filters_criteria.destination_error,
                        disabled: return_required
                        // placeholder: t('shared.destination')
                    }}
                    request_parameters={{
                        url_path: `${API_HREF}client/${window.id_owner}/trains/places/`,
                        search_fields: ['place'],
                        search_length_before_request: 1
                    }}
                    sx={input_style}
                    endAdornment={null}
                    startAdornment={<PlaceOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />}
                    CustomPoper={CustomPopper}
                />
                <FormHelperText>{train_list_filters_criteria.destination_error}</FormHelperText>
            </FormControl>
        </Stack>
    </>);

}