import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import GetStatusColorHexa from "./Functions/GetStatusColorHexa";
import { Checkbox } from "@mui/material";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    formControlRoot2: {
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)',
            fontSize: 12,
            fontWeight: 500,
            color: "black"
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: 5
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px',
            fontSize: 12,
            fontWeight: "bold"
	    }
  	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "black"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontSize12: {
        fontSize: "12px !important"
    }
}));

const CustomAutoComplete = ({ options, values, type, allSelected, customSetter, handleSelectAll, customLabel, label }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const statusFilter = createFilterOptions();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const getOptionLabel = (option, type) => {
        if (option.label !== "ALL" && type === "status") {
            return t(`trip.status.${quotation_code}.${option.label}`);
        }
        if (type === "status" || type === "tripTags") {
            return `${t("menu.trip_list." + option.label)}`;
        }

        return `${option.label}`;
        // if (quotation_code === "volonline" && option.label !== "ALL") {
        //     return t(`trip.status.volonline.${option.label}`);
        // }
        // if (type === "status" || type === "tripTags") {
        //     return `${t("menu.trip_list." + option.label)}`;
        // }
        //
        // return `${option.label}`;
    };

    return (
        <Autocomplete
            multiple
            options={options}
            disableCloseOnSelect
            size={"small"}
            value={values}
            onChange={(event, selectedOptions, reason) => {
                console.log('selectedOptions:', selectedOptions);
                if (reason === "select-option" || reason === "remove-option") {
                    if (selectedOptions.find(option => option.label === customLabel)) {
                        handleSelectAll(!allSelected, type);
                    } else if (type === "userAgence" || type === "userTO") {
                        if (selectedOptions.length !== 0 && selectedOptions[selectedOptions.length - 1].id === null) {
                            filterOption = selectedOptions.filter((option) => option.id === null);
                            customSetter(filterOption);
                        } else {
                            filterOption = selectedOptions.filter((option) => option.id !== null);
                            customSetter(filterOption);
                        }
                    } else {
                        customSetter(selectedOptions);
                    }
                } else if (reason === "clear") {
                    customSetter([]);
                }
            }}
            getOptionLabel={(option) => getOptionLabel(option, type)}
            groupBy={(option) => {
                if (type === "status" && (["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code))) {
                    if (option.label === "ALL") {
                        return t("menu.trip_list.ALL");
                    }
                    return t("menu.trip_list." + option.group);
                }
            }}
            renderTags={(value) => {
                let value_length = value.length;
                if (type === "userAgence" || type === "userTO") {
                    return (
                        <span style={{ marginLeft: 20 }}>
                            {
                                value.find((el) => el.id === null) !== undefined ? (type === "userTO" ? t('menu.user_management.without_user_bo') : t('menu.user_management.without_seller'))
                                    :
                                    (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) ? value_length !== options.length - 1 ? value_length + " " + t("global.over") + " " + (options.length - 1) + " " + label : customLabel + " (" + value_length + ")" : value_length !== options.length ? value_length + " " + t("global.over") + " " + (options.length) + " " + label : customLabel + " (" + value_length + ")")
                            }
                            {
                                //value_length !== options.length - 1 ? (
                                //value.find((el) => el.id === null) !== undefined ? (type === "userTO" ? t('menu.user_management.without_user_bo') : t('menu.user_management.without_seller')) : value_length + " " + t("global.over") + " " + (options.length - 1 )+ " " + label
                                //) : (
                                //customLabel + " (" + value_length + ")"
                                //)
                            }
                        </span>
                    );
                }
                return (
                    <span style={{ marginLeft: 20 }}>
                        {
                            value_length !== options.length ? (
                                value_length + " " + t("global.over") + " " + options.length + " " + label
                            ) : (
                                ((type === "status" || type === "tripTags") ? t("menu.trip_list." + customLabel) : customLabel) + " (" + value_length + ")"
                            )
                        }
                    </span>
                );
            }}
            getOptionSelected={(option, value) => (type === "userTO" || type === "userAgence" || type === "userAgenceTO" || type === "creator") ? option.id === value.id : (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? option.label === value.label : option.label === value.label && option.group === value.group)}
            renderOption={(option, { selected }) => {
                const selectAllProps = option.label === customLabel //To control the state of 'select-all' checkbox
                    ? { checked: allSelected }
                    : {};
                return (
                    <Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            sx={{
                                'color': `${main_color}`,
                                '&.Mui-checked': {
                                    color: `${main_color}`
                                }
                            }}
                            {...selectAllProps}
                        />
                        {
                            type === "status" && option.label !== customLabel && (
                                <FiberManualRecordIcon style={{ color: GetStatusColorHexa(option.label), marginRight: 3 }}/>
                            )
                        }
                        <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                            {getOptionLabel(option, type)}
                        </Grid>
                    </Fragment>
                );
            }
            }
            renderInput={(params) => (
                <TextField {...params} className={`${classes.formControlRoot2}`} variant="outlined" label={label} />
            )}
            filterOptions={(options, params) => {
                const filtered = statusFilter(options, params);
                return [{ label: customLabel }, ...filtered];
            }}
        />
    );
};
export default React.memo(CustomAutoComplete);
