import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Collapse,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
    iconButtonClasses
} from "@mui/material";
import {
    Check,
    CheckBox,
    CheckBoxOutlineBlankOutlined,
    Close,
    ContactSupportOutlined,
    Delete,
    Edit,
    HourglassEmpty,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    MoreVertOutlined,
    ThumbDown,
    ThumbUp
} from "@mui/icons-material";
import { isFunction } from "lodash";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { ProviderQuotationProductCommentModal } from "../ProviderQuotation/ProviderQuotationProductCommentModal";
import { ProviderBookingProductCommentModal } from "../ProviderBooking/ProviderBookingProductCommentModal";
import { CartProductPicturesModal } from "./CartProductPicturesModal";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import CartDeleteModal from "./CartDeleteModal";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import { ProviderContext } from "./utils/providerContext";
import { isProductBookable } from "./utils/isProductBookable";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isDeleteButtonEnabled } from "./utils/isDeleteButtonEnabled";
import { getTableItemBackgroundColor } from "./utils/getTableItemBackgroundColor";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { makeProductEditRequest } from "./utils/editProductRequests";
import { reverseProviderQuotationStatus } from "../ProviderQuotation/utils/getProviderQuotationProductStatus";
import GetCookie from "../Common/Functions/GetCookie";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { useDeleteProduct } from "./utils/deleteProduct";
import { useProductDays } from "./utils/productDays";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import { useShowError } from "../Utils/showError";
import { useProviderBookingChangeComment } from "../ProviderBooking/utils/providerBookingChangeComment";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { toggleManualProductBooking } from "./redux/cartConstructionReducer";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { Flight } from "../Itinerary/network/flight";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";
import { TrainCartFromBackend } from "../train/objects/backendData/cart/trainCartFromBackend";

export type CartProductCardProps = {
    pictures: string[],
    mainContent: React.ReactNode | ((sideActions: (minimal?: boolean) => React.ReactNode) => React.ReactNode),
    sideContent?: React.ReactNode,
    footer?: React.ReactNode | ((providerActions: React.ReactNode, providerInfo: React.ReactNode) => React.ReactNode),
    alerts?: React.ReactNode,
    margin?: React.ReactNode,
    children: React.ReactNode,
    direction?: 'horizontal' | 'vertical',
    disablePictures?: boolean,
    showEntirePictures?: boolean,
    alwaysOpen?: boolean,
    disableSideActions?: boolean,
} & ({
    type: 'car',
    item: CarCart,
    index: number
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'train',
    item: TrainCartFromBackend
} | {
    type: 'manual',
    item: ManualProduct
})

const Content = React.memo(
    function CartProductCard(props: CartProductCardProps): JSX.Element | null {
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const user = useSelector((state: AppState) => state.user.user);
        const permissions = useSelector((state: AppState) => state.user.permissions);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
        const to_book = useSelector((state: AppState) => state.cart.to_book);
        const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
        const bookableManualProducts = useSelector((state: AppState) => state.cartConstruction.bookableManualProducts);
        const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const [showDetails, setShowDetails] = useState(false);
        const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
        const [openDeleteModal, setOpenDeleteModal] = useState(false);
        const [openMenu, setOpenMenu] = useState(false);
        const [openPicturesModal, setOpenPicturesModal] = useState(false);
        const [footerHasContent, setFooterHasContent] = useState(true);
        const [containerHasContent, setContainerHasContent] = useState(true);
        const [openCommentModal, setOpenCommentModal] = useState(false);
        const [openBookingCommentModal, setOpenBookingCommentModal] = useState(false);
        const [loadingStatus, setLoadingStatus] = useState(false);
        const menuButtonRef = useRef<HTMLButtonElement>(null);
        const checked = useMemo(() => {
            return props.type !== 'manual' && props.type !== 'manual-accommodation' ?
                !!to_book?.find(book_item => book_item.id === props.item.id) :
                bookableManualProducts.findIndex((product) => product.id === props.item.id) >= 0;
        }, [props.type, props.item, to_book, bookableManualProducts]);
        const stackInfo = useMemo(() => {
            return trip?.stack_info?.find((item) => {
                return item.stack_number === props.item.stack_number &&
                    item.id === props.item.stack_info_id;
            });
        }, [trip, props.item]);
        const getDays = useProductDays();
        const steps = useMemo(() => {
            return itineraryList.filter((item) => {
                return item.step_type === 'STEP';
            }).sort((a, b) => {
                if ('start_destination' in props.item) {
                    return a.destination?.id === props.item.start_destination?.id ?
                        -1 :
                        1;
                }
                return sortItinerary(a, b);
            })
        }, [itineraryList, getDays, locale]);
        const providerContext = useContext(ProviderContext);
        const context = useContext(CartConstructionProductsContext);
        const {
            loading,
            onDeleteAccommodation,
            onDeleteAssistance,
            onDeleteCar,
            onDeleteFlight,
            onDeleteTrain,
            onDeleteManualProduct,
            onDeletePoi,
            onDeleteTransfer
        } = useDeleteProduct();
        const showError = useShowError();
        const changeBookingStatus = useProductBookingStatusChange({
            onTrigger() {
                setLoadingStatus(true);
            },
            onSuccess(product) {
                if (trip) {
                    switch (props.type) {
                        case 'flight': {
                            dispatch({
                                type: 'FLIGHT_EDIT_CART_BY_ID',
                                payload: CheckResponse([product], trip.end_date)[0]!
                            });
                            break;
                        }
                        case 'car': {
                            dispatch({
                                type: 'CAR_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'accommodation': {
                            dispatch({
                                type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'transfer': {
                            dispatch({
                                type: 'TRANSFER_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'poi': {
                            dispatch({
                                type: 'POI_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        default: {
                            dispatch({
                                type: 'CART_EDIT_MANUAL_ITEM',
                                payload: product
                            });
                            break;
                        }
                    }
                }
            },
            onError(error) {
                console.error(error);
                showError(error);
            },
            onFinally() {
                setLoadingStatus(false);
            }
        });
        const changeComment = useProviderBookingChangeComment({
            onTrigger() {
                setLoadingStatus(true);
            },
            onSuccess(product) {
                if (trip) {
                    switch (props.type) {
                        case 'flight': {
                            dispatch({
                                type: 'FLIGHT_EDIT_CART_BY_ID',
                                payload: CheckResponse([product], trip.end_date)[0]!
                            });
                            break;
                        }
                        case 'car': {
                            dispatch({
                                type: 'CAR_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'accommodation': {
                            dispatch({
                                type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'transfer': {
                            dispatch({
                                type: 'TRANSFER_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        case 'poi': {
                            dispatch({
                                type: 'POI_EDIT_CART_BY_ID',
                                payload: product
                            });
                            break;
                        }
                        default: {
                            dispatch({
                                type: 'CART_EDIT_MANUAL_ITEM',
                                payload: product
                            });
                            break;
                        }
                    }
                }
            },
            onError(error) {
                console.error(error);
                showError(error);
            },
            onFinally() {
                setLoadingStatus(false);
            }
        });
        const commentModalAction = useRef<'accept' | 'refuse' | 'new-proposal' | null>(null);
        const color = getTableItemBackgroundColor(
            props.item,
            itinerary_type_details_data ?? { variant_name: [] }
        );
        const itemProviderData = providerContext.module === 'quotation' ?
            providerContext.statuses[props.item.id] :
            null;
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        function hasTextContent(node: Node): boolean {
            if (
                node.nodeType === Node.TEXT_NODE &&
                (node.textContent?.trim().length ?? 0) > 0
            ) {
                return true;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                for (let childNode of Array.from(node.childNodes)) {
                    if (hasTextContent(childNode)) {
                        return true;
                    }
                }
            }
            return false;
        }

        const onToggleForBooking = () => {
            if (props.type !== 'manual' && props.type !== 'manual-accommodation') {
                dispatch({
                    type: 'CART_TOGGLE_FOR_BOOKING',
                    payload: {
                        ...props.item,
                        is_manual: false
                    }
                });
            } else {
                dispatch(toggleManualProductBooking(props.item));
            }
        };

        const onAccept = () => {
            commentModalAction.current = 'accept';
            setOpenCommentModal(true);
        };

        const onRefuse = () => {
            commentModalAction.current = 'refuse';
            setOpenCommentModal(true);
        };

        const onMakeNewProposition = () => {
            if (providerContext.module === 'quotation') {
                providerContext.onOpenManualProductForm();
                let productType = props.item.product_type === 6 ?
                    61 :
                    props.item.product_type;

                if (props.type === 'poi') {
                    productType = 12;
                }

                dispatch({
                    type: 'CART_MANUAL_PRODUCT_TYPE',
                    payload: {
                        value: productType,
                        default_pictures: []
                    }
                });
                dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
                if (steps[0]?.id) {
                    dispatch({
                        type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
                        payload: {
                            value: steps[0].id,
                            itinerary_list: itineraryList
                        }
                    });
                }
                providerContext.onChangeCounterOfferProduct(props.item.id);
            }
        };

        const onEditComment = () => {
            commentModalAction.current = props.item.provider_accepted ?
                'accept' :
                'refuse';
            setOpenCommentModal(true);
        };

        const onConfirmCommentModal = (comment: string | null) => {
            if (providerContext.module === 'quotation') {
                switch (commentModalAction.current) {
                    case 'accept': {
                        providerContext.onChangeStatus(
                            props.item.id,
                            {
                                agent: props.item.agent_accepted ?
                                    'accepted' :
                                    (
                                        props.item.agent_refused ?
                                            'refused' :
                                            null
                                    ),
                                provider: 'accepted'
                            },
                            comment
                        );
                        break;
                    }
                    case 'refuse': {
                        providerContext.onChangeStatus(
                            props.item.id,
                            {
                                agent: props.item.agent_accepted ?
                                    'accepted' :
                                    (
                                        props.item.agent_refused ?
                                            'refused' :
                                            null
                                    ),
                                provider: 'refused'
                            },
                            comment
                        );
                        break;
                    }
                    case 'new-proposal': {
                        providerContext.onChangeStatus(
                            props.item.id,
                            {
                                agent: props.item.agent_accepted ?
                                    'accepted' :
                                    (
                                        props.item.agent_refused ?
                                            'refused' :
                                            null
                                    ),
                                provider: 'refused'
                            },
                            comment
                        );

                        (async () => {
                            setLoadingStatus(true);
                            try {
                                const response = await makeProductEditRequest({
                                    tripId: parseInt(GetCookie('trip_id') ?? '0'),
                                    version: parseInt(GetCookie('trip_id_version') ?? '0'),
                                    product: {
                                        id: props.item.id,
                                        type: props.type,
                                        isCustom: props.item.is_custom
                                    },
                                    data: {
                                        ...reverseProviderQuotationStatus({
                                            agent: null,
                                            provider: 'refused'
                                        }),
                                        agent_accepted: props.item.agent_accepted,
                                        agent_refused: props.item.agent_refused,
                                        provider_comment: !!comment ? comment : null
                                    }
                                });
                                switch (props.type) {
                                    case 'flight': {
                                        dispatch({
                                            type: 'FLIGHT_EDIT_CART_BY_ID',
                                            payload: CheckResponse([response], trip?.end_date ?? new Date().toISOString())[0]!
                                        });
                                        break;
                                    }
                                    case 'car': {
                                        dispatch({
                                            type: 'CAR_EDIT_CART_BY_ID',
                                            payload: response
                                        });
                                        break;
                                    }
                                    case 'accommodation': {
                                        dispatch({
                                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                            payload: response
                                        });
                                        break;
                                    }
                                    case 'transfer': {
                                        dispatch({
                                            type: 'TRANSFER_EDIT_CART_BY_ID',
                                            payload: response
                                        });
                                        break;
                                    }
                                    case 'poi': {
                                        dispatch({
                                            type: 'POI_EDIT_CART_BY_ID',
                                            payload: response
                                        });
                                        break;
                                    }
                                    default: {
                                        dispatch({
                                            type: 'CART_EDIT_MANUAL_ITEM',
                                            payload: response
                                        });
                                        break;
                                    }
                                }
                            } catch (error) {
                                showError(error as Error);
                            } finally {
                                setLoadingStatus(false);
                            }
                        })();
                    }
                }
            }
        };

        const onConfirmBookingCommentModal = (comment: string | null) => {
            if (
                providerContext.module === 'booking' &&
                providerContext.tripId &&
                providerContext.version &&
                providerContext.providerId
            ) {
                changeComment(
                    {
                        id: props.item.id,
                        type: (() => {
                            switch (props.type) {
                                case 'accommodation': return 'accommodations';
                                case 'assistance': return 'assistances';
                                case 'car': return 'cars';
                                case 'flight': return 'flights';
                                case 'manual':
                                case 'manual-accommodation': return 'manual';
                                case 'poi': return 'pois';
                                case 'transfer': return 'transfers';
                            }
                        })(),
                        providerComment: comment,
                        isCustom: props.item.is_custom
                    },
                    {
                        tripId: providerContext.tripId,
                        version: providerContext.version,
                        providerId: providerContext.providerId
                    }
                );
            }
        };

        const onChangeProductBookingStatus = (status: StatusBooking | null, isManual: boolean) => {
            if (
                providerContext.module === 'booking' &&
                providerContext.tripId &&
                providerContext.version
            ) {
                changeBookingStatus({
                    tripId: providerContext.tripId,
                    version: providerContext.version,
                    productId: props.item.id,
                    ref: props.item.booking_status?.item_reference ?? '',
                    status,
                    isManual,
                    providerToken: providerContext.providerToken
                });
                setOpenBookingCommentModal(true);
            }
        };

        const SideActions = (minimal = false) => {
            if (providerContext.module && (providerContext.module !== 'quotation' || !props.item.provider_created)) {
                return null;
            }

            return (
                <Stack
                    direction={
                        !minimal ?
                            { xs: 'row-reverse', md: 'column' } :
                            'row'
                    }
                    alignItems="flex-start"
                >
                    {
                        isDeleteButtonEnabled({
                            item: {
                                type: props.type !== 'manual-accommodation' ? props.type : 'manual',
                                product: props.item
                            },
                            trip,
                            permissions,
                            user
                        }) &&
                        <IconButton
                            sx={{
                                position: 'relative',
                                top: !minimal ? '-11px' : 0,
                                padding: minimal ? 0 : undefined
                            }}
                            onClick={() => setOpenDeleteModal(true)}
                        >
                            <Close />
                        </IconButton>
                    }
                    <IconButton
                        ref={menuButtonRef}
                        onClick={() => setOpenMenu(true)}
                        sx={(theme) => ({
                            position: 'relative',
                            padding: minimal ? 0 : undefined,
                            [theme.breakpoints.down('md')]: {
                                top: '-11px'
                            }
                        })}
                    >
                        <MoreVertOutlined />
                    </IconButton>
                </Stack>
            );
        };

        useEffect(() => {
            setShowDetails(openProductsDetails);
        }, [openProductsDetails]);

        useEffect(() => {
            if (props.alwaysOpen) {
                setShowDetails(true);
            }
        }, [props.alwaysOpen]);

        useEffect(() => {
            const handler = (event: CustomEvent) => {
                if (event.detail === props.item.id) {
                    commentModalAction.current = 'new-proposal';
                    setOpenCommentModal(true);
                }
            }
            document.addEventListener('counter-offer-created', handler as EventListener);
            return () => {
                document.removeEventListener('counter-offer-created', handler as EventListener);
            };
        }, []);

        return (
            <>
                <Card
                    elevation={0}
                    sx={{
                        width: '100%',
                        backgroundColor: color,
                        border: '1px solid rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <Stack sx={{ width: '100%' }}>
                        <Box
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: props.direction === 'vertical' ?
                                    'column' :
                                    'row',
                                borderBottom: showDetails ?
                                    '1px solid rgba(0, 0, 0, 0.25)' :
                                    undefined,
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column'
                                }
                            })}>
                            {
                                !props.disablePictures &&
                                <CardMedia
                                    key={props.pictures[currentPictureIndex] ?? ''}
                                    image={props.pictures[currentPictureIndex] ?? ''}
                                    sx={(theme) => ({
                                        backgroundSize: props.showEntirePictures ? '80%' : 'cover',
                                        width: props.direction === 'vertical' ?
                                            '100%' :
                                            '25%',
                                        height: props.direction === 'vertical' ?
                                            250 :
                                            undefined,
                                        position: 'relative',
                                        [`.${iconButtonClasses.root}`]: {
                                            display: 'none',
                                            [theme.breakpoints.down('md')]: {
                                                display: 'inline-flex'
                                            }
                                        },
                                        [`&:hover .${iconButtonClasses.root}`]: {
                                            display: 'inline-flex'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            height: 200
                                        }
                                    })}
                                >
                                    {
                                        context.enableBooking &&
                                        isProductBookable(
                                            props,
                                            user,
                                            trip?.stack_info ?? null,
                                            quotationCode
                                        ) &&
                                        <Checkbox
                                            onChange={onToggleForBooking}
                                            checked={checked}
                                            sx={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0
                                            }}
                                            icon={
                                                <Box
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: '3px',
                                                        backgroundColor: '#fff'
                                                    }}
                                                >
                                                    <CheckBoxOutlineBlankOutlined
                                                        viewBox="3 3 18 18"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            checkedIcon={
                                                <Box
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: '3px',
                                                        backgroundColor: '#fff'
                                                    }}
                                                >
                                                    <CheckBox
                                                        viewBox="3 3 18 18"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            color: '#E6592F'
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            disableRipple
                                        />
                                    }
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        sx={(theme) => ({
                                            "position": 'absolute',
                                            "top": '50%',
                                            "transform": 'translateY(-50%)',
                                            "backgroundColor": '#f4f4f4',
                                            "color": '#000',
                                            '&:hover': {
                                                color: theme.palette.getContrastText('#121212'),
                                                backgroundColor: '#121212'
                                            }
                                        })}
                                        onClick={() => {
                                            const index = currentPictureIndex - 1 < 0 ?
                                                props.pictures.length - 1 :
                                                currentPictureIndex - 1;
                                            setCurrentPictureIndex(index);
                                        }}
                                    >
                                        <KeyboardArrowLeftOutlined />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        sx={(theme) => ({
                                            "position": 'absolute',
                                            "right": 0,
                                            "top": '50%',
                                            "transform": 'translateY(-50%)',
                                            "backgroundColor": '#f4f4f4',
                                            "color": '#000',
                                            '&:hover': {

                                                color: theme.palette.getContrastText('#121212'),
                                                backgroundColor: '#121212'
                                            }
                                        })}
                                        onClick={() => {
                                            const index = currentPictureIndex + 1 >= props.pictures.length ?
                                                0 :
                                                currentPictureIndex + 1;
                                            setCurrentPictureIndex(index);
                                        }}
                                    >
                                        <KeyboardArrowRightOutlined />
                                    </IconButton>
                                    <Button
                                        size="small"
                                        color="inherit"
                                        variant="outlined"
                                        sx={{
                                            position: 'absolute',
                                            color: '#fff',
                                            right: 10,
                                            bottom: 10
                                        }}
                                        onClick={() => setOpenPicturesModal(true)}
                                    >
                                        {t('cart-material.edit-pictures')}
                                    </Button>
                                </CardMedia>
                            }
                            <CardContent
                                sx={(theme) => ({
                                    width: props.direction === 'vertical' || props.disablePictures ?
                                        '100%' :
                                        '75%',
                                    paddingBottom: `${theme.spacing(2)} !important`,
                                    [theme.breakpoints.down('md')]: {
                                        width: '100%'
                                    }
                                })}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={{ md: 8 }}
                                    flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                >
                                    {
                                        !isFunction(props.mainContent) ?
                                            props.mainContent :
                                            props.mainContent(SideActions)
                                    }
                                    {
                                        (
                                            props.sideContent ||
                                            !props.direction ||
                                            props.direction === 'horizontal'
                                        ) &&
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            paddingTop={{ xs: 1.5, md: 0 }}
                                            margin={{ xs: 'auto', md: 0 }}
                                        >
                                            {
                                                (
                                                    !providerContext.module ||
                                                    providerContext.priceType === 'products-total'
                                                ) &&
                                                props.sideContent &&
                                                (
                                                    !isProductPackaged({
                                                        product: props.item,
                                                        stackInfos: trip?.stack_info ?? null
                                                    }) ||
                                                    stackInfo?.display_product_price ||
                                                    providerContext.module
                                                ) &&
                                                props.sideContent
                                            }
                                            {
                                                (!props.direction || props.direction === 'horizontal') &&
                                                SideActions()
                                            }
                                        </Stack>
                                    }
                                </Stack>
                                {
                                    (
                                        footerHasContent ||
                                        containerHasContent
                                    ) &&
                                    <Divider sx={{ marginTop: 2, marginBottom: 1.5 }} />
                                }
                                <Stack
                                    direction={{ md: 'row' }}
                                    alignItems="center"
                                    justifyContent={
                                        props.footer ?
                                            'space-between' :
                                            'flex-end'
                                    }
                                    paddingRight={{ md: 5 }}
                                    spacing={2}
                                >
                                    <Box
                                        ref={(element) => {
                                            if (element && !hasTextContent(element as HTMLDivElement)) {
                                                setFooterHasContent(false);
                                            }
                                        }}
                                        sx={{ flex: 1 }}
                                    >
                                        {
                                            !isFunction(props.footer) ?
                                                props.footer :
                                                props.footer(
                                                    providerContext.module === 'quotation' ?
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            sx={{
                                                                marginTop: itemProviderData?.status?.agent ||
                                                                    itemProviderData?.status?.provider ?
                                                                    1.5 :
                                                                    undefined
                                                            }}
                                                        >
                                                            <Box sx={{ width: '100%' }}>
                                                                {
                                                                    (
                                                                        itemProviderData?.status?.agent ||
                                                                        itemProviderData?.status?.provider
                                                                    ) &&
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        sx={{ marginBottom: 1 }}
                                                                    >
                                                                        {
                                                                            !itemProviderData?.status?.agent &&
                                                                            <HourglassEmpty />
                                                                        }
                                                                        {
                                                                            itemProviderData?.status?.agent === 'accepted' &&
                                                                            <ThumbUp color="success" fontSize="small" />
                                                                        }
                                                                        {
                                                                            itemProviderData?.status?.agent === 'refused' &&
                                                                            <ThumbDown color="error" fontSize="small" />
                                                                        }
                                                                        <Typography variant="caption">
                                                                            {
                                                                                !itemProviderData?.status?.agent &&
                                                                                t('cart-material.provider-quotation-agent-question')
                                                                            }
                                                                            {
                                                                                itemProviderData?.status?.agent === 'accepted' &&
                                                                                t('cart-material.provider-quotation-product-confirmed-by-agent')
                                                                            }
                                                                            {
                                                                                itemProviderData?.status?.agent === 'refused' &&
                                                                                t('cart-material.provider-quotation-product-refused-by-agent')
                                                                            }
                                                                        </Typography>
                                                                        {
                                                                            (props.item.agent_comment?.trim().length ?? 0) > 0 &&
                                                                            <LightTooltip
                                                                                title={
                                                                                    <Box sx={{ padding: 2 }}>
                                                                                        <Typography
                                                                                            fontWeight="bold"
                                                                                            sx={{
                                                                                                marginBottom: 1,
                                                                                                textDecoration: 'underline'
                                                                                            }}
                                                                                        >
                                                                                            {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            {props.item.agent_comment}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <ContactSupportOutlined
                                                                                    fontSize="inherit"
                                                                                    sx={{ cursor: 'help' }}
                                                                                />
                                                                            </LightTooltip>
                                                                        }
                                                                    </Stack>
                                                                }
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    columnGap={1}
                                                                >
                                                                    {
                                                                        itemProviderData?.status?.provider === 'accepted' &&
                                                                        <ThumbUp color="success" fontSize="small" />
                                                                    }
                                                                    {
                                                                        itemProviderData?.status?.provider === 'refused' &&
                                                                        <ThumbDown color="error" fontSize="small" />
                                                                    }
                                                                    {
                                                                        props.item.provider_refused !== props.item.provider_accepted &&
                                                                        <Typography variant="caption">
                                                                            {
                                                                                itemProviderData?.status?.provider === 'accepted' &&
                                                                                t('cart-material.provider-quotation-product-confirmed')
                                                                            }
                                                                            {
                                                                                itemProviderData?.status?.provider === 'refused' &&
                                                                                t('cart-material.provider-quotation-product-refused')
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                    {
                                                                        itemProviderData?.status?.provider &&
                                                                        (itemProviderData.providerComment?.trim().length ?? 0) > 0 &&
                                                                        <LightTooltip
                                                                            title={
                                                                                <Box sx={{ padding: 2 }}>
                                                                                    <Typography
                                                                                        fontWeight="bold"
                                                                                        sx={{
                                                                                            marginBottom: 1,
                                                                                            textDecoration: 'underline'
                                                                                        }}
                                                                                    >
                                                                                        {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        {itemProviderData.providerComment}
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                            arrow
                                                                        >
                                                                            <ContactSupportOutlined
                                                                                fontSize="inherit"
                                                                                sx={{ cursor: 'pointer' }}
                                                                                onClick={onEditComment}
                                                                            />
                                                                        </LightTooltip>
                                                                    }
                                                                    {
                                                                        !itemProviderData?.status?.provider &&
                                                                        <Box sx={{ display: 'flex' }}>
                                                                            <Tooltip title={t('cart-material.provider-quotation-confirm')}>
                                                                                <IconButton
                                                                                    color="success"
                                                                                    onClick={onAccept}
                                                                                >
                                                                                    <Check />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title={t('cart-material.provider-quotation-refuse')}>
                                                                                <IconButton
                                                                                    color="error"
                                                                                    onClick={onRefuse}
                                                                                >
                                                                                    <Close />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {
                                                                                !props.item.provider_created &&
                                                                                <Tooltip title={t('cart-material.provider-quotation-new-proposal')}>
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={onMakeNewProposition}
                                                                                    >
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                            {
                                                                                props.item.provider_created &&
                                                                                <Tooltip title={t('shared.delete')}>
                                                                                    <IconButton
                                                                                        color="warning"
                                                                                        onClick={() => setOpenDeleteModal(true)}
                                                                                    >
                                                                                        <Delete />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                        </Box>
                                                                    }
                                                                    {
                                                                        itemProviderData?.status?.provider &&
                                                                        (
                                                                            itemProviderData.status.provider !== 'accepted' ||
                                                                            itemProviderData.status.agent !== 'accepted'
                                                                        ) &&
                                                                        <Button
                                                                            onClick={() => providerContext.onChangeStatus(
                                                                                props.item.id,
                                                                                {
                                                                                    agent: props.item.agent_accepted ?
                                                                                        'accepted' :
                                                                                        (
                                                                                            props.item.agent_refused ?
                                                                                                'refused' :
                                                                                                null
                                                                                        ),
                                                                                    provider: null
                                                                                },
                                                                                null
                                                                            )}
                                                                            sx={{ marginLeft: 'auto !important' }}
                                                                        >
                                                                            {t('shared.cancel')}
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            </Box>
                                                        </Stack> :
                                                        (
                                                            providerContext.module === 'booking' ?
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    flexWrap="wrap"
                                                                    justifyContent="space-between"
                                                                    sx={{ flex: 1, paddingTop: 1 }}
                                                                >
                                                                    {
                                                                        (
                                                                            !props.item.booking_status ||
                                                                            [
                                                                                StatusBooking.WAITING,
                                                                                StatusBooking.PENDING
                                                                            ].includes(props.item.booking_status.status_booking)
                                                                        ) &&
                                                                        <FormControl
                                                                            size="small"
                                                                            sx={{ minWidth: 250 }}
                                                                            fullWidth
                                                                        >
                                                                            <InputLabel>
                                                                                {t('cart-material.provider-quotation-action-question')}
                                                                            </InputLabel>
                                                                            <Select
                                                                                value={props.item.booking_status?.status_booking}
                                                                                label={t('cart-material.provider-quotation-action-question')}
                                                                                onChange={(event) => {
                                                                                    switch (event.target.value) {
                                                                                        case StatusBooking.CONFIRMED: {
                                                                                            onChangeProductBookingStatus(
                                                                                                StatusBooking.CONFIRMED,
                                                                                                props.type.includes('manual')
                                                                                            );
                                                                                            break;
                                                                                        }
                                                                                        case StatusBooking.REFUSED: {
                                                                                            onChangeProductBookingStatus(
                                                                                                StatusBooking.REFUSED,
                                                                                                props.type.includes('manual')
                                                                                            );
                                                                                            break;
                                                                                        }
                                                                                        case StatusBooking.WAITING: {
                                                                                            onChangeProductBookingStatus(
                                                                                                StatusBooking.WAITING,
                                                                                                props.type.includes('manual')
                                                                                            );
                                                                                            break;
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem value={StatusBooking.CONFIRMED}>
                                                                                    {t('cart-material.provider-quotation-confirm')}
                                                                                </MenuItem>
                                                                                <MenuItem value={StatusBooking.REFUSED}>
                                                                                    {t('cart-material.provider-quotation-refuse')}
                                                                                </MenuItem>
                                                                                <MenuItem value={StatusBooking.WAITING}>
                                                                                    {t('cart-material.provider-quotation-waiting')}
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    }
                                                                    {
                                                                        props.item.booking_status &&
                                                                        [
                                                                            StatusBooking.CONFIRMED,
                                                                            StatusBooking.REFUSED,
                                                                            StatusBooking.UNAVAILABLE
                                                                        ].includes(props.item.booking_status.status_booking) &&
                                                                        <Button onClick={() => onChangeProductBookingStatus(null, props.type.includes('manual'))}>
                                                                            {t('shared.cancel')}
                                                                        </Button>
                                                                    }
                                                                </Stack> :
                                                                null
                                                        ),
                                                    providerContext.module === 'quotation' &&
                                                        props.item.is_optional ?
                                                        <Typography variant="body2" fontStyle="italic" sx={{ marginTop: 2 }}>
                                                            {t('cart-material.provider-option-hint')}
                                                        </Typography> :
                                                        null
                                                )
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {
                                            !showDetails &&
                                            !providerContext.module &&
                                            props.item.provider_comment &&
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                            >
                                                {t('cart-material.provider-added-comment')}
                                            </Typography>
                                        }
                                        {
                                            !showDetails &&
                                            providerContext.module &&
                                            props.item.agent_comment &&
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                            >
                                                {t('cart-material.agent-added-comment')}
                                            </Typography>
                                        }
                                        {
                                            containerHasContent &&
                                            !props.alwaysOpen &&
                                            <Button
                                                color="inherit"
                                                sx={(theme) => ({
                                                    color: '#fff',
                                                    borderRadius: 20,
                                                    backgroundColor: '#FC4400',
                                                    border: '1px solid #FC4400',
                                                    textTransform: 'none',
                                                    whiteSpace: 'nowrap',
                                                    ['&:hover']: {
                                                        border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                        color: theme.palette.getContrastText('#f4f4f4'),
                                                        backgroundColor: '#f4f4f4'
                                                    },
                                                    [theme.breakpoints.down('md')]: {
                                                        margin: 'auto !important',
                                                        marginTop: `${theme.spacing(2)} !important`
                                                    }
                                                })}
                                                onClick={() => setShowDetails((state) => !state)}
                                            >
                                                {
                                                    showDetails ?
                                                        t('cart-material.cart-construction-hide-details') :
                                                        t('cart-material.cart-construction-show-details')
                                                }
                                            </Button>
                                        }
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Box>
                        <Collapse
                            in={
                                showDetails &&
                                containerHasContent
                            }
                        >
                            <CardContent
                                ref={(element) => {
                                    if (element && !hasTextContent(element)) {
                                        setContainerHasContent(false);
                                    }
                                }}
                            >
                                {props.children}
                            </CardContent>
                        </Collapse>
                        {
                            !providerContext.module &&
                            props.alerts
                        }
                        {props.margin}
                    </Stack>
                </Card>
                {
                    props.type === 'accommodation' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteAccommodation(props.item)}
                    />
                }
                {
                    props.type === 'car' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteCar(props.item)}
                    />
                }
                {
                    props.type === 'flight' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteFlight(props.item)}
                    />
                }
                {
                    props.type === 'train' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteTrain(props.item)}
                    />
                }
                {
                    props.type === 'poi' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeletePoi(props.item)}
                    />
                }
                {
                    props.type === 'transfer' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteTransfer(props.item)}
                    />
                }
                {
                    props.type === 'assistance' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteAssistance(props.item)}
                    />
                }
                {
                    props.type === 'manual-accommodation' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteManualProduct(props.item)}
                    />
                }
                {
                    props.type === 'manual' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteManualProduct(props.item)}
                    />
                }
                {
                    props.type === 'accommodation' &&
                    <CartConstructionProductsTableItemMenu
                        type="accommodation"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        rooms={props.item.rooms?.map((room) => room.id) ?? []}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'car' &&
                    <CartConstructionProductsTableItemMenu
                        type="car"
                        index={props.index}
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'flight' &&
                    <CartConstructionProductsTableItemMenu
                        type="flight"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'train' &&
                    <CartConstructionProductsTableItemMenu
                        type="train"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'poi' &&
                    <CartConstructionProductsTableItemMenu
                        type="poi"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'transfer' &&
                    <CartConstructionProductsTableItemMenu
                        type="transfer"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        isCustom={props.item.is_custom}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'assistance' &&
                    <CartConstructionProductsTableItemMenu
                        type="assistance"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'manual-accommodation' &&
                    <CartConstructionProductsTableItemMenu
                        type="manual"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'manual' &&
                    <CartConstructionProductsTableItemMenu
                        type="manual"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    openPicturesModal &&
                    <CartProductPicturesModal
                        type={props.type as any} //TODO: fix types
                        item={props.item as any}
                        onClose={() => setOpenPicturesModal(false)}
                    />
                }
                {
                    openCommentModal &&
                    <ProviderQuotationProductCommentModal
                        defaultComment={props.item.provider_comment}
                        onConfirm={onConfirmCommentModal}
                        onClose={() => setOpenCommentModal(false)}
                        disableCancel={commentModalAction.current === 'new-proposal'}
                    />
                }{
                    openBookingCommentModal &&
                    <ProviderBookingProductCommentModal
                        defaultComment={props.item.provider_booking_comment}
                        onConfirm={onConfirmBookingCommentModal}
                        onClose={() => setOpenBookingCommentModal(false)}
                    />
                }
                <LoadingBackDrop open={loadingStatus} />
            </>
        );
    }
);

type CounterOfferWrapperProps = Pick<CartProductCardProps, 'item'>;

function CounterOfferWrapper(props: PropsWithChildren<CounterOfferWrapperProps>): JSX.Element {
    const cart = useCartProducts();
    const hasCounterOffer = useMemo(() => {
        return cart.accommodations.some((item) => {
            return item.accommodation.counter_offer_of === props.item.id;
        }) ||
            cart.assistances.some((item) => {
                return item.assistance.counter_offer_of === props.item.id;
            }) ||
            cart.cars.some((item) => {
                return item.car.counter_offer_of === props.item.id;
            }) ||
            cart.cruises.some((item) => {
                return item.cruise.counter_offer_of === props.item.id;
            }) ||
            cart.ferries.some((item) => {
                return item.ferry.counter_offer_of === props.item.id;
            }) ||
            cart.flights.some((item) => {
                return item.flight.counter_offer_of === props.item.id;
            }) ||
            cart.insurances.some((item) => {
                return item.insurance.counter_offer_of === props.item.id;
            }) ||
            cart.pois.some((item) => {
                return item.poi.counter_offer_of === props.item.id;
            }) ||
            cart.trains.some((item) => {
                return item.train.counter_offer_of === props.item.id;
            }) ||
            cart.transfers.some((item) => {
                return item.transfer.counter_offer_of === props.item.id;
            });
    }, [cart]);

    return (
        <Box
            sx={(theme) => ({
                width: '100%',
                '& > div': {
                    backgroundColor: hasCounterOffer ?
                        theme.palette.grey[300] :
                        undefined
                }
            })}
        >
            {props.children}
        </Box>
    );
}

export function CartProductCard(props: CartProductCardProps): JSX.Element {
    return (
        <CounterOfferWrapper item={props.item}>
            <Content {...props} />
        </CounterOfferWrapper>
    );
}