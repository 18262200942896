import { makeStyles } from "@material-ui/core";
import { Stack, Typography, Button, Divider, Tooltip } from "@mui/material";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrainForm } from "./objects/trainForm";
import { Schedule } from "@mui/icons-material";
import moment from "moment";
import { TrainSegment } from "./objects/train";
import { TrainListContentFormTarifSegments } from "./trainListContentFormTarifSegments";
import { MarketingInformationIcon, MarketingInformationIconPAO } from "./objects/backendData/trainMarketingInformationIcon";
import { ServiceOffersEntity } from "./objects/backendData/trainBackendDate";
import { ServiceTypeIcons } from "./components/services/serviceTypeIcon";
import { TrainListContentFormTarifOffers } from "./trainListContentFormTarifOffers";
import { getProviderIcon } from "./utils/provider/getProviderIcon";

interface TrainListContentFormTarifProps {
    closeDrawer: () => void;
    train_form: TrainForm;
    setTrainFormAction: (train_form: TrainForm | null) => void;
    is_departure: boolean;
}

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        height: '100vh',
        flexDirection: 'column',
        gap: theme.spacing(2),
        backgroundColor: '#F3F3F8'
    },
    drawerHeader: {
        padding: '1rem 1rem',
        backgroundColor: 'black'
    },
    drawerContent: {
        flex: '0 1 100%',
        overflow: 'auto',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: '1rem'
    }
}));

export const TrainListContentFormTarif: FC<TrainListContentFormTarifProps> = ({ is_departure, train_form, closeDrawer, setTrainFormAction }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    // const offer = useMemo(() => train_form?.offer, [train_form]);
    // const getAvailableServices = (segment: TrainSegment): ServiceOffersEntity[] => offer.service_offers.filter((service) => !!service.segments?.find(seg => seg === segment.id));

    const common_available_services = useMemo(() => {
        const allOffers = train_form?.solution?.offers || [];
        if (allOffers.length === 0) return [];
      
        const serviceTypeCounts = new Map<string, number>();
      
        // Count occurrences of each service_type across all offers
        allOffers.forEach((offer) => {
          const uniqueServiceTypes = new Set(offer.service_offers.map(service => service.service_type));
          uniqueServiceTypes.forEach(serviceType => {
            serviceTypeCounts.set(serviceType, (serviceTypeCounts.get(serviceType) || 0) + 1);
          });
        });
      
        // Collect services that have service_type visible in all offers
        const commonServices: ServiceOffersEntity[] = [];
        allOffers[0]!.service_offers.forEach((service) => {
          if (serviceTypeCounts.get(service.service_type) === allOffers.length) {
            commonServices.push(service);
          }
        });
      
        return commonServices;
      }, [train_form?.solution?.offers]);

    return (<Stack className={classes.drawerContainer}>
        <Stack justifyContent={'space-between'} direction='row' className={classes.drawerHeader}>
            <Stack><Typography variant="subtitle1" fontSize={20} color="white">{t("train.choose-a-rate")}</Typography></Stack>
            <Button onClick={closeDrawer}>{t("shared.close")}</Button>
        </Stack>
        <Stack className={classes.drawerContent}>
            <Stack direction={'column'}>
                <Stack direction={'row'} padding={'1rem'} paddingTop={'0'}><Typography variant='body2' fontSize={'1rem'}>{is_departure ? t("global.go") : t("global.Return")} : {moment.utc(train_form.solution.departure).format('ddd DD MMM')}</Typography></Stack>
                <Divider />
                <Stack direction={'column'} spacing={1} padding={'1rem'}>
                    <Stack direction={'row'} spacing={0.5}>
                        <Typography variant='body2' fontWeight={'bold'} fontSize={'1rem'}>{moment.utc(train_form.solution.departure).format('HH:mm')}</Typography>
                        <Typography variant='body2' fontSize={'1rem'}>{train_form.solution.origin.label}</Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='body2' fontWeight={'bold'} fontSize={'1rem'}>{moment.utc(train_form.solution.arrival).format('HH:mm')}</Typography>
                        <Typography variant='body2' fontSize={'1rem'}>{train_form.solution.destination.label}</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction='row' spacing={1} sx={{ minHeight: '2.625rem', padding: '0 1rem' }} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction='row' spacing={1} alignItems={'center'}>
                        <Schedule sx={{ width: '15px', height: '15px', color: 'rgb(94, 104, 120)' }} />
                        <Typography variant="caption" sx={{ color: 'rgb(94, 104, 120)', lineHeight: '0 !important' }}>{`${Math.floor(require('moment').duration(train_form.solution.duration).asHours())}h${require('moment').duration(train_form.solution.duration).minutes()}m`}</Typography>
                        {train_form.solution.segments.length === 1 && <>
                            <Typography variant="caption" sx={{ color: 'rgb(94, 104, 120)', lineHeight: '0 !important' }}>{t("shared.direct")}</Typography>
                            <img src={getProviderIcon(train_form.solution.segments[0]!.marketingInformation, {operatingCarrier: train_form.solution.segments[0]!.operatingCarrier, marketingCarrier: train_form.solution.segments[0]!.marketingCarrier})} style={{ height: '1rem' }} />
                        </>}
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        {common_available_services.map((service) => {
                            const Icon = ServiceTypeIcons[service.service_type];
                            return <Tooltip key={`offer-service-${service.service_id}-${service.service_type}`} title={t(`train.service.${service.service_type}`)}><Icon sx={{ width: '15px', height: '15px', color: 'rgb(0,0,0,0.3)' }} /></Tooltip>
                        })}
                    </Stack>
                </Stack>
            </Stack>
            <TrainListContentFormTarifOffers setTrainFormAction={setTrainFormAction} />
            <TrainListContentFormTarifSegments segments={train_form.solution.segments} />
        </Stack>
    </Stack>

    );
}