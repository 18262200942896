import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Info, LockOpen } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import {
    useUpdateProductProviderQuotationStatus
} from "./utils/updateProductProviderQuotationStatus";
import { useSendProviderQuotationDemand } from "./utils/sendProviderQuotationDemand";
import {
    getProviderQuotationStatus,
    reverseProviderQuotationStatus
} from "../ProviderQuotation/utils/getProviderQuotationProductStatus";
import GetCookie from "../Common/Functions/GetCookie";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { AppState, Key } from "../../Reducers/Reducers";
import { editTrainCartById } from "../train/redux/reducer";

type Product = Parameters<ReturnType<typeof useUpdateProductProviderQuotationStatus>>[1]

type Props = {
    item: Product
}

export function CartPackageItemChangeProviderQuotationStatusButtons(props: Props): React.ReactNode {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const languages = useSelector((state: AppState) => state.user.locales);
    const view = useSelector((state: AppState) => state.cartConstruction.productsView);
    const [loading, setLoading] = useState(false);
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [comment, setComment] = useState('');
    const [translateTargetLanguage, setTranslateTargetLanguage] = useState('');
    const commentModalAction = useRef<'accept' | 'refuse' | null>(null);
    const version = parseInt(GetCookie('trip_id_version') ?? '-1');
    const status = getProviderQuotationStatus(props.item);
    const showError = useShowError();
    const sendQuotationMail = useSendProviderQuotationDemand({});
    const updateProduct = useUpdateProductProviderQuotationStatus({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(item) {
            if (trip) {
                enqueueSnackbar(
                    t('cart-material.provider-quotation-product-updated'),
                    { variant: 'success' }
                );
                switch (item.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([item.data], trip.end_date)[0]!
                        });
                        break;
                    }
                    case 'trains': {
                        dispatch(editTrainCartById(CheckResponse([item.data], trip.end_date)[0]!));
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: item.data
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: item.data
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: item.data
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: item.data
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: item.data
                        });
                        break;
                    }
                }
                setOpenCommentModal(false);
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const commentIsTooLong = comment.length > 500;


    const onAccept = () => {
        commentModalAction.current = 'accept';
        setOpenCommentModal(true);
    };


    const onRefuse = () => {
        commentModalAction.current = 'refuse';
        setOpenCommentModal(true);
    };

    const onUnlockPrice = () => {
        updateProduct(
            version,
            {
                ...props.item,
                provider_want_modification: false,
                agent_accepted: false,
                agent_refused: false,
                provider_accepted: false,
                provider_refused: false
            }
        );
        if (props.item.stackNumber) {
            sendQuotationMail(
                version,
                [
                    {
                        stackNumber: props.item.stackNumber,
                        providerId: props.item.providerId
                    }
                ],
                false
            );
        }
    }

    const onCommentConfirm = () => {
        switch (commentModalAction.current) {
            case 'accept': {
                updateProduct(
                    version,
                    {
                        ...props.item,
                        ...reverseProviderQuotationStatus({
                            provider: null,
                            agent: 'accepted'
                        }),
                        agent_comment: comment,
                        provider_accepted: props.item.provider_accepted,
                        provider_refused: props.item.provider_refused
                    }
                );
                break;
            }
            case 'refuse': {
                updateProduct(
                    version,
                    {
                        ...props.item,
                        ...reverseProviderQuotationStatus({
                            provider: null,
                            agent: 'refused'
                        }),
                        agent_comment: comment,
                        provider_accepted: props.item.provider_accepted,
                        provider_refused: props.item.provider_refused
                    }
                );
                break;
            }
        }
    };

    const onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        updateProduct(
            version,
            {
                ...props.item,
                ...reverseProviderQuotationStatus({
                    provider: null,
                    agent: null
                }),
                provider_accepted: props.item.provider_accepted,
                provider_refused: props.item.provider_refused
            }
        );
    };

    const onTranslate = async () => {
        const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
        const key = config.keys?.find((item) => item.identifier === 'google_api');

        if (key) {
            try {
                setLoading(true);
                const response = await axios.post<{
                    data: {
                        translations: {
                            translatedText: string
                        }[]
                    }
                }>(
                    'https://translation.googleapis.com/language/translate/v2',
                    {
                        q: [comment],
                        target: translateTargetLanguage,
                        format: 'text'
                    },
                    {
                        params: {
                            key: key.value
                        }
                    }
                );
                setComment(response.data.data.translations.map((item) => item.translatedText).join(''));
            } catch (error) {
                showError(error as Error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setComment(props.item.agent_comment ?? '');
    }, [props.item]);

    if (!status.provider && !status.agent) {
        return null;
    }

    return (
        <>
            <div>
                {
                    view === 'cards' &&
                    <Typography
                        fontWeight="bold"
                        textAlign="right"
                        sx={{
                            marginBottom: 1,
                            textDecoration: 'underline'
                        }}
                    >
                        {t('cart-material.cart-to-agent-actions-title')}
                    </Typography>
                }
                {
                    status.provider &&
                    !status.agent &&
                    <Stack
                        direction={view === 'cards' ? 'row' : 'column'}
                        spacing={1}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            color="success"
                            onClick={onAccept}
                        >
                            {t('cart-material.provider-quotation-confirm')}
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={onRefuse}
                        >
                            {t('cart-material.provider-quotation-refuse')}
                        </Button>
                    </Stack>
                }
                {
                    props.item.provider_want_modification &&
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        startIcon={
                            <LockOpen fontSize="small" />
                        }
                        endIcon={
                            <Tooltip title={t('cart-material.unlock-provider-price-hint')}>
                                <Info fontSize="small" />
                            </Tooltip>
                        }
                        onClick={onUnlockPrice}
                        sx={{ marginX: 1 }}
                    >
                        {t('shared.price')}
                    </Button>
                }
                {
                    status.agent &&
                    (status.provider !== 'accepted' || status.agent !== 'accepted') &&
                    <Button
                        variant="contained"
                        size="small"
                        onClick={onCancel}
                    >
                        {
                            view === 'cards' ?
                                <>
                                    {
                                        status.agent === 'accepted' ?
                                            t('shared.cancel-confirmation') :
                                            t('shared.cancel-refusal')
                                    }
                                </> :
                                t('shared.cancel')
                        }
                    </Button>
                }
            </div>
            <Dialog
                open={openCommentModal}
                onClose={() => setOpenCommentModal(false)}
                onClick={(event) => event.stopPropagation()}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {t('cart-material.provider-quotation-comment')}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('cart-material.provider-quotation-comment-agent-title')}
                    </Typography>
                    <TextField
                        label={t('cart-material.provider-quotation-comment')}
                        value={comment}
                        sx={{ marginTop: 2 }}
                        minRows={10}
                        maxRows={10}
                        helperText={
                            <Stack direction="row">
                                {
                                    commentIsTooLong &&
                                    <Typography variant="caption">
                                        {t('cart-material.provider-quotation-comment-too-long-error')}
                                    </Typography>
                                }
                                <Typography
                                    variant="caption"
                                    sx={{ marginLeft: 'auto' }}
                                >
                                    {comment.length} / 500
                                </Typography>
                            </Stack>
                        }
                        onChange={(event) => setComment(event.target.value)}
                        error={commentIsTooLong}
                        multiline
                        fullWidth
                    />
                    <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                        <FormControl size="small">
                            <InputLabel shrink>{t('shared.translate-in')}</InputLabel>
                            <Select
                                label={t('shared.translate-in')}
                                value={translateTargetLanguage}
                                onChange={(event) => setTranslateTargetLanguage(event.target.value)}
                                notched
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    {t('cart-material.select-language')}
                                </MenuItem>
                                {
                                    languages.map((language) => (
                                        <MenuItem key={language.id} value={language.language_code}>
                                            {
                                                t(
                                                    `language.${language.language_code}`,
                                                    { defaultValue: language.full_name }
                                                )
                                            }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Button onClick={onTranslate}>
                            {t('shared.translate')}
                        </Button>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCommentModal(false)}>
                        {t('shared.cancel')}
                    </Button>
                    <Button onClick={onCommentConfirm} disabled={commentIsTooLong}>
                        {t('shared.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
