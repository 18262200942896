import React, { FC } from "react";
import { Divider, Skeleton, Stack } from "@mui/material";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Schedule } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    stackContainerResponsive: {
        flexDirection: 'column',
        flex: '0 1 70%',
        borderRadius: '0.6rem',
        border: '0.5px solid rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            flex: '0 1 100%',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }
    },
    priceBox: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: '0 1 15%',
        border: '0.5px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '0.6rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }
    }
}))

export const TrainListItemLoading:FC = () => {
    const classes= useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (<Stack direction='row' spacing={2} flex={'0 1 100%'}>
        <Stack className={classes.stackContainerResponsive}>
            <Stack direction={'column'} spacing={1} padding={'1rem'}>
                <Stack direction={'row'} spacing={0.5}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={42} height={21} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={115} height={21} />
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={42} height={22} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={117} height={21} />
                </Stack>
            </Stack>
            <Divider/>
            <Stack direction='row' spacing={1} sx={{minHeight: '2.625rem', padding: '0 1rem'}} alignItems={'center'}>
                <Schedule sx={{width: '15px', height: '15px', color: 'rgb(94, 104, 120)' }}/>     <Skeleton variant="text" width={39} height={19} />
            </Stack>
        </Stack>
        {!isMobile &&<>
            <Stack className={classes.priceBox}><Skeleton variant="text" width={35} height={21} /></Stack>
            <Stack className={classes.priceBox}><Skeleton variant="text" width={35} height={21} /></Stack>
        </>}
    </Stack>);
}