import axios, { CancelToken } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { TrainListFiltersCriteria } from "../objects/trainList";
import GetCookie from "../../Common/Functions/GetCookie";

const { headers } = CheckBeforeRequest();
export const searchTrains = (train_search_criteria: TrainListFiltersCriteria, cancelToken?: CancelToken) => {
    const { provider_id, ...criteria } = train_search_criteria;
    return axios.post(
        `${API_HREF}client/${window.id_owner}/trains/search/`,
        {
            ...criteria
        },
        {
            headers: headers,
            cancelToken,
            params: {
                trip_version: GetCookie("trip_id_version"),
                trip: GetCookie("trip_id"),
                provider_id
            }
        }
    )
};

export const searchTrainPlace = (place: string) => axios.get(
    `${API_HREF}client/${window.id_owner}/trains/places/`,
    {
        headers,
        params: {
            place
        }
    }
)

export const getProviders = () => axios.get(
    `${API_HREF}client/${window.id_owner}/trains/providers/`,
    { headers }
)