// Dependencies
import axios, { CancelTokenSource } from 'axios';
import React, { useState, useEffect, FC, ReactElement } from 'react';
// Core & Lab
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// Types
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { useShowError } from '../../../Utils/showError';
import { AppState } from '../../../../Reducers/Reducers';
import CheckBeforeRequest from '../../../Common/CheckBeforeRequest';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import { CustomTextField } from './customTextField';

let request_timeout: NodeJS.Timeout;
let cancelToken: CancelTokenSource;

export interface AutocompleteTemplateProps {
    value_parameters: {
        setValue: any;
        value: any;
        error?: string | boolean;
        // OptionType: any;
        getOptionLabel: ((option: any) => string);
        renderOption: (
            props: React.HTMLAttributes<HTMLLIElement>,
            option: any,
            state: AutocompleteRenderOptionState,
            ownerState: any,
        ) => React.ReactNode;
        required?: boolean;
        isOptionEqualToValue?: ((option: any, value: any) => boolean);
        label?: string;
        multiple?: boolean;
        renderGroup?: ((params: any) => React.ReactNode);
        groupBy?: ((option: any) => any);
        placeholder?: string;
        disabled?: boolean;
        // OptionType: any;
    }
    request_parameters: {
        url_path?: string;
        params?: any;
        google_search?: boolean;
        transformResults?: (results: any) => any[];
        search_fields?: string[];
        search_length_before_request?: number;
        predifined_option?: any[];
    }
    size?: 'small' | 'medium',
    sx?: any,
    text_field_sx?: any,
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode | null,
    CustomPoper?: any
}

const { headers } = CheckBeforeRequest();

export const AutocompleteTemplate: FC<AutocompleteTemplateProps> = ({ value_parameters, request_parameters, size, sx, text_field_sx, startAdornment, endAdornment, CustomPoper }): ReactElement => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<(any)[]>([]);
    const google = useSelector((state: AppState) => state.header.google);
    const showError = useShowError();
    const onSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearch(event.target.value);
    };
    const runRequest = () => {
        if (request_parameters.predifined_option) {
            return;
        }
        if (isNumber(request_parameters.search_length_before_request) && search?.length < request_parameters.search_length_before_request) {
            return;
        }
        if (request_parameters.google_search) {
            if (google !== null && search !== '') {
                setLoading(true);
                const autocompleteService = new google.maps.places.AutocompleteService();
                autocompleteService.getPlacePredictions({ input: search, language: i18next.language, fields: ['address_components', 'geometry/location'] }, (response: any, status: any) => {
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        setOptions(response);
                    }
                    setLoading(false);
                });
            }
        } else {
            setLoading(true);
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.");
            }
            cancelToken = axios.CancelToken.source();
            const request_param = { ...request_parameters.params };
            let searchFields: string[] = ['search'];
            if (request_parameters.search_fields) {
                searchFields = request_parameters.search_fields;
            }
            searchFields.forEach((search_fields) => {
                request_param[search_fields] = search !== '' ? search : undefined;
            });
            axios({
                method: 'GET',
                url: request_parameters.url_path,
                headers,
                cancelToken: cancelToken.token,
                params: {
                    ...request_parameters.params,
                    ...request_param,
                }
            }).then((response) => {
                if (request_parameters.transformResults) {
                    setOptions(request_parameters.transformResults(response));
                } else {
                    setOptions(response.data);
                }
                setLoading(false);
            }).catch((error) => {
                if (!axios.isCancel(error)) {
                    showError(error);
                    console.log('error : ', error);
                    setLoading(false);
                }
            });
        }
    };
    const onClose = () => {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
        setOpen(false);
        setLoading(false);
    };
    useEffect(() => {
        if (open) {
            clearTimeout(request_timeout);
            request_timeout = setTimeout(runRequest, 500);
        } else {
            setOptions([]);
        }
    }, [open, search]);
    useEffect(() => {
        if (value_parameters.value !== null && value_parameters.multiple) {
            const missing_option: any[] = [];
            value_parameters.value.map((val: any) => {
                let included = false;
                for (let i = 0; i < options.length; i++) {
                    if (options[i].id === val.id) {
                        included = true;
                    }
                }
                if (!included) {
                    missing_option.push(val);
                }
            });
            setOptions([...options, ...missing_option]);
        }
    }, [value_parameters.value]);
    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
                setSearch('');
            }}
            onClose={onClose}
            noOptionsText={t('list.autocomplete_no_options_text')}
            loadingText={t('list.autocomplete_loading_text')}
            clearOnBlur={true}
            clearOnEscape={true}
            isOptionEqualToValue={value_parameters.isOptionEqualToValue ? value_parameters.isOptionEqualToValue : (option, value) => option.id === value.id}
            getOptionLabel={value_parameters.getOptionLabel}
            renderOption={value_parameters.renderOption}
            options={request_parameters.predifined_option ? request_parameters.predifined_option : options}
            loading={loading}
            multiple={value_parameters.multiple}
            value={value_parameters.value}
            filterOptions={(x) => x}
            onChange={(event, value) => {
                value_parameters.setValue(value);
            }}
            disabled={value_parameters.disabled}
            sx={{ ...sx }}
            PopperComponent={CustomPoper}
            groupBy={value_parameters?.groupBy}
            renderGroup={value_parameters?.renderGroup}
            renderInput={(params) => (
                // <TextField
                <CustomTextField
                    {...params}
                    disabled={value_parameters.disabled}
                    error={!!value_parameters.error}
                    label={value_parameters.label}
                    placeholder={value_parameters.placeholder}
                    required={!!value_parameters.required}
                    onChange={onSearchChange}
                    sx={{ ...(text_field_sx || {}) }}
                    size={size}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: endAdornment === null ? undefined : endAdornment !== undefined ? (
                            <>
                                {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ) : endAdornment,
                        startAdornment:
                            <>
                                {startAdornment}
                                {params.InputProps.startAdornment}
                            </>


                    }}
                />
            )}
        />
    );
};
