import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { useUnpackagedProducts } from "../CartMaterial/utils/unpackagedProduts";
import { TrainCartCard } from './trainCartCard';
import CartManualProduct from '../CartMaterial/CartManualProduct';
import { ManualProduct } from '../../Reducers/objects/manualProduct';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        //height: '100%',
        width: 50,
        borderLeft: '6px solid #E6592F'
    },
    checkbox: {
        //position: 'absolute',
        //top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    marginBottom: {
        marginBottom: 32
    }
});

export const TrainCart: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const total_trains = useSelector((state: AppState) => state.cart.total_trains);
    const unpackagedProducts = useUnpackagedProducts();
    if (unpackagedProducts.trains.length > 0) {
        return (
            <div>
                <Typography className={classes.title}>
                    {t('cart-material.total-train')} ({unpackagedProducts.trains.length}) :{' '}
                    {total_trains.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`)}
                </Typography>
                <hr />
                {
                    unpackagedProducts.trains.map((item) => {
                        if (item.type === 'manual') {
                            return (
                                <Grid key={`train-manual-product-${item?.train.id}`} id={`train-manual-product-${item.train.id}`} container>
                                    <CartManualProduct manual_product={item.train as unknown as ManualProduct} />
                                    <Grid className={classes.marginBottom} item xs={12} />
                                </Grid>
                            );
                        }
                        return (
                            <Grid key={`train-${item.train.id}`} container>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <TrainCartCard
                                            type="normal"
                                            train={item.train}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }
    return null;

}