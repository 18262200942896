import moment, { Moment } from 'moment';

export function generateMonthlyDates(n: number, startDate: Moment): Date[][] {
    if (n < 1) throw new Error("Le nombre de mois doit être supérieur ou égal à 1.");

    const result: Date[][] = [];

    for (let i = 0; i < n; i++) {
        // Calculer le premier et le dernier jour du mois courant
        const currentMonthStart = moment(startDate).add(i, 'months').startOf('month');
        const currentMonthEnd = moment(currentMonthStart).endOf('month');

        // Générer toutes les dates pour ce mois
        const datesInMonth: Date[] = [];
        let currentDate = moment(currentMonthStart);
        while (currentDate.isSameOrBefore(currentMonthEnd, 'day')) {
            datesInMonth.push(currentDate.toDate());
            currentDate.add(1, 'day');
        }
        
        result.push(datesInMonth);
    }

    return result;
}