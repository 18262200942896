import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ItineraryStepItemAccommodationTooltip } from "../itineraryStepItemAccommodationTooltip";
import { ItineraryStepItemCarTooltip } from "../itineraryStepItemCarTooltip";
import { ItineraryStepItemPoiTooltip } from "../itineraryStepItemPoiTooltip";
import { ItineraryStepItemTransferTooltip } from "../itineraryStepItemTransferTooltip";
import { ManualProductTransferCart } from "../objects/manualProductTransferCart";
import { ManualProductCarCart } from "../objects/manualProductCarCart";
import { ManualProductAccommodationCart } from "../objects/manualProductAccommodationCart";
import { ManualProductPoiCart } from "../objects/manualProductPoiCart";
import { AppState } from "../../../Reducers/Reducers";
import { ManualProductFlightCart } from "../objects/manualProductFlightCart";
import { ManualProductTrainCart } from "../objects/manualProductTrainCart";
import { ManualProductCruiseCart } from "../objects/manualProductCruiseCart";
import { ManualProductFerryCart } from "../objects/manualProductFerryCart";
import { ManualProductAssistanceCart } from '../objects/manualProductAssistanceCart';
import { ManualProductInsuranceCart } from '../objects/manualProductInsuranceCart';
import { Flight } from "./flight";
import { AssistanceCart } from "../objects/assistanceCart";
import { TrainCartFromBackend } from "../../train/objects/backendData/cart/trainCartFromBackend";

type Options = {
    forRoadbook?: boolean
}

type Products = {
    accommodations: Parameters<typeof ItineraryStepItemAccommodationTooltip>[0]['accommodations'],
    cars: Parameters<typeof ItineraryStepItemCarTooltip>[0]['cars'],
    pois: Parameters<typeof ItineraryStepItemPoiTooltip>[0]['pois'],
    flights: ({
        type: 'normal',
        flight: Flight
    } | {
        type: 'manual',
        flight: ManualProductFlightCart
    })[],
    trains: ({
        type: 'normal',
        train: TrainCartFromBackend
    } | {
        type: 'manual',
        train: ManualProductTrainCart
    })[],
    cruises: {
        type: 'manual',
        cruise: ManualProductCruiseCart
    }[],
    ferries: {
        type: 'manual',
        ferry: ManualProductFerryCart
    }[],
    assistances: ({
        type: 'normal',
        assistance: AssistanceCart
    } | {
        type: 'manual',
        assistance: ManualProductAssistanceCart
    })[],
    insurances: {
        type: 'manual',
        insurance: ManualProductInsuranceCart
    }[],
    transfers: Parameters<typeof ItineraryStepItemTransferTooltip>[0]['transfers'],
}

export function useCartProducts(options?: Options): Products {
    const carsCart = useSelector((state: AppState) => {
        return state.cars_search.cart;
    });
    const accommodationsCart = useSelector((state: AppState) => {
        return state.accommodation.sort_cart;
    });
    const poisCart = useSelector((state: AppState) => {
        return state.poi.cart;
    });
    const flightsCart = useSelector((state: AppState) => {
        return state.flight.cart;
    });
    const trainsCart = useSelector((state: AppState) => {
        return state.train.cart;
    });
    const transfersCart = useSelector((state: AppState) => {
        return state.transfers.cart;
    });
    const manualProducts = useSelector((state: AppState) => {
        return state.cart.manual_item_list;
    });
    const assistancesCart = useSelector((state: AppState) => state.cart.assistance_cart);

    let cars: Products['cars'] = carsCart?.map((item) => ({
        type: 'normal',
        car: item
    })) ?? [];
    cars = cars.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 2;
            }).map((item) => ({
                type: 'manual',
                car: { ...item, is_manual: true } as ManualProductCarCart
            })) ?? []
        )
    );
    let accommodations: Products['accommodations'] = accommodationsCart?.map((item) => ({
        type: 'normal',
        accommodation: item
    })) ?? [];
    accommodations = accommodations.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 0;
            }).map((item) => ({
                type: 'manual',
                accommodation: { ...item, is_manual: true } as ManualProductAccommodationCart
            })) ?? []
        )
    );
    let pois: Products['pois'] = poisCart?.map((item) => ({
        type: 'normal',
        poi: item
    })) ?? [];
    pois = pois.concat(
        (
            manualProducts?.filter((item) => {
                return [1, 12].includes(item.product_type);
            }).map((item) => ({
                type: 'manual',
                poi: { ...item, is_manual: true } as ManualProductPoiCart
            })) ?? []
        )
    );
    let flights: Products['flights'] = flightsCart?.map((item) => ({
        type: 'normal',
        flight: item
    })) ?? [];
    flights = flights.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 6;
            }).map((item) => ({
                type: 'manual',
                flight: { ...item, is_manual: true } as unknown as ManualProductFlightCart
            })) ?? []
        )
    );
    let trains: Products['trains'] = trainsCart?.map((item) => ({
        type: 'normal',
        train: item
    })) ?? [];
    trains = trains.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 5;
            }).map((item) => ({
                type: 'manual',
                train: { ...item, is_manual: true } as unknown as ManualProductTrainCart
            })) ?? []
        )
    );
    let transfers: Products['transfers'] = transfersCart?.map((item) => ({
        type: 'normal',
        transfer: item
    })) ?? [];
    transfers = transfers.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 4;
            }).map((item) => ({
                type: 'manual',
                transfer: { ...item, is_manual: true } as unknown as ManualProductTransferCart
            })) ?? []
        )
    );
    let assistances: Products['assistances'] = assistancesCart?.map((item) => ({
        type: 'normal',
        assistance: item
    })) ?? [];
    assistances = assistances.concat(
        (
            manualProducts?.filter((item) => {
                return item.product_type === 19 ||
                       (
                           item.product_type === 11 &&
                           options?.forRoadbook &&
                           item.auto_product
                       );
            }).map((item) => ({
                type: 'manual',
                assistance: { ...item, is_manual: true } as unknown as ManualProductAssistanceCart
            })) ?? []
        )
    );
    const cruises: Products['cruises'] = manualProducts?.filter((item) => {
        return item.product_type === 9;
    }).map((item) => ({
        type: 'manual',
        cruise: { ...item, is_manual: true } as unknown as ManualProductCruiseCart
    })) ?? [];
    const ferries: Products['ferries'] = manualProducts?.filter((item) => {
        return item.product_type === 13;
    }).map((item) => ({
        type: 'manual',
        ferry: { ...item, is_manual: true } as unknown as ManualProductFerryCart
    })) ?? [];
    const insurances: Products['insurances'] = manualProducts?.filter((item) => {
        return item.product_type === 8;
    }).map((item) => ({
        type: 'manual',
        insurance: { ...item, is_manual: true } as unknown as ManualProductInsuranceCart
    })) ?? [];
    return useMemo(() => {
        return {
            accommodations,
            cars,
            pois,
            transfers,
            flights,
            trains,
            cruises,
            ferries,
            assistances,
            insurances
        };
    }, [
        options?.forRoadbook,
        carsCart,
        accommodationsCart,
        poisCart,
        transfersCart,
        flightsCart,
        trainsCart,
        assistancesCart,
        manualProducts
    ]);
}
