import moment from "moment";
import { TrainLegCriteria, TrainListCriteria, TrainListFiltersFrontCriteria, TrainListFiltersCriteria } from "../objects/trainList";

export const EMPTY_TRAIN_LEGS_CRITERIA: TrainLegCriteria = {
    origin: undefined,
    destination: undefined,
    departure: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    multiProviderEnabled: undefined
}


export const EMPTY_TRAIN_LIST_FILTERS_CRITERIA: TrainListFiltersCriteria = {
    legs: [EMPTY_TRAIN_LEGS_CRITERIA],
    travelers: []
}

export const EMPTY_TRAIN_LIST_FILTERS_FRONT_CRITERIA: TrainListFiltersFrontCriteria = {
    origin: null,
    destination: null,
    departure: new Date(),
    can_return: false,
    return: null,
    multiProviderEnabled: undefined,
    departure_hour: "12h",
    return_hour: "12h",
    travelers: [],
    providers: []
}

export const EMPTY_TRAIN_LIST_CRITERIA: TrainListCriteria = {
    pagination: {
        limit: 0,
        page: 0
    },
    ordering: {
        order: "asc",
        order_by: ""
    },
    filters: EMPTY_TRAIN_LIST_FILTERS_FRONT_CRITERIA
}