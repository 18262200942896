import { useContext } from "react";
import { useSelector } from "react-redux";
import { useCartSummarizedProducts } from "./cartSummarizedProducts";
import { ProviderContext } from "./providerContext";
import {
    makeAccommodationEditRequest,
    makeCarEditRequest,
    makeCustomProductEditRequest,
    makeFlightEditRequest,
    makeManualProductEditRequest,
    makeTrainEditRequest,
    makeTransferEditRequest
} from "./editProductRequests";
import { AppState } from "../../../Reducers/Reducers";

type Product = Pick<
    ReturnType<typeof useCartSummarizedProducts>[number],
    'type' |
    'id' |
    'agent_accepted' |
    'agent_refused' |
    'agent_comment' |
    'provider_accepted' |
    'provider_refused' |
    'isCustom' |
    'provider_want_modification' |
    'stackNumber' |
    'providerId'
>

type Callback = (tripVersion: number, product: Product) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (
        item: {
            type: Product['type'],
            data: NonNullable<Awaited<ReturnType<typeof makeRequest>>>
        }
    ) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useUpdateProductProviderQuotationStatus(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const providerContext = useContext(ProviderContext);

    return async (version, item) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const response = await makeRequest({
                    tripId,
                    version,
                    providerToken: providerContext.module ?
                        providerContext.providerToken :
                        undefined,
                    product: {
                        ...item,
                        agent_comment: (item.agent_comment?.length ?? 0) > 0 ?
                            item.agent_comment :
                            null
                    }
                });
                if (options.onSuccess && response) {
                    options.onSuccess({
                        type: item.type,
                        data: response
                    });
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    product: Product,
    providerToken?: string | null
}

async function makeRequest(options: RequestOptions) {
    if (
        (
            options.product.type === 'transfers' ||
            options.product.type === 'pois'
        ) &&
        options.product.isCustom
    ) {
        return await makeCustomProductEditRequest({
            tripId: options.tripId,
            version: options.version,
            id: options.product.id,
            providerToken: options.providerToken,
            data: {
                agent_accepted: options.product.agent_accepted,
                agent_refused: options.product.agent_refused,
                agent_comment: options.product.agent_comment,
                provider_accepted: options.product.provider_accepted,
                provider_refused: options.product.provider_refused,
                provider_want_modification: options.product.provider_want_modification
            }
        });
    }

    switch (options.product.type) {
        case 'flights': {
            return await makeFlightEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
        case 'trains': {
            return await makeTrainEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused
                }
            });
        }
        case 'cars': {
            return await makeCarEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
        case 'accommodations': {
            return await makeAccommodationEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
        case 'transfers': {
            return await makeTransferEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
        case 'pois': {
            return await makeCustomProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
        default: {
            return await makeManualProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    agent_accepted: options.product.agent_accepted,
                    agent_refused: options.product.agent_refused,
                    agent_comment: options.product.agent_comment,
                    provider_accepted: options.product.provider_accepted,
                    provider_refused: options.product.provider_refused,
                    provider_want_modification: options.product.provider_want_modification
                }
            });
        }
    }
}
