export const cleanTrailingEmptyTags = (html: string): string => {
    // Transformer la chaîne HTML en un élément DOM
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const body = doc.body;
    if (!body) return html; // Retourner tel quel si le body est null
  
    // Fonction récursive pour supprimer les balises vides en fin de texte
    const removeEmptyTrailingNodes = (parent: Node) => {
      let childNodes = Array.from(parent.childNodes).reverse();
  
      for (let node of childNodes) {
        // Vérifier si c'est un nœud texte non vide
        if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
          break; // Arrêter la suppression dès qu'on trouve du texte
        }
  
        // Vérifier si c'est une balise HTML
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          removeEmptyTrailingNodes(element); // Nettoyer récursivement
  
          // Supprimer la balise si elle est vide ou ne contient que des espaces insécables
          if (!element.textContent?.replace(/\u00A0/g, "").trim()) {
            parent.removeChild(element);
          }
        } else if (node.nodeType === Node.TEXT_NODE && !node.textContent?.trim()) {
          parent.removeChild(node); // Supprimer les espaces vides
        }
      }
    };
  
    removeEmptyTrailingNodes(body);
  
    return body.innerHTML.trim();
  };