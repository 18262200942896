import React, { FC, useEffect, useMemo, useState } from "react";
import { TripTrainLeg, TripTrainLegFareOffer, TripTrainLegOffer } from "./objects/trainCart";
import { useTranslation } from "react-i18next";
import { translateText } from "./services/translation";
import { getExchangeRefundableStatus } from "./utils/cancelationFunction/getCancelationStatus";
import { Stack, Typography, Divider } from "@mui/material";
import { TravelerGroup } from "./objects/backendData/cart/trainTravelerGroup";
import { UserIcon } from "./assets/trainIcon";
import { travelerColor } from "./style/colors";
import { getAge } from "./utils/dates/getAge";
import { cleanTrailingEmptyTags } from "./utils/htmlText/clearTrailingEmptyTags";


interface TrainCartCardDetailFareProps {
    trip_leg: TripTrainLeg;
    offer: TripTrainLegOffer;
    travelers_group: TravelerGroup;
    fare: TripTrainLegFareOffer;
    fare_index: number;
    fares_lenght: number;
}


export const TrainCartCardDetailFare: FC<TrainCartCardDetailFareProps> = ({ trip_leg, travelers_group, offer, fare, fare_index, fares_lenght }) => {
    const { t, i18n } = useTranslation();
    const conserned_segments = useMemo(() => trip_leg.segments, [trip_leg.segments]);
    const considered_travelers = useMemo(() => travelers_group.travelers_list, [travelers_group]);
    const exchage_status = getExchangeRefundableStatus(fare.exchangeable, fare.refundable);
    // {fare.cabin_class.label},

    const [translatedCondition, setTranslatedCondition] = useState('');
    const [translatedName, setTranslatedName] = useState('');

    const translateCondition = async () => {
        try {
            const response = await translateText(fare.conditions, 'en', i18n.language);
            const translatedText = response.data.data.translations[0].translatedText;
            setTranslatedCondition(cleanTrailingEmptyTags(translatedText));
        } catch (error) {
            console.error('Error translating condition:', error);
        }
    };
    const translateName = async () => {
        try {
            const response = await translateText(fare.fare_name, 'en', i18n.language);
            const translatedText = response.data.data.translations[0].translatedText;
            setTranslatedName(translatedText);
        } catch (error) {
            console.error('Error translating condition:', error);
        }
    };

    // Use useEffect to watch for changes
    useEffect(() => {
        if (fare.conditions) {
            translateCondition();
        }
    }, [fare.conditions, i18n.language]);


    useEffect(() => {
        if (fare.fare_name) {
            translateName();
        }
    }, [fare.fare_name, i18n.language]);

    return <Stack direction={'column'} spacing={1}>
        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>{translatedName}</Typography>
        <Typography variant='subtitle1' sx={{ fontSize: '1rem' }}>{t(`train.comfort.${offer.comfort_category_code}`)}, {t(`train.flexibility.${offer.flexibility_code}`)}, {t(`train.cancelation.${exchage_status}`)}</Typography>
        <Stack direction={'row'} flexWrap={'wrap'}>
            {

                conserned_segments?.map((segment, segment_index) =>
                    <Stack key={`fare-segment-${fare.id}-${segment.id}`}>
                        <Typography variant='body1' sx={{ fontSize: '1rem', fontWeight: 500 }}>{segment.vehicle_type} n° {segment.vehicle_reference}{segment_index + 1 < conserned_segments.length ? <>{','}&nbsp;</> : ''}</Typography>
                    </Stack>
                )
            }
        </Stack>
        <Stack>
            <div style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }} dangerouslySetInnerHTML={{ __html: translatedCondition }} />
        </Stack>
        <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
            {considered_travelers?.map((traveler, traveler_id) => <>
                <Stack direction={'row'} spacing={1} key={`fare-segment-traveler-${fare.id}-${traveler.id}`}>
                    <Stack justifyContent={'center'} alignItems={'center'} sx={{ borderRadius: '50%', backgroundColor: travelerColor[traveler_id % 3 as 0 | 1 | 2], width: '2.5rem', height: '2.5rem' }}><UserIcon /></Stack>
                    <Stack direction={'column'}>
                        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>{t('shared.year', { count: getAge(new Date(traveler.birth_date)) })}</Typography>
                        <Typography variant='subtitle1' sx={{ fontSize: '1rem' }}>{t(`train.comfort.${offer.comfort_category_code}`)}</Typography>
                    </Stack>
                </Stack>
            </>)}
        </Stack>
        {fare_index + 1 < fares_lenght && <Stack >
            <Divider sx={{ marginBottom: '1rem' }} />
        </Stack>}
    </Stack>;
}
