//Dependencies
import axios from 'axios';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
//Core
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button, Grid, Stack } from '@mui/material';
import clsx from 'clsx';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { TrainCartFromBackend } from './objects/backendData/cart/trainCartFromBackend';
import { editTrainCartById } from './redux/reducer';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    hide: {
        display: 'none'
    }
});

const TrainCartManualBooking: FC<{train: TrainCartFromBackend, onManualBooking: () => void}> = ({ train, onManualBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(train.booking_status !== null ? train.booking_status.status_booking : 'DEFAULT');
    const [reference, setReference] = useState(train.booking_status !== null && train.booking_status.item_reference !== null ? train.booking_status.item_reference : '');
    const activateAllStatuses = useMemo(() => {
        return train.booking_manually_set ||
            train.is_custom ||
            train.booking_status?.status_booking !== "CONFIRMED";
    }, [train]);
    const onStatusChange = (event: any) => {
        setStatus(event.target.value);
    };
    const onReferenceChange = (event: any) => {
        setReference(event.target.value);
    };
    const onValidateManualBooking = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${train.id}/status/`,
            data: {
                status_booking: status === 'DEFAULT' ? null : status,
                item_reference: reference
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
            onManualBooking();
        }).catch(error => {
            console.log(error);
            onManualBooking();
        });
    };
    const onCancelManualBooking = () => {
        onManualBooking();
    };
    return (
        <Grid container columnSpacing={4}>
            <Grid item xs={6}>
                <FormControl fullWidth size="small" variant={'outlined'}>
                    <InputLabel htmlFor={'status'}>{t('cart-material.status')}</InputLabel>
                    <Select id={'status'} value={status} label={t('cart-material.status')} onChange={onStatusChange}>
                        <MenuItem
                            value={'DEFAULT'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            train.booking_manually_set &&
                                            !train.is_manual &&
                                            !train.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.none')}
                        </MenuItem>
                        <MenuItem value={'CONFIRMED'} className={clsx({ [classes.hide]: !activateAllStatuses })}>{t('cart-material.confirmed')}</MenuItem>
                        <MenuItem
                            value={'WAITING'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            train.booking_manually_set &&
                                            !train.is_manual &&
                                            !train.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.pending')}
                        </MenuItem>
                        <MenuItem
                            value={'ERROR'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            train.booking_manually_set &&
                                            !train.is_manual &&
                                            !train.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.error')}
                        </MenuItem>
                        <MenuItem
                            value={'REFUSED'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            train.booking_manually_set &&
                                            !train.is_manual &&
                                            !train.is_custom
                                        )
                                })
                            }
                        >
                            {t("cart-material.refused")}
                        </MenuItem>
                        <MenuItem value="CANCELLED">{t('cart-material.cancelled')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth size="small" variant={'outlined'}>
                    <InputLabel htmlFor={'note'}>{t('cart-material.reference')}</InputLabel>
                    <OutlinedInput
                        id={'note'}
                        type={'text'}
                        onChange={onReferenceChange}
                        label={t('cart-material.reference')}
                        value={reference}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ marginTop: 2 }}>
                    <Button onClick={onCancelManualBooking}>{t('global.cancel')}</Button>
                    <Button onClick={onValidateManualBooking}>{t('global.validate')}</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default TrainCartManualBooking;
