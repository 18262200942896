import { Button, Dialog, DialogActions, DialogContent, DialogTitle, paperClasses, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { TrainListFiltersFrontCriteria } from "./objects/trainList";
import { updateTrainListFiltersFrontCriteria } from "./redux/reducer";
import { AppState } from "../../Reducers/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { searchNextDate } from "./utils/dates/searchNextDate";

import {  makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import moment, { Moment } from "moment";
import { enqueueSnackbar } from "notistack";
import Calendar from "./components/calendar/customCalendarPicker";
import { TrainSearchDatesRangePickerHeader } from "./trainSearchDatesRangePickerHeader";

interface DateRangeFilters {departure: Date, departure_hour: string, return: Date | null, return_hour: string}

export type DateRangeFiltersKeyErrors = `${keyof DateRangeFilters}_error`;
export type DateRangeFiltersErrors = {
    [K in DateRangeFiltersKeyErrors]?: string
}
type DateRangeFiltersForm = DateRangeFilters & DateRangeFiltersErrors;


const useStyles = makeStyles((theme) => ({
    responsiveDialogContent: {
        height: '311px !important',
        [theme.breakpoints.down('sm')]: {
          height: 'auto !important',
        },
    }
  }));

 const dialog_sx ={
    [`.${paperClasses.root}`]: {
        borderRadius: '1.5rem !important', // Set your desired border radius
    },
  };
export const TrainSearchDatesRangePicker: FC<{is_open: boolean, onClose: () => void; return_required?: boolean}> = ({is_open, onClose, return_required}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme(); 
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);

    const [filters, setFilters] = useState<DateRangeFiltersForm>({departure: train_list_filters_criteria.departure, departure_hour: train_list_filters_criteria.departure_hour, return: train_list_filters_criteria.return, return_hour: train_list_filters_criteria.return_hour});

    const [can_return, setCanReturn] = useState<boolean>(train_list_filters_criteria.can_return);
    

    const controlForm = () => {
        const errors: Record<string, string> = {};
        if(!filters.departure) {
            errors.departure_error = t('shared.date-required');
        } else if(moment.isMoment(filters.departure) && !filters.departure.isValid()) {
            errors.departure_error = t('shared.invalid-date');
        } else if(moment.isMoment(filters.departure) && filters.departure.isValid() && filters.departure.isBefore(moment.utc(), 'day')) {
            errors.departure_error = t('shared.invalid-date');
        }
        if(return_required && !filters.return) {
            errors.return_error = t('shared.date-required');
        }
        if(filters.return && moment.isMoment(filters.return) && !filters.return.isValid()) {
            errors.return_error = t('shared.invalid-date');
        }
        if(moment.isMoment(filters.return) && filters.return.isValid() && moment.isMoment(filters.return) && filters.return.isValid() && filters.return.isBefore(filters.departure)) {
            errors.return_error = t('shared.end-date-should-sup');
        }
        Object.entries(errors).forEach(([key, value]) => {
            setFilters((state) => ({ ...state, [key]: value }));
            enqueueSnackbar(value, { variant: 'error' });
        });
        
        if (Object.keys(errors).length > 0)  return false; else return true;
    }
    
    const onValidate = () => {
        const can_validate = controlForm();
        if(!can_validate) {
            return;
        }
        dispatch(updateTrainListFiltersFrontCriteria({
            ...train_list_filters_criteria, 
            departure: filters.departure,
            departure_hour: filters.departure_hour,
            return: filters.return,
            return_hour: filters.return_hour,
            can_return
        }));
        onClose();
    };

    const onFieldChange = (key: keyof TrainListFiltersFrontCriteria, value: any) => {
        if(key === 'return' && filters.departure) {
            if(value?.isSame(moment.utc(filters.departure), 'day')) {
                const {return_hour} = searchNextDate(filters.departure, filters.departure_hour);
                setFilters((state) => ({
                    ...state, 
                    [key]: value,
                    return_hour,
                    return_error: undefined
                }));
            } else {
                setFilters((state) => ({ ...state, [key]: value, [`${key}_error`]: undefined }));
            }
        } else {
            setFilters((state) => ({ ...state, [key]: value, [`${key}_error`]: undefined }));
        }
    };
    const handleDateClick = (date: Moment, number_of_click: number) => {
        if(return_required) {
            onFieldChange('return', date);
        } else {
            if (!number_of_click) {
                onFieldChange('departure', date);
                onFieldChange('return', null);
            } else {
                onFieldChange('return', date);
            }
        }
    };
    const initDatePicker = () => {
        setFilters({departure: train_list_filters_criteria.departure, departure_hour: train_list_filters_criteria.departure_hour, return: train_list_filters_criteria.return, return_hour: train_list_filters_criteria.return_hour})
    }

    useEffect(() => {
        initDatePicker();
    }, [train_list_filters_criteria, is_open]);
    useEffect(() => {
        setCanReturn(train_list_filters_criteria.can_return);
    }, [is_open]);
    useEffect(() => {
        if(moment.isMoment(filters.return) && filters.return.isValid() && (filters.return.isAfter(filters.departure) || filters.return.isSame(filters.departure, 'day'))) {
            setCanReturn(true);
        } else {
            if(filters.return === null) {
                setCanReturn(false);
            }
        }
    }, [filters.return]);

    return <Dialog maxWidth={!isMobile? "md" : undefined} fullScreen={isMobile} fullWidth sx={!isMobile? dialog_sx: undefined} open={is_open} onClose={onClose}>
        {isMobile && <DialogTitle sx={{backgroundColor: 'black'}} >
            <Stack justifyContent={'space-between'} direction='row'>
                <Stack><Typography variant="subtitle1" fontSize={20} color="white">{t("train.date-times")}</Typography></Stack>
                <Button onClick={onClose}>{t("shared.close")}</Button>
            </Stack>
        </DialogTitle >}
        <DialogTitle sx={{backgroundColor: '#F3F3F8'}} >
            <TrainSearchDatesRangePickerHeader setFilters={setFilters} filters={filters} can_return={can_return} return_required={return_required}/>
        </DialogTitle> 
        <DialogContent dividers>
            <Stack direction={'column'} justifyContent={'center'} className={classes.responsiveDialogContent} gap={2}>
            <Calendar 
                numberOfMonths={!isMobile ? 2: 12} 
                startDate={filters.departure}
                minDateCanBeSelected={return_required? filters.departure: undefined}
                endDate={filters.return}
                clickOnDate={handleDateClick}
                // handleOneClick={return_required}
                disabled={!!return_required}
                />
            </Stack>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', gap: 2, backgroundColor: isMobile? '#F3F3F8': undefined }}>
            <Button variant="contained" className="train-date-validate-button" onClick={onValidate}>{t("shared.validate")}</Button>
        </DialogActions>
    </Dialog>
};