import { Box, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import { ItineraryResume } from "./components/itinerary/itineraryResume";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { updateTrainListFiltersFrontCriteria } from "./redux/reducer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { itineraryToItineraryInput } from "../Itinerary/utils/itineraryToItineraryInput";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { searchTrainPlace } from "./services/trainServices";
import { useShowError } from "../Utils/showError";
import { getCityFromPlaceId } from "./services/googleServices";
import GoogleMapsLoader from "google-maps";

export const TrainSearchItinararyResume = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const showError = useShowError();
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const sorted_itinerary = itinerary.sort(sortItinerary);
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const [step, setStep] = useState<number>(0);

    const stepperRef = useRef(null);

    const onChooseDate = async (date: string, step?: number, date_type?: 'start_date' | 'end_date') => {
        try {
            let origin_place: string = '';
            let destination_place: string = '';

            if (date_type === 'start_date') {
                if (step === 0) {
                    origin_place = await getCityFromPlaceId(sorted_itinerary[Number(step)]!.places_id);
                } else {
                    origin_place = sorted_itinerary[Number(step)]!.destination?.data?.international_name.split(',')[0] ?? '';
                }
                destination_place = sorted_itinerary[Number(step) + 1]!.destination?.data?.international_name.split(',')[0] ?? '';
            } else {
                if (Number(step) + 3 === sorted_itinerary.length) {
                    destination_place = await getCityFromPlaceId(sorted_itinerary[Number(step)+ 2]!.places_id);
                } else {
                    destination_place = sorted_itinerary[Number(step) + 2]!.destination?.data?.international_name.split(',')[0] ?? '';
                }
                origin_place = sorted_itinerary[Number(step) + 1]!.destination?.data?.international_name.split(',')[0] ?? '';
            }

            const origin_reponse = origin_place? await searchTrainPlace(origin_place): null;
            const destination_reponse = destination_place? await searchTrainPlace(destination_place): null;

            const origin = origin_reponse?.data.find((place: any) => place.type === 'city') ?? origin_reponse?.data[0] ?? null;
            const destination = destination_reponse?.data.find((place: any) => place.type === 'city') ?? destination_reponse?.data[0] ?? null;

            dispatch(updateTrainListFiltersFrontCriteria({
                ...train_list_filters_criteria,
                departure: moment.utc(date).toDate(),
                origin,
                destination,
            }));
        } catch (error) {
            showError(error as Error)
        }
    };


    return (
        <Grid container item justifyContent={'center'} sx={{ width: '100%' }}>
            {
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'sticky',
                                top: 70/*  + stickyHeaderOffset */,
                                backgroundColor: '#fff',
                                zIndex: 2,
                                maxWidth: '100%',
                                width: '100%'
                            }}
                        >
                            <ItineraryResume
                                ref={stepperRef}
                                orientation="horizontal"
                                step={step}
                                setStep={(step) => setStep(step)}
                                alternativeLabel
                                showStatus
                                disableDatesEdit={true}
                                dateToHighlight={train_list_filters_criteria.departure.toISOString()}
                                onChooseDate={onChooseDate}
                                shouldShowTransfers
                            />
                        </Box>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}