import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../Reducers/Reducers";
import { useEffect } from "react";
import { sortItinerary } from "../../../../Itinerary/utils/sortItinerary";
import { useShowError } from "../../../../Utils/showError";
import { getCityFromPlaceId } from "../../googleServices";
import { getProviders, searchTrainPlace } from "../../trainServices";
import { setTrainProviders, updateTrainListFiltersFrontCriteria } from "../../../redux/reducer";
import moment from "moment";
import { TrainTravelersFrontCriteria } from "../../../objects/trainList";

let timeout: NodeJS.Timeout;
export const useGetDefaultFilter = () => {
    const showError = useShowError();
    const dispatch = useDispatch();
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const traveler_available = useSelector((store: AppState) => store.trip.travelers);
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const train_providers = useSelector((state: AppState) => state.train.train_providers);
    const sorted_itinerary = itinerary.sort(sortItinerary);

    const step = 0;

    const getDefaultTrainFilters = async () => {
        try {
            let providers = train_providers || [];
            if(!train_providers) {
                const provider_response = await getProviders();
                providers = provider_response.data;
                dispatch(setTrainProviders(providers));
            }
            let origin_place: string = '';
            let destination_place: string = '';
            if (step === 0) {
                origin_place = await getCityFromPlaceId(sorted_itinerary[Number(step)]!.places_id);
            } else {
                origin_place = sorted_itinerary[Number(step)]!.destination?.data?.international_name.split(',')[0] ?? '';
            }
            
            if (step + 2 === sorted_itinerary.length) {
                destination_place = await getCityFromPlaceId(sorted_itinerary[step + 1]!.places_id);
            } else {
                destination_place = sorted_itinerary[step + 1]!.destination?.data?.international_name.split(',')[0] ?? '';
            }

            const origin_reponse = origin_place ? await searchTrainPlace(origin_place) : null;
            const destination_reponse = destination_place ? await searchTrainPlace(destination_place) : null;

            const origin = origin_reponse?.data.find((place: any) => place.type === 'city') ?? origin_reponse?.data[0] ?? null;
            const destination = destination_reponse?.data.find((place: any) => place.type === 'city') ?? destination_reponse?.data[0] ?? null;

            dispatch(updateTrainListFiltersFrontCriteria({
                ...train_list_filters_criteria,
                departure: moment.utc(sorted_itinerary[step + 1]!.start_date).toDate(),
                origin,
                destination,
                travelers: (traveler_available ?? []) as unknown as TrainTravelersFrontCriteria[],
                providers
            }));
        } catch (error) {
            showError(error as Error)
        }
    };

    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(getDefaultTrainFilters, 500);
    }, [itinerary, traveler_available, train_providers]);
}