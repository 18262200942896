import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMPTY_TRAIN_LIST_CRITERIA } from "../constants/trainConstants";
import { TrainState } from "../objects/trainState";
import { TrainProduct, TrainService, TrainTrip } from "../objects/train";
import { TrainListCriteria, TrainListFiltersFrontCriteria, TrainTravelersFrontCriteria } from "../objects/trainList";
import { searchNextDate } from "../utils/dates/searchNextDate";
import { TrainForm } from "../objects/trainForm";
import { Currency } from "../../../Reducers/objects/currency";
import { TrainBackendTraveler } from "../objects/backendData/trainBackendDate";
import { TrainProvider } from "../objects/backendData/trainProvider";
import { TrainCartFromBackend } from "../objects/backendData/cart/trainCartFromBackend";

const initialState: TrainState = {
    train_trip_list_outbound: [],
    train_trip_list_return: [],
    train_travelers: [],
    train_departure_selected: null,
    train_departure_form: null,
    train_return_selected: null,
    train_return_form: null,
    services_available: {},
    products_available: {},
    train_list_refresh: false,
    train_list_loading: false,
    train_list_load_more_loading: false,
    train_list_loaded: false,
    train_list_visible_item: 5,
    train_list_criteria: EMPTY_TRAIN_LIST_CRITERIA,
    train: null,
    currencies: {},
    train_providers: undefined,

    cart: [],
    refresh: null
};



//  ------------------------------ search ----------------------------------- //
const toggleTrainRefreshListReducer = (state: TrainState): TrainState => ({
    ...state, train_list_refresh: !state.train_list_refresh, train_list_loaded: false
});
const updateTrainListOutboundReducer = (state: TrainState, action: PayloadAction<TrainTrip[]>): TrainState => ({
    ...state, train_trip_list_outbound: action.payload, train_list_loaded: true
});
const updateTrainListReturnReducer = (state: TrainState, action: PayloadAction<TrainTrip[]>): TrainState => ({
    ...state, train_trip_list_return: action.payload, train_list_loaded: true
});
const setTrainTravelersReducer = (state: TrainState, action: PayloadAction<TrainBackendTraveler[]>): TrainState => ({
    ...state, train_travelers: action.payload
});
const updateProductsAvailableReducer = (state: TrainState, action: PayloadAction<Record<string, TrainProduct>>): TrainState => ({
    ...state, products_available: action.payload
});
const updateServicesAvailableReducer = (state: TrainState, action: PayloadAction<Record<string, TrainService>>): TrainState => ({
    ...state, services_available: action.payload
});
const updateTrainListCriteriaReducer = (state: TrainState, action: PayloadAction<TrainListCriteria>): TrainState => ({
    ...state, train_list_criteria: action.payload
});
const updateTrainListFiltersFrontCriteriaReducer = (state: TrainState, action: PayloadAction<TrainListFiltersFrontCriteria>): TrainState => ({
    ...state, train_list_criteria: { ...state.train_list_criteria, filters: { ...state.train_list_criteria.filters, ...action.payload } }
});
const updateTrainListLoadingReducer = (state: TrainState, action: PayloadAction<boolean>): TrainState => ({
    ...state, train_list_loading: action.payload
});
const updateTrainListLoadMoreLoadingReducer = (state: TrainState, action: PayloadAction<boolean>): TrainState => ({
    ...state, train_list_load_more_loading: action.payload
});
const updateTrainListLoadedReducer = (state: TrainState, action: PayloadAction<boolean>): TrainState => ({
    ...state, train_list_loaded: action.payload
});
const updateTrainListVisibleItemReducer = (state: TrainState, action: PayloadAction<number>): TrainState => ({
    ...state, train_list_visible_item: action.payload
});
const setTrainReducer = (state: TrainState, action: PayloadAction<TrainTrip | null>): TrainState => ({
    ...state, train: action.payload
});


const updateTrainListFiltersSwapDestinationReducer = (state: TrainState): TrainState => ({
    ...state, train_list_criteria: { ...state.train_list_criteria, filters: { ...state.train_list_criteria.filters, origin: state.train_list_criteria.filters.destination, destination: state.train_list_criteria.filters.origin } }
});
const deleteTrainListFlitersFrontReturnReducer = (state: TrainState): TrainState => ({
    ...state, train_list_criteria: { ...state.train_list_criteria, filters: { ...state.train_list_criteria.filters, can_return: false, return: null } }
});
const addTrainListFlitersFrontReturnReducer = (state: TrainState): TrainState => {
    const { return_hour, return_date } = searchNextDate(state.train_list_criteria.filters.departure, state.train_list_criteria.filters.departure_hour);
    return ({
        ...state, train_list_criteria: {
            ...state.train_list_criteria, filters: {
                ...state.train_list_criteria.filters,
                can_return: true,
                return: return_date,
                return_hour
            }
        }
    });
};
const updateTrainListFiltersFrontTravelersReducer = (state: TrainState, action: PayloadAction<TrainTravelersFrontCriteria[]>): TrainState => ({
    ...state, train_list_criteria: { ...state.train_list_criteria, filters: { ...state.train_list_criteria.filters, travelers: action.payload } }
});
const updateTrainDepartureFormReducer = (state: TrainState, action: PayloadAction<Omit<TrainForm, 'travelers'> | null>): TrainState => ({
    ...state, train_departure_form: action.payload ? { ...action.payload, travelers: state.train_travelers } : null
});
const updateTrainReturnFormReducer = (state: TrainState, action: PayloadAction<Omit<TrainForm, 'travelers'> | null>): TrainState => ({
    ...state, train_return_form: action.payload ? { ...action.payload, travelers: state.train_travelers } : null
});

// ----------- currencies ------------ //
const setCurrenciesReducer = (state: TrainState, action: PayloadAction<Currency[]>): TrainState => ({
    ...state, currencies: action.payload.reduce((acc, currency) => ({ ...acc, [currency.id]: currency }), {})
});


// ------------- providers -------------- //
const setTrainProvidersReducer = (state: TrainState, action: PayloadAction<TrainProvider[]>): TrainState => ({
    ...state, train_providers: action.payload
});

// -------------- pre cart ------------ //
const updateTrainDepartureSelectedReducer = (state: TrainState, action: PayloadAction<Omit<TrainForm, 'travelers'> | null>): TrainState => ({
    ...state, train_departure_selected: action.payload ? { ...action.payload, travelers: state.train_travelers } : null
});

const updateTrainReturnSelectedReducer = (state: TrainState, action: PayloadAction<Omit<TrainForm, 'travelers'> | null>): TrainState => ({
    ...state, train_return_selected: action.payload ? { ...action.payload, travelers: state.train_travelers } : null
});


// ----------- cart ------------ //
const setTrainCartRefreshPriceReducer = (state: TrainState, action: PayloadAction<any>): TrainState => ({
    ...state, refresh: action.payload !== null? action.payload.cars : state.refresh
});

const addTrainCartReducer = (state: TrainState, action: PayloadAction<TrainCartFromBackend>): TrainState => ({
    ...state, cart: [...state.cart, action.payload]
});
const setTrainCartReducer = (state: TrainState, action: PayloadAction<TrainCartFromBackend[]>): TrainState => {
    if (state.refresh !== null) {
        const updatedCart = action.payload.map(car => {
            const priceChange = state.refresh[car.id];
            return priceChange !== undefined
                ? { ...car, price_change: priceChange }
                : car;
        });
        return {
            ...state,
            cart: updatedCart
        };
    }
    return ({...state, cart: action.payload});
};

const updateTrainCartReducer = (state: TrainState, action: PayloadAction<TrainCartFromBackend[]>): TrainState => ({
    ...state, cart: state.cart.map((train, index) => {
        const new_train = action.payload.find((item) => item.id === train.id);
        return new_train ?? train;
    })
});

const editTrainCartByIdReducer = (state: TrainState, action: PayloadAction<TrainCartFromBackend>): TrainState => ({
    ...state, cart: state.cart.map((train) => train.id === action.payload.id ? action.payload : train)
});
const removeTrainCartByIdReducer = (state: TrainState, action: PayloadAction<number>): TrainState => ({
    ...state, cart: state.cart.filter((train) => train.id !== action.payload)
});

export const trainSlice = createSlice({
    name: 'train',
    initialState,
    reducers: {
        toggleTrainRefreshList: toggleTrainRefreshListReducer,
        updateTrainListOutbound: updateTrainListOutboundReducer,
        updateTrainListReturn: updateTrainListReturnReducer,
        setTrainTravelers: setTrainTravelersReducer,
        updateServicesAvailable: updateServicesAvailableReducer,
        updateProductsAvailable: updateProductsAvailableReducer,
        updateTrainListCriteria: updateTrainListCriteriaReducer,
        updateTrainListFiltersFrontCriteria: updateTrainListFiltersFrontCriteriaReducer,
        updateTrainListFiltersSwapDestination: updateTrainListFiltersSwapDestinationReducer,
        deleteTrainListFlitersFrontReturn: deleteTrainListFlitersFrontReturnReducer,
        addTrainListFlitersFrontReturn: addTrainListFlitersFrontReturnReducer,
        updateTrainListFiltersFrontTravelers: updateTrainListFiltersFrontTravelersReducer,
        updateTrainListLoading: updateTrainListLoadingReducer,
        updateTrainListLoadMoreLoading: updateTrainListLoadMoreLoadingReducer,
        updateTrainListLoaded: updateTrainListLoadedReducer,
        updateTrainListVisibleItem: updateTrainListVisibleItemReducer,
        setTrain: setTrainReducer,
        updateTrainDepartureForm: updateTrainDepartureFormReducer,
        updateTrainReturnForm: updateTrainReturnFormReducer,
        updateTrainDepartureSelected: updateTrainDepartureSelectedReducer,
        updateTrainReturnSelected: updateTrainReturnSelectedReducer,
        setCurrencies: setCurrenciesReducer,
        setTrainProviders: setTrainProvidersReducer,

        addTrainCart: addTrainCartReducer,
        setTrainCart: setTrainCartReducer,
        updateTrainCart: updateTrainCartReducer,
        setTrainCartRefreshPrice: setTrainCartRefreshPriceReducer,
        editTrainCartById: editTrainCartByIdReducer,
        removeTrainCartById: removeTrainCartByIdReducer
    },
});

export const {
    toggleTrainRefreshList,
    updateTrainListOutbound,
    updateTrainListReturn,
    setTrainTravelers,
    updateServicesAvailable,
    updateProductsAvailable,
    updateTrainListCriteria,
    updateTrainListFiltersFrontCriteria,
    updateTrainListFiltersSwapDestination,
    deleteTrainListFlitersFrontReturn,
    addTrainListFlitersFrontReturn,
    updateTrainListFiltersFrontTravelers,
    updateTrainListLoading,
    updateTrainListLoadMoreLoading,
    updateTrainListLoaded,
    updateTrainListVisibleItem,
    setTrain,
    updateTrainDepartureForm,
    updateTrainReturnForm,
    updateTrainDepartureSelected,
    updateTrainReturnSelected,
    setCurrencies,
    setTrainProviders,

    addTrainCart,
    setTrainCart,
    updateTrainCart,
    setTrainCartRefreshPrice,
    editTrainCartById,
    removeTrainCartById
} = trainSlice.actions;
