
import AirIcon from '@mui/icons-material/AirOutlined';
import AudiotrackIcon from '@mui/icons-material/AudiotrackOutlined';
import LuggageIcon from '@mui/icons-material/LuggageOutlined';
import LocalBarIcon from '@mui/icons-material/LocalBarOutlined';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBikeOutlined';
import BikeScooterIcon from '@mui/icons-material/BikeScooterOutlined';
import LocalDiningIcon from '@mui/icons-material/LocalDiningOutlined';
import RestaurantIcon from '@mui/icons-material/RestaurantOutlined';
import FastfoodIcon from '@mui/icons-material/FastfoodOutlined';
import CoffeeIcon from '@mui/icons-material/CoffeeOutlined';
import WifiIcon from '@mui/icons-material/WifiOutlined';
import LoungeIcon from '@mui/icons-material/WeekendOutlined';
import MealIcon from '@mui/icons-material/EmojiFoodBeverageOutlined';
import NewspaperIcon from '@mui/icons-material/FeedOutlined';
import FoodOrderIcon from '@mui/icons-material/RestaurantMenuOutlined';
import LocalParkingIcon from '@mui/icons-material/LocalParkingOutlined';
import PowerIcon from '@mui/icons-material/PowerOutlined';
import BoardingIcon from '@mui/icons-material/AirplaneTicketOutlined';
import CabinIcon from '@mui/icons-material/CabinOutlined';
import QuietZoneIcon from '@mui/icons-material/NoPhotographyOutlined';
import DiningIcon from '@mui/icons-material/DiningOutlined';
import SnacksIcon from '@mui/icons-material/FastfoodOutlined';
import StewardIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TaxiIcon from '@mui/icons-material/LocalTaxiOutlined';
import TouchAppIcon from '@mui/icons-material/TouchAppOutlined';
import WcIcon from '@mui/icons-material/WcOutlined';
import TrolleyIcon from '@mui/icons-material/LuggageOutlined';
import UsbIcon from '@mui/icons-material/UsbOutlined';
import ValetIcon from '@mui/icons-material/DriveEtaOutlined';
import VendingIcon from '@mui/icons-material/LocalDrinkOutlined';
import VideoLibraryIcon from '@mui/icons-material/VideoLibraryOutlined';
import WaterIcon from '@mui/icons-material/WaterDropOutlined';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickupOutlined';
import WheelchairIcon from '@mui/icons-material/AccessibleOutlined';
import { ServiceType } from '../../objects/backendData/trainServiceType';
import { SvgIconComponent } from '@mui/icons-material';

export const ServiceTypeIcons: Record<keyof typeof ServiceType, SvgIconComponent> = {
  "AIRCON": AirIcon,
  "AUDIO": AudiotrackIcon,
  "BAGGAGE": LuggageIcon,
  "BAR": LocalBarIcon,
  "BICYCLE": DirectionsBikeIcon,
  "BICYCLE_AVAILABLE": BikeScooterIcon,
  "BUFFET": LocalDiningIcon,
  "DINING": RestaurantIcon,
  "HOT_FOOD": FastfoodIcon,
  "HOT_DRINKS": CoffeeIcon,
  "INTERNET": WifiIcon,
  "INTERNET_AVAILABLE": WifiIcon,
  "LOUNGE": LoungeIcon,
  "LOUNGE_ACCESS": LoungeIcon,
  "MEAL": MealIcon,
  "NETWORK": WifiIcon,
  "NEWSPAPER": NewspaperIcon,
  "ONLINE_FOOD": FoodOrderIcon,
  "PARKING": LocalParkingIcon,
  "POWER_SOCKET": PowerIcon,
  "PRIORITY_BOARDING": BoardingIcon,
  "PRIVATE": CabinIcon,
  "QUIET": QuietZoneIcon,
  "RESTAURANT": DiningIcon,
  "RESTAURANT_1ST": DiningIcon,
  "SNACK": SnacksIcon,
  "STEWARD": StewardIcon,
  "TAXI": TaxiIcon,
  "TOUCH_SCREEN": TouchAppIcon,
  "TOILET": WcIcon,
  "TROLLEY": TrolleyIcon,
  "USB_PLUG": UsbIcon,
  "VALET_PARKING": ValetIcon,
  "VENDING_MACHINE": VendingIcon,
  "VIDEO": VideoLibraryIcon,
  "WATER": WaterIcon,
  "WHEELCHAIR": WheelchairPickupIcon,
  "WHEELCHAIR_RES": WheelchairIcon
};
