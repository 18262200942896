import { TrainCart } from "../../objects/trainCart";
import { TrainForm } from "../../objects/trainForm";

export const createCartPayload = (train_trips: TrainForm[], group_passenger: number): TrainCart => {
    const total_price = train_trips.reduce((acc, curr) => acc += curr.selected_offer!.prices[0]!.purchase_price, 0);

    return ({
        group_passenger,
        provider_id: train_trips[0]!.provider_id,
        extra_information: train_trips[0]!.search_criteria,
        total_price: total_price,
        trip_legs: train_trips.map((train_trip, index) => {

            const price = train_trip.selected_offer!.prices[0];
            return {
                origin_code: train_trip.solution.origin.code,
                origin_label: train_trip.solution.origin.label,
                destination_code: train_trip.solution.destination.code,
                destination_label: train_trip.solution.destination.label,
                departure_datetime: train_trip.solution.departure,
                arrival_datetime: train_trip.solution.arrival,
                duration: train_trip.solution.duration,
                segment_connections: train_trip.solution.segment_connections.map((segment_connection, segment_connection_sequence_number) => ({
                    connecting_time: segment_connection.connecting_time,
                    previous_segment_search_id: segment_connection.previous_segment_id,
                    next_segment_search_id: segment_connection.next_segment_id,
                    segment_connection_sequence_number, 
                    warning: segment_connection.warning?.label
                })),
                segments: train_trip.solution.segments.map((segment, segment_sequence_number) => ({
                    segment_sequence_number,
                    search_id: segment.id,
                    origin_code: segment.origin.code,
                    origin_label: segment.origin.label,
                    destination_code: segment.destination.code,
                    destination_label: segment.destination.label,
                    departure_datetime: segment.departure,
                    arrival_datetime: segment.arrival,
                    duration: segment.duration,
                    operating_carrier: segment.operatingCarrier,
                    marketing_carrier: segment.marketingCarrier,
                    marketing_information: segment.marketingInformation,
                    vehicle_name: segment.vehicle.name,
                    vehicle_code: segment.vehicle.code || null,
                    vehicle_type: segment.vehicle.type,
                    vehicle_reference: segment.vehicle.reference,
                })),
                offer: {
                    search_id: train_trip.selected_offer!.search_identifier,
                    expiration_date: train_trip.selected_offer!.expiration_date, // ISO date string
                    comfort_category_code: train_trip.selected_offer!.comfort_category.code,
                    comfort_category_name: train_trip.selected_offer!.comfort_category.label,
                    flexibility_code: train_trip.selected_offer!.flexibility.code,
                    flexibility_name: train_trip.selected_offer!.flexibility.label,
                    fare_offers: train_trip.selected_offer!.fare_offers[0]!.fares.map(fare => ({
                        fare_code: fare.product.code,
                        fare_name: fare.product.label,
                        fare_type: fare.product.type,
                        marketing_carrier: fare.product.marketing_carrier,
                        supplier: fare.product.supplier,
                        cabin_class_code: fare.product.cabin_class.code,
                        cabin_class_name: fare.product.cabin_class.label,
                        refundable: fare.product.refundable,
                        exchangeable: fare.product.exchangeable,
                        conditions: fare.product.conditions
                    })),
                    travel_preferences: train_trips[train_trips.length - 1]!.selected_preferences!.filter((pref) => !!pref.option).map(traveler_preference => ({
                        preference_type: traveler_preference.preference_type,
                        options: [traveler_preference.option]
                    })),
                    // ticketing_option: train_trips[train_trips.length - 1]!.selected_ticketing!.code,
                    price: price!.purchase_price,
                    currency: price!.purchase_currency_code,
                },
                trip_direction: index === 0 ? 'going' : 'return',
            }
        })
    });
}