import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
    Avatar,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    StepConnector,
    stepConnectorClasses,
    StepperProps,
    styled,
    Typography
} from "@mui/material";
import {
    ArrowBackIosNew,
    ArrowForwardIos,
    TransferWithinAStation,
} from "@mui/icons-material";
import { findLastIndex } from "lodash";
import {
    AirIcon,
    AirportShuttleIcon,
    BedroomBabyIcon,
    CarIcon,
    DirectionsBikeIcon,
    DirectionsBoatIcon,
    DirectionsBusIcon,
    DirectionsCarIcon,
    DirectionsSubwayIcon,
    DirectionsTransitIcon,
    DirectionsWalkIcon,
    FlightIcon,
    LocalTaxiIcon,
    TrainIcon,
    TwoWheelerIcon,
    UnknowIcon,
    LoadingIcon
} from "./itineraryResumeTransportsIcon";
import tzlookup from "@photostructure/tz-lookup";
import Axios from "axios";
import { selectStepTransport } from "../../../Itinerary/utils/selectStepTransport";
import { ItineraryContentStep } from "../../../Itinerary/objects/itineraryContentStep";
import { AppState } from "../../../../Reducers/Reducers";
import { sortItinerary } from "../../../Itinerary/utils/sortItinerary";
import { itineraryToItineraryInput } from "../../../Itinerary/utils/itineraryToItineraryInput";
import { CartTransferCard } from "../../../CartMaterial/CartTransferCard";
import { CartManualProductCard } from "../../../CartMaterial/CartManualProductCard";
import GetCookie from "../../../Common/Functions/GetCookie";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { SetItinerary } from "../../../../Actions/Itinerary";
import CheckBeforeRequest from '../../../Common/CheckBeforeRequest';
import { Transport } from "../../../Itinerary/objects/transport";
import { StepsDirectionsManager } from "../../../Itinerary/utils/stepsDirectionsManager";

type ItemComponentProps = {
    children: JSX.Element,
    index: number,
    step: ItineraryContentStep,
    onClick: () => void
}
export type CarsItineraryProps = {
    steps?: ItineraryContentStep[],
    step?: number,
    setStep?: (step: number) => void,
    showPreview?: boolean,
    showStatus?: boolean,
    orientation?: StepperProps['orientation'],
    alternativeLabel?: StepperProps['alternativeLabel'],
    disableDatesEdit?: boolean,
    ItemComponent?: React.FC<ItemComponentProps>,
    dateToHighlight?: string,
    onChooseDate: (date: string, step?: number, date_type?: 'start_date' | 'end_date') => void,
    shouldShowTransfers?: boolean,
    inverseDateToHighlightScroll?: boolean,
    isB2bPackage?: boolean,
}

export const ItineraryResume = React.forwardRef<HTMLDivElement, CarsItineraryProps>(
    function ItineraryResume(props, ref): JSX.Element {
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const containerRef = useRef<HTMLDivElement>(null);
        const itemsRef = useRef<HTMLDivElement[]>([]);
        const filterItinerary = itinerary.filter(step => step.step_type === 'STEP');

        const [loadingTransports, setLoadingTransports] = useState<null | number>(null);
        const [transports, setTransports] = useState<Transport[]>([]);
        const [areElementsAllVisible, setAreElementsAllVisible] = useState(false);
        const [stepIndex, setStepIndex] = useState<null | number>(null);
        const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
        const cart = useCartProducts();

        const onPrev = () => {
            const firstVisibleElementIndex = itemsRef.current.findIndex((item) => {
                return isElementVisible(containerRef.current, item);
            });
            itemsRef.current[firstVisibleElementIndex - 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };

        const onNext = () => {
            const lastVisibleElementIndex = findLastIndex(
                itemsRef.current,
                (item) => {
                    return isElementVisible(containerRef.current, item);
                }
            );
            itemsRef.current[lastVisibleElementIndex + 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };
        const onChangeStepTransport = async (
            newTransport: Transport
        ) => {
            const { headers } = CheckBeforeRequest();
            if (stepIndex !== null) {
                const result = filterItinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);
                let current_step = result[stepIndex - 1];
                let next_step = result[stepIndex];
                if (current_step !== undefined && next_step !== undefined) {
                    const manager = StepsDirectionsManager.getInstance();
                    const aPosition = await manager.transformStepToCoordinates(current_step);
                    const bPosition = await manager.transformStepToCoordinates(next_step);
                    selectStepTransport({
                        step: current_step,
                        nextStep: next_step,
                        fromTimezone: tzlookup(aPosition.lat(), aPosition.lng()),
                        toTimezone: tzlookup(bPosition.lat(), bPosition.lng()),
                        nextStepCoordinates: {
                            latitude: bPosition.lat(),
                            longitude: bPosition.lng()
                        },
                        transport: newTransport.alternatives[0],
                        r2rPlaces: newTransport.r2rPlaces,
                        r2rRoutes: newTransport.r2rRoutes,
                        isCustom: false
                    });
                    console.log('current_step:', current_step);
                    Axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${current_step.id}/?token=${GetCookie("trip_token")}&shift_mode=true`,
                        headers: headers,
                        data: JSON.stringify({
                            r2r_json: current_step?.r2r_json
                        })
                    }).then(function (data: any) {
                        console.log('data:', data);
                        dispatch(SetItinerary(data.data));
                        handleClose();
                    }).catch(function (error: any) {
                        console.log('error:', error);
                    });
                }
            }
        };

        const handleClick = async (index: number) => {
            if (props.isB2bPackage) {
                return;
            }

            setLoadingTransports(index);
            const result = filterItinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);
            let current_step = result[index - 1];
            let next_step = result[index];
            if (current_step !== undefined && next_step !== undefined) {
                const manager = StepsDirectionsManager.getInstance();
                try {
                    let tmp_transports = await manager.findAvailableTransports(current_step, next_step);
                    let unique_transports: Transport[] = [];
                    tmp_transports.map((transport: Transport) => {
                        if (unique_transports.find((unique) => unique.types.join() === transport.types.join()) === undefined) {
                            unique_transports.push(transport);
                        }
                    });
                    setTransports(unique_transports);
                    if (tmp_transports.length !== 0 && itemsRef.current[index]) {
                        setAnchorEl(itemsRef.current[index]!);
                        setStepIndex(index);
                    }
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoadingTransports(null);
                }
                setLoadingTransports(null);
            }
        };

        const handleClose = () => {
            setTransports([]);
            setAnchorEl(null);
            setStepIndex(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'choose-transport' : undefined;

        useEffect(() => {
            if (props.dateToHighlight) {
                const date = window.moment.utc(props.dateToHighlight);
                const iconElement = document.querySelector(
                    `.transfer-step-icon[data-date="${date.format('yyyy-MM-DD')}"]`
                );
                if (iconElement) {
                    iconElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                } else {
                    const dateElement = document.querySelector(
                        `.transfer-highlight-date[data-date="${date.format('yyyy-MM-DD')}"]`
                    );
                    dateElement?.parentElement?.parentElement?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
            }
        }, [props.dateToHighlight]);

        return (
            <Stack ref={ref} direction="row" alignItems="center" justifyContent={'center'}>
                {
                    !areElementsAllVisible &&
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onPrev}>
                        <ArrowBackIosNew />
                    </IconButton>
                }
                <Stack
                    ref={containerRef}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                        maxWidth: 'calc(100% - 100px)',
                        overflow: 'hidden',
                    }}
                >
                    {
                        (
                            filterItinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary)
                        ).map((item, index, array) => {
                            const prevStep = array[index - 1];
                            let destination = '';
                            let step_date = `${window.moment.utc(item.start_date).format('L')} - ${window.moment.utc(item.end_date).format('L')}`;
                            let Icon = UnknowIcon;
                            let vehicle = prevStep?.r2r_json?.vehicle;
                            if (vehicle !== undefined && (loadingTransports === null || loadingTransports !== index)) {
                                switch (vehicle.kind) {
                                    case 'animal': {
                                        Icon = BedroomBabyIcon;
                                        break;
                                    }
                                    case 'bicycle': {
                                        Icon = DirectionsBikeIcon;
                                        break;
                                    }
                                    case 'bus': {
                                        Icon = DirectionsBusIcon;
                                        break;
                                    }
                                    case 'cablecar': {
                                        Icon = DirectionsTransitIcon;
                                        break;
                                    }
                                    case 'car': {
                                        Icon = CarIcon;
                                        break;
                                    }
                                    case 'ferry': {
                                        Icon = DirectionsBoatIcon;
                                        break;
                                    }
                                    case 'foot': {
                                        Icon = DirectionsWalkIcon;
                                        break;
                                    }
                                    case 'helicopter': {
                                        Icon = AirIcon;
                                        break;
                                    }
                                    case 'plane': {
                                        Icon = FlightIcon;
                                        break;
                                    }
                                    case 'rideshare': {
                                        Icon = DirectionsCarIcon;
                                        break;
                                    }
                                    case 'shuttle': {
                                        Icon = AirportShuttleIcon;
                                        break;
                                    }
                                    case 'subway': {
                                        Icon = DirectionsSubwayIcon;
                                        break;
                                    }
                                    case 'taxi': {
                                        Icon = LocalTaxiIcon;
                                        break;
                                    }
                                    case 'towncar': {
                                        Icon = DirectionsCarIcon;
                                        break;
                                    }
                                    case 'train': {
                                        Icon = TrainIcon;
                                        break;
                                    }
                                    case 'tram': {
                                        Icon = DirectionsTransitIcon;
                                        break;
                                    }
                                    case 'bike': {
                                        Icon = TwoWheelerIcon;
                                        break;
                                    }
                                    default: {
                                        Icon = UnknowIcon;
                                    }
                                }
                            }
                            if (loadingTransports === index) {
                                Icon = LoadingIcon;
                            }
                            const nightDestination = item.destination;
                            destination = nightDestination?.international_name ?? '';
                            destination = destination.split(',')[0] ?? '';

                            const hotel =
                                cart.accommodations.find((hotel) => {
                                    return window.moment.utc(hotel.accommodation.start_date).startOf('day').isSame(
                                        window.moment.utc(item.start_date).startOf('day'),
                                        'day'
                                    );
                                });
                            const hotelName = hotel && 'type' in hotel ?
                                hotel?.type === 'normal' ?
                                    hotel.accommodation.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    hotel.accommodation.hotel[0]?.name ??
                                    '' :
                                    hotel?.accommodation.localization?.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    hotel?.accommodation.name :
                                hotel?.hotel?.name;

                            return (
                                <>
                                    {
                                        index !== 0 &&
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                position: 'relative',
                                                alignSelf: 'stretch'
                                            }}
                                        >
                                            <StepConnector
                                                sx={{
                                                    "minWidth": 75,
                                                    [`& .${stepConnectorClasses.line}`]: {
                                                        border: '1px solid #bdbdbd'
                                                    }
                                                }}
                                            />
                                            {
                                                index !== 0 ?
                                                    <div
                                                        data-date={
                                                            props.inverseDateToHighlightScroll && prevStep ?
                                                                window.moment.utc(prevStep.start_date).format('yyyy-MM-DD') :
                                                                window.moment.utc(item.start_date).format('yyyy-MM-DD')
                                                        }
                                                        className="transfer-step-icon"
                                                    >
                                                        <Icon icon={null} />
                                                    </div> :
                                                    undefined
                                            }
                                            <StepConnector
                                                sx={{
                                                    "minWidth": 75,
                                                    [`& .${stepConnectorClasses.line}`]: {
                                                        border: '1px solid #bdbdbd'
                                                    }
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    bottom: 0,
                                                    transform: 'translateX(-50%)',
                                                    marginLeft: '0 !important',
                                                    height: 20
                                                }}
                                            >
                                                <TransferIcon date={item.start_date} />
                                            </Box>
                                        </Stack>
                                    }
                                    <Stack
                                        justifyItems={'center'}
                                        alignItems={'center'}
                                        sx={{ cursor: 'pointer', width: '100%' }}
                                        ref={(element) => {
                                            if (element) {
                                                itemsRef.current[index] = element;
                                                setAreElementsAllVisible(
                                                    itemsRef.current.every((item) => {
                                                        return isElementVisible(containerRef.current, item);
                                                    })
                                                );
                                            }
                                        }}
                                        onClick={() => {
                                            handleClick(index);
                                            props.setStep && props.setStep(index);
                                        }}
                                    >
                                        <Typography>{t('itinerary.step') + (index + 1)}</Typography>
                                        <Typography>{destination}</Typography>
                                        {/* <Typography>
                                            {
                                                hotelName ?? t('shared.no-accommodation')
                                            }
                                        </Typography> */}
                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                            <Trans
                                                i18nKey="cms.tailor-made.transfer-search-step-date"
                                                values={{
                                                    from: window.moment.utc(item.start_date).format('L'),
                                                    to: window.moment.utc(item.end_date).format('L')
                                                }}
                                                components={{
                                                    from: (
                                                        <DateWrapper
                                                            date={item.start_date}
                                                            dateToHighlight={props.dateToHighlight}
                                                            onChooseDate={(date) => props.onChooseDate(date, index, 'start_date')}
                                                            shouldShowTransfers={
                                                                props.shouldShowTransfers &&
                                                                index === 0
                                                            }
                                                        />
                                                    ),
                                                    to: (
                                                        <DateWrapper
                                                            date={item.end_date}
                                                            dateToHighlight={props.dateToHighlight}
                                                            onChooseDate={(date) => props.onChooseDate(date, index, 'end_date')}
                                                            shouldShowTransfers={
                                                                props.shouldShowTransfers &&
                                                                index === array.length - 1
                                                            }
                                                            isEnd
                                                        />
                                                    )
                                                }}
                                            />
                                        </Typography>
                                    </Stack>
                                </>
                            );
                        })
                    }
                </Stack>
                {
                    !areElementsAllVisible &&
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onNext}>
                        <ArrowForwardIos />
                    </IconButton>
                }
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <List sx={{ width: 170 }}>
                        {
                            transports.map((transport, index_transport) => {
                                return (
                                    <ListItem key={index_transport} disablePadding>
                                        <ListItemButton sx={{ cursor: 'pointer' }} onClick={() => onChangeStepTransport(transport)}>
                                            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                                <Grid item xs={'auto'}>
                                                    <ListItemAvatar sx={{ display: 'inline-flex' }}>
                                                        {
                                                            transport.types.map((type: string, index_type) => (
                                                                <Avatar key={index_type} alt={type} src={`/Img/transport/route-${type}.png`} sx={{ width: 24, height: 24, marginRight: '2px' }} />
                                                                // <img
                                                                //     key={index_type}
                                                                //     src={"/Img/transport/route-" + type + ".png"}
                                                                //     height="25px"   
                                                                //     className={"itinerary-transport-mode-icon"}
                                                                // />
                                                            ))
                                                        }
                                                    </ListItemAvatar>
                                                </Grid>
                                                <Grid item>
                                                    <ListItemText primary={t(`global.${transport.types[0]}`)} />
                                                </Grid>
                                            </Grid>
                                        </ListItemButton>

                                    </ListItem>

                                );
                            })
                        }
                    </List>
                </Popover>
            </Stack>
        );
    }
);

type DateWrapperProps = Pick<
    CarsItineraryProps,
    'shouldShowTransfers' | 'dateToHighlight' | 'onChooseDate'
> & {
    date: string,
    isEnd?: boolean
};

function DateWrapper(props: PropsWithChildren<DateWrapperProps>): JSX.Element {
    const cart = useCartProducts();
    // const product = cart.transfers.find((item) => {
    //     return window.moment.utc(
    //         props.isEnd ?
    //             item.transfer.start_date :
    //             item.transfer.end_date
    //     ).startOf('day').isSame(
    //         window.moment.utc(props.date),
    //         'day'
    //     );
    // });

    return (
        <>
            <Stack
                display="inline-flex"
                alignItems="center"
                onClick={(event) => event.stopPropagation()}
                sx={{
                    paddingBottom: '20px'
                }}
            >
                {
                    <Box
                        onClick={() => props.onChooseDate?.(props.date)}
                        sx={(theme) => ({
                            color: props.dateToHighlight && window.moment.utc(props.date).startOf('day').isSame(
                                window.moment.utc(props.dateToHighlight).startOf('day'),
                                'day'
                            ) ?
                                theme.palette.primary.main :
                                undefined,
                            transition: theme.transitions.create(
                                'color',
                                {
                                    duration: 300,
                                    easing: theme.transitions.easing.sharp
                                }
                            ),
                            cursor: 'pointer'
                        })}
                        className={`transfer-highlight-date`}
                        data-date={window.moment.utc(props.date).format('yyyy-MM-DD')}
                        component="span"
                    >
                        {props.children}
                    </Box>
                }
                {
                    props.shouldShowTransfers &&
                    <TransferIcon
                        date={props.date}
                        isEnd={props.isEnd}
                    />
                }
            </Stack>
        </>
    );
}

type TransferIconProps = {
    date: string,
    isEnd?: boolean
}

function TransferIcon(props: TransferIconProps): React.ReactNode {
    const [open, setOpen] = useState(false);
    const container = useRef(null);
    const cart = useCartProducts();
    const product = cart.transfers.find((item) => {
        return window.moment.utc(
            props.isEnd ?
                item.transfer.start_date :
                item.transfer.end_date
        ).startOf('day').isSame(
            window.moment.utc(props.date),
            'day'
        );
    });

    const onClose = () => {
        setOpen(false);
    }

    const icon = <TransferWithinAStation
        ref={container}
        fontSize="small"
        color="secondary"
        onClick={() => setOpen(true)}
    />;

    if (!product) {
        return null;
    }

    return (
        <>
            {icon}
            {
                product &&
                open &&
                <Popover
                    open
                    anchorEl={container.current}
                    onClose={onClose}
                    slotProps={{
                        paper: {
                            sx: {
                                width: 650,
                                maxWidth: '50vw'
                            }
                        }
                    }}
                    sx={{ marginRight: 3 }}
                >
                    {
                        product.type === 'normal' ?
                            <CartTransferCard
                                transfer={product.transfer}
                                // disableSideActions
                                // isInSearchModule
                                alwaysOpen
                            /> :
                            <CartManualProductCard
                                product={product.transfer}
                                // disableSideActions
                                alwaysOpen
                            />
                    }
                </Popover>
            }
        </>
    );
}

export const ShowItineraryStepperButton = styled('button')((props) => ({
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    opacity: 0.6,
    fontSize: props.theme.typography.body2.fontSize
}));

const isElementVisible = (containerElement: HTMLDivElement | null, itemElement: HTMLDivElement) => {
    if (containerElement) {
        const container = containerElement.getBoundingClientRect();
        const element = itemElement.getBoundingClientRect();
        const containerLeft = Math.trunc(container.left);
        const containerRight = Math.trunc(container.right);
        const elementLeft = Math.trunc(element.left);
        const elementRight = Math.trunc(element.right);
        return (
            containerLeft <= elementLeft &&
            elementLeft <= containerRight
        ) && (
                containerLeft <= elementRight &&
                elementRight <= containerRight
            );
    }
    return false;
};
