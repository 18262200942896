import { useMemo } from "react";
import { useFilteredCartProducts } from "../../Itinerary/network/filteredCartProducts";
import { isProductPackaged } from "./isProductPackaged";
import { useSelector } from "react-redux";
import { AppState } from "../../../Reducers/Reducers";

export function useUnpackagedProducts() {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const filteredCart = useFilteredCartProducts();
    return useMemo((): typeof filteredCart => {
        return {
            flights: filteredCart.flights.filter((item) => {
                return !isProductPackaged({
                    product: item.flight,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            trains: filteredCart.trains.filter((item) => {
                return !isProductPackaged({
                    product: item.train,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            accommodations: filteredCart.accommodations.filter((item) => {
                return !isProductPackaged({
                    product: item.accommodation,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            cars: filteredCart.cars.filter((item) => {
                return !isProductPackaged({
                    product: item.car,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            pois: filteredCart.pois.filter((item) => {
                return !isProductPackaged({
                    product: item.poi,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            transfers: filteredCart.transfers.filter((item) => {
                return !isProductPackaged({
                    product: item.transfer,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            assistances: filteredCart.assistances.filter((item) => {
                return !isProductPackaged({
                    product: item.assistance,
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            manualProducts: filteredCart.manualProducts.filter((item) => {
                return !isProductPackaged({
                    product: item,
                    stackInfos: trip?.stack_info ?? null
                });
            })
        };
    }, [filteredCart]);
}
