//Dependencies
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, Fragment, useState } from 'react';
//Core
import { Button } from '@mui/material';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { TrainCartFromBackend } from './objects/backendData/cart/trainCartFromBackend';
import { editTrainCartById } from './redux/reducer';

const useStyles = makeStyles({
    validateButton: {
        marginTop: 15,
        backgroundColor: '#E6592F',
        color: 'white'
    }
});

export const CartTrainNote:FC<{train: TrainCartFromBackend, onAddNote: () => void}> = ({ train, onAddNote }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '')?.quotation_code ?? '';

    const [note, setNote] = useState(train.custom_information !== null ? train.custom_information : '');
    const onNoteChange = (event: any) => {
        setNote(event.target.value);
    };
    const onValidateNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/train/${train.id}/`,
            data: {
                custom_information: note
            }
        }).then(response => {
            dispatch(editTrainCartById(response.data));
            onAddNote();
        }).catch(error => {
            console.log(error);
        });
    };
    return (
        <Fragment>
            <FormControl fullWidth variant={ 'outlined' }>
                <InputLabel htmlFor={ 'note' }>{ train.custom_information !== null ? ["cercledesvoyages"].includes(quotation_code) ? t('cart-material.edit-note-client') : t('cart-material.edit-note') : (["cercledesvoyages"].includes(quotation_code) ? t('cart-material.add-note-client') : t('cart-material.add-note')) }</InputLabel>
                <OutlinedInput
                    id={ 'note' }
                    type={ 'text' }
                    multiline
                    rows={ 8 }
                    onChange={ onNoteChange }
                    label={ ["cercledesvoyages"].includes(quotation_code) ? t('cart-material.add-note-client') : t('cart-material.add-note') }
                    value={ note }
                />
            </FormControl>
            <Button onClick={ onValidateNote } sx={{ display: 'flex', marginLeft: 'auto', marginTop: 2 }}>
                { t('global.validate') }
            </Button>
        </Fragment>
    );
};
