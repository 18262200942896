import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonProps, IconButton, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { format, getDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import moment, { Moment } from 'moment';
import clsx from 'clsx';
import { generateMonthlyDates } from '../../utils/dates/generateMonthlyDates';

const SmallTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
    borderBottom: 'none',
}));
const BodyTableCell = styled(TableCell)(({ theme }) => ({
    padding: 0,
    borderBottom: 'none',
}));
interface BodyButtonProps extends ButtonProps {
    isSelected?: boolean;
}
const BodyButton = styled(({ isSelected, ...rest }: BodyButtonProps) => (
    <Button {...rest} />
))(({ theme, isSelected }) => ({
    padding: theme.spacing(1),
    minWidth: 0,
    color: 'black',
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    position: "relative",
    '&:hover': {
        backgroundColor: isSelected ? 'rgb(0, 117, 141) !important' : 'rgb(229, 229, 239) !important',
    }
}));

const useStyles = makeStyles((theme) => ({
    betweenCell: {
        backgroundColor: 'rgb(229, 229, 239) !important',
    },
    startExtremumCell: {
        backgroundColor: 'rgb(229, 229, 239) !important',
        borderRadius: '50% 0 0 50% !important',
    },
    endExtremumCell: {
        backgroundColor: 'rgb(229, 229, 239) !important',
        borderRadius: '0 50% 50% 0 !important',
    },
    stackContainerResponsive: {
        flexDirection: 'row !important' as unknown as undefined,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column  !important' as unknown as undefined,
        },
    },
    selectedCell: {
        backgroundColor: 'rgb(0, 97, 121) !important',
        color: 'white !important',
    },
    todayCell: {
        fontWeight: 'bold',
        paddingBottom: '10px',
        '&:after': {
            position: 'absolute',
            content: "' '",
            width: '40%',
            height: '1px',
            display: 'block',
            backgroundColor: 'black',
            bottom: '9px',
            margin: '0 auto'
        }
    },
    todayCellSelected: {
        '&:after': {
            backgroundColor: 'white !important',
        }
    }
}));

interface CalendarProps {
    numberOfMonths: number;
    minDateCanBeSelected?: Date;
    startDate: Date | null;
    endDate: Date | null;
    handleOneClick?: boolean;
    disabled?: boolean;
    clickOnDate: (date: Moment, click_number: number) => void;
}

const Calendar: React.FC<CalendarProps> = ({ numberOfMonths, minDateCanBeSelected, startDate, endDate, handleOneClick, disabled, clickOnDate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { t } = useTranslation();
    const [click_number, setClickNumber] = useState<0 | 1>(0);
    const [hovered_date, setHoveredDate] = useState<Moment | null>(null);
    const [date_to_display, setDateToDisplay] = useState<Moment>(moment.utc(startDate ?? new Date()).startOf('month'));
    const monthlyDates = useMemo(() => generateMonthlyDates(numberOfMonths, moment.utc(date_to_display)), [numberOfMonths, date_to_display]);
    const today_moment = moment.utc().startOf('day');
    const date_can_be_selected = minDateCanBeSelected ? moment.utc(minDateCanBeSelected).startOf('day') : today_moment;

    const can_prev_calendar = moment.utc(date_to_display).startOf('day').startOf('month').isAfter(moment.utc(date_can_be_selected).startOf('month'));

    const prevCalendar = () => {
        setDateToDisplay((state) => moment.utc(state).subtract(numberOfMonths, 'months').startOf('month'));
    };
    const nextCalendar = () => {
        setDateToDisplay((state) => moment.utc(state).add(numberOfMonths, 'months').startOf('month'));
    };
    const onSelectDate = (date: Date) => {
        if (!!disabled) { return; }
        if (moment(date).startOf('day').isBefore(moment(startDate).startOf('day'))) {
            clickOnDate(moment(date), 0);
            setClickNumber(1);
        } else {
            clickOnDate(moment(date), click_number);
            setClickNumber((state) => (state + 1) % 2 as 0 | 1);
        }
        if (handleOneClick) { setClickNumber(0); }
    };

    const isTodayDate = (date: Date) => moment.utc(date).startOf('day').isSame(today_moment, 'day');
    const isStartDateSelected = (date: Date) => startDate && moment.utc(date).startOf('day').isSame(moment.utc(startDate), 'day');
    const isEndDateSelected = (date: Date) => endDate && moment.utc(date).startOf('day').isSame(moment.utc(endDate), 'day');
    const isSelectedDate = (date: Date) => isStartDateSelected(date) || isEndDateSelected(date);
    const isHoveredDate = (date: Date) => startDate && (hovered_date || endDate) && moment.utc(date).startOf('day').isBefore(moment.utc(hovered_date || endDate).startOf('day'), 'day') && moment.utc(date).startOf('day').isAfter(moment.utc(startDate).startOf('day'), 'day');
    const isExtremumStartDate = (date: Date) => ((endDate && !hovered_date) && isStartDateSelected(date) && !moment.utc(endDate).startOf('day').isSame(moment.utc(startDate).startOf('day'), 'day')) || (hovered_date && moment.utc(hovered_date).isAfter(moment.utc(startDate).startOf('day'), 'day') && isStartDateSelected(date) && !moment.utc(hovered_date).startOf('day').isSame(moment.utc(startDate).startOf('day'), 'day'));
    const isExtremumEndDate = (date: Date) => (!click_number && startDate && (endDate) && moment.utc(date).startOf('day').isSame(moment.utc((endDate)), 'day') && !moment.utc(endDate).startOf('day').isSame(moment.utc(startDate).startOf('day'), 'day')) || (click_number && hovered_date && moment.utc(hovered_date).startOf('day').isAfter(moment.utc(startDate).startOf('day'), 'day') && moment.utc(date).startOf('day').isSame(moment.utc(hovered_date).startOf('day'), 'day'));

    const weekdays = [
        t('calendar.lun'),
        t('calendar.mar'),
        t('calendar.mer'),
        t('calendar.jeu'),
        t('calendar.ven'),
        t('calendar.sam'),
        t('calendar.dim'),
    ];

    useEffect(() => {
        const handleRightClick = (event: { preventDefault: () => void; }) => {
            if (click_number === 1) {
                event.preventDefault();
                setClickNumber(0);
            }
        };

        // Attach the listener to the body
        document.body.addEventListener("daterangecontext", handleRightClick);

        // Cleanup the listener on component unmount
        return () => {
            document.body.removeEventListener("daterangecontext", handleRightClick);
        };
    }, []);


    useEffect(() => {
        if (!click_number) setHoveredDate(null)
    }, [click_number])
    return <Stack direction={!isMobile ? 'row' : 'column'} gap={6} alignItems={'center'} justifyContent={'center'} onMouseLeave={() => click_number > 0 && setHoveredDate(null)}>
        {!isMobile && <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} width={'fit-content'}>
            <IconButton disabled={!can_prev_calendar} onClick={prevCalendar}><ArrowBack /></IconButton>
        </Stack>}
        <Stack className={classes.stackContainerResponsive} gap={5}>
            {
                monthlyDates.map((monthDates, monthIndex) => (
                    <Stack flex={`0 1 ${100 / numberOfMonths}%`} key={`calendar-${monthIndex}`} direction={'column'}>
                        <TableContainer><Table>
                            <TableHead>
                                <TableRow><SmallTableCell colSpan={7} sx={{ fontWeight: 'bold' }}>
                                    <Typography variant="subtitle1" fontWeight="bold" fontSize={20}>
                                        {t(`months.${format(monthDates.find(Boolean) as Date, 'MMMM').toLocaleLowerCase()}`)} {format(monthDates.find(Boolean) as Date, 'yyyy')}
                                    </Typography>
                                </SmallTableCell></TableRow>
                                <TableRow>
                                    {weekdays.map((day, index) => (
                                        <SmallTableCell key={`header-${monthIndex}-${index}`} align="center">
                                            <Typography variant="subtitle1">
                                                {day}
                                            </Typography>
                                        </SmallTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from({ length: Math.ceil((monthDates.length + (getDay(monthDates[0] as Date) + 6) % 7) / 7) }).map((_, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {Array.from({ length: 7 }).map((_, columnIndex) => {
                                            const dayIndex = rowIndex * 7 + columnIndex - ((getDay(monthDates[0] as Date) + 6) % 7); // Align with Monday
                                            const date = monthDates[dayIndex];

                                            return (
                                                <BodyTableCell
                                                    key={columnIndex}
                                                    align="center"
                                                    className={clsx(
                                                        { [classes.betweenCell]: date && isHoveredDate(date) },
                                                        { [classes.startExtremumCell]: date && isExtremumStartDate(date) },
                                                        { [classes.endExtremumCell]: date && isExtremumEndDate(date) },
                                                    )}
                                                    onMouseEnter={() => click_number > 0 && date && setHoveredDate(moment.utc(date))}>
                                                    {date ? (
                                                        <BodyButton
                                                            disabled={moment.utc(date).startOf('day').isBefore(date_can_be_selected) || !!disabled}
                                                            isSelected={!!isSelectedDate(date)}
                                                            className={clsx(
                                                                { [classes.todayCell]: isTodayDate(date) },
                                                                { [classes.selectedCell]: isSelectedDate(date) },
                                                                { [classes.todayCellSelected]: isTodayDate(date) && isSelectedDate(date) },
                                                            )}
                                                            onClick={() => onSelectDate(date)}
                                                        >
                                                            <Typography variant="body2">
                                                                {format(date, 'dd')}
                                                            </Typography>
                                                        </BodyButton>
                                                    ) : (
                                                        ''
                                                    )}
                                                </BodyTableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table></TableContainer>
                    </Stack>
                ))
            }
        </Stack>
        {!isMobile && <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} width={'fit-content'}>
            <IconButton onClick={nextCalendar}><ArrowForward /></IconButton>
        </Stack>}
    </Stack>

};

export default Calendar;
