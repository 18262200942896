import { Add, PersonOutline } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../Reducers/Reducers"
import { makeStyles } from "@material-ui/core"
import { TrainSearchTravelersPicker } from "./trainSearchTravelersPicker"
import clsx from "clsx"
import { updateTrainListFiltersFrontCriteria } from "./redux/reducer"


const useStyles = makeStyles((theme) => ({
  stackContentResponsive: {
    gap: theme.spacing(1),
    flexDirection: 'row  !important' as unknown as undefined, // For desktop
    flex: '0 1 100%',
  },
}));
export const TrainSearchTravelers: FC<{return_required?: boolean}> = ({return_required}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const travelers = useSelector((store: AppState) => store.train.train_list_criteria.filters.travelers);
  const travelers_error = useSelector((store: AppState) => store.train.train_list_criteria.filters.travelers_error);
  const train_list_filters_criteria = useSelector((store: AppState) => store.train.train_list_criteria.filters);
  const [is_traveler_open, setIsTravelerOpen] = React.useState<boolean>(false);
  const toggleGroups = () => { 
    setIsTravelerOpen(state => !state);
    dispatch(updateTrainListFiltersFrontCriteria({...train_list_filters_criteria, travelers_error: undefined}));
   };

  return (<>
    <Stack className={classes.stackContentResponsive} >
      <Button disabled={!!return_required} className={clsx(!travelers_error && 'traveler-criteria', !!travelers_error && 'traveler-criteria-error')} startIcon={<PersonOutline />} onClick={toggleGroups}>{t('shared.travelers', { count: travelers.length })}</Button>
    </Stack>
    <TrainSearchTravelersPicker is_open={is_traveler_open} onClose={toggleGroups} />
  </>
  )
}