import { FareOffersEntity } from "../../objects/backendData/trainBackendDate";

export type ExchangeStatus =
  | 'TOTALY_EXCHANGABLE_TOTALY_REFUNDABLE'
  | 'TOTALY_EXCHANGABLE_REFUNDABLE'
  | 'TOTALY_EXCHANGABLE_NOT_REFUNDABLE'
  | 'NOT_EXCHANGABLE_REFUNDABLE'
  | 'NOT_EXCHANGABLE_TOTALY_REFUNDABLE'
  | 'NOT_EXCHANGABLE_NOT_REFUNDABLE'
  | 'EXCHANGABLE_TOTALY_REFUNDABLE'
  | 'EXCHANGABLE_REFUNDABLE'
  | 'EXCHANGABLE_NOT_REFUNDABLE';

const exchangeStatusMap: Record<string, ExchangeStatus> = {
  'YES_YES': 'TOTALY_EXCHANGABLE_TOTALY_REFUNDABLE',
  'YES_WITH_CONDITION': 'TOTALY_EXCHANGABLE_REFUNDABLE',
  'YES_NO': 'TOTALY_EXCHANGABLE_NOT_REFUNDABLE',
  'WITH_CONDITION_YES': 'EXCHANGABLE_TOTALY_REFUNDABLE',
  'WITH_CONDITION_WITH_CONDITION': 'EXCHANGABLE_REFUNDABLE',
  'WITH_CONDITION_NO': 'EXCHANGABLE_NOT_REFUNDABLE',
  'NO_YES': 'NOT_EXCHANGABLE_TOTALY_REFUNDABLE',
  'NO_WITH_CONDITION': 'NOT_EXCHANGABLE_REFUNDABLE',
  'NO_NO': 'NOT_EXCHANGABLE_NOT_REFUNDABLE',
};

export const getExchangeRefundableStatus = (exchangeable: 'YES' | 'NO' | 'WITH_CONDITION', refundable: 'YES' | 'NO' | 'WITH_CONDITION') => exchangeStatusMap[`${exchangeable}_${refundable}`] as ExchangeStatus;

export const getExchangeStatus = (fare_offers: FareOffersEntity[]): ExchangeStatus => {
  let exchangeable: 'YES' | 'NO' | 'WITH_CONDITION' | undefined = fare_offers[0]!.fares![0]!.product?.exchangeable || 'NO';
  let refundable: 'YES' | 'NO' | 'WITH_CONDITION' | undefined = fare_offers[0]!.fares![0]!.product?.refundable || 'NO';

  for (const fareOffer of fare_offers) {
    for (const fare of fareOffer.fares || []) {
      const { exchangeable: ex, refundable: ref } = fare.product;

      if (ex === 'WITH_CONDITION') exchangeable = 'WITH_CONDITION';
      else if (exchangeable !== 'WITH_CONDITION') exchangeable = ex;

      if (ref === 'WITH_CONDITION') refundable = 'WITH_CONDITION';
      else if (refundable !== 'WITH_CONDITION') refundable = ref;
    }
  }



  return getExchangeRefundableStatus(exchangeable, refundable) as ExchangeStatus;
};
