import { autocompleteClasses, Checkbox, FormControl, FormHelperText, Stack, Typography } from "@mui/material"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../Reducers/Reducers"
import { makeStyles, Popper } from "@material-ui/core"
import { updateTrainListFiltersFrontCriteria } from "./redux/reducer"
import { TrainListFiltersFrontCriteria } from "./objects/trainList"
import { AutocompleteTemplate } from "./components/inputs/autocompleteTemplate"
import { TrainProvider } from "./objects/backendData/trainProvider"
import {  FactoryOutlined } from "@mui/icons-material"

const input_style = {
  [`.${autocompleteClasses.inputRoot}`]: {
      maxHeight: '3rem !important'
  }
};
const CustomPopper = (props: any) => (
  <Popper {...props} style={{ width: "300px", borderRadius: '0.6rem' }} />
);
const useStyles = makeStyles((theme) => ({
  stackContentResponsive: {
    gap: theme.spacing(1),
    flexDirection: 'row  !important' as unknown as undefined, // For desktop
    flex: '0 1 100%',
  },
}));
export const TrainSearchProviders: FC<{ return_required?: boolean }> = ({ return_required }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const train_providers = useSelector((store: AppState) => store.train.train_providers);
  const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
  const setTrainListFilterCriteria = (key: keyof TrainListFiltersFrontCriteria, value: any) => {
    dispatch(updateTrainListFiltersFrontCriteria({ ...train_list_filters_criteria, [key]: value, [`${key}_error`]: undefined }));
  };
  return (<>
    <Stack className={classes.stackContentResponsive} >
      <FormControl fullWidth error={!!train_list_filters_criteria.providers_error}>
        <AutocompleteTemplate
          value_parameters={{
            value: train_list_filters_criteria.providers,
            setValue: (providers: TrainProvider[]) => { setTrainListFilterCriteria('providers', providers); },
            getOptionLabel: (option: TrainProvider) => option?.name ?? '-',
            renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: TrainProvider) => <li {...props} key={option?.id ?? '-'} style={{ display: 'flex', gap: '25px', alignItems: 'center', borderBottom: 'solid 0.5px rgba(0, 0, 0, 0.1)' }}>
              {/* {getOptionTypeIcon(option.type)} */}
              <Stack direction={'row'} gap={0.5} alignItems={'center'} sx={{height: 'fit-content'}}>
                <Checkbox checked={!!train_list_filters_criteria.providers?.find(provider => option?.id === provider.id)} />
                <Typography variant='body2' fontWeight={'bold'}>
                  {option?.name ?? '-'}
                </Typography>
              </Stack>
            </li>,
            required: true,
            multiple: true,
            label: t('shared.providers'),
            error: !!train_list_filters_criteria.providers_error,
            disabled: return_required,
          }}
          request_parameters={{
            predifined_option: train_providers || []
          }}
          sx={input_style}
          endAdornment={null}
          startAdornment={<FactoryOutlined sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />}
          CustomPoper={CustomPopper}
        />
        <FormHelperText>{train_list_filters_criteria.providers_error}</FormHelperText>
      </FormControl>
    </Stack>
  </>
  )
}