

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@mui/material/colors';

export const useCartDetailStyles = makeStyles(() => ({
    disableBorder: {
        border: 'none !important'
    },
    smallText: {
        fontSize: 10
    },
    gridContainer: {
        marginBottom: 8,
        borderRadius: 8
    },
    mainRow: {
        margin: 0,
        position: 'relative'
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    priceDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    firstRow: {
        backgroundColor: '#daf6ff',
        margin: 0
    },
    secondRow: {
        margin: 0
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 48
    },
    baggage: {
        color: '#E6592F',
        fontSize: 48
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    selector: {
        margin: '4px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800',
        cursor: 'pointer'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    cancellable: {
        color: '#4caf50',
        border: '1px solid',
        cursor: 'pointer',
        borderRadius: 8,
        fontSize: 12,
        padding: 2
    },
    notCancellable: {
        color: '#f44336',
        border: '1px solid',
        cursor: 'pointer',
        borderRadius: 8,
        fontSize: 12,
        padding: 2
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: '20px !important'
    },
    delete: {
        position: 'absolute',
        right: 4,
        top: 4,
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    catalogedPrice: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 12
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
	    color: green[500],
	    position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        //height: '100%',
        //width: 50,
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    cancelConditionAlertVerdie: {
        whiteSpace: 'pre-wrap'
    }
}));
