import { Box, Checkbox, Chip, Divider, Stack, Typography } from "@mui/material";
import React, { FC, useContext, useMemo } from "react";
import { CartConstructionProductsContext } from "../CartMaterial/utils/cartConstructionProductsContext";
import { isProductBookable } from "../CartMaterial/utils/isProductBookable";
import { CartProductCardProps } from "../CartMaterial/CartProductCard";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { CartProductIconText } from "../CartMaterial/CartProductIconText";
import { ArrowRightAltOutlined, CloudCircle, PeopleOutlineOutlined } from "@mui/icons-material";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";
import { CartConstructionProductsTableItemStatus } from "../CartMaterial/CartConstructionProductsTableItemStatus";
import { StatusBooking } from "./objects/backendData/cart/trainBookingStatus";
import { CartProductCardProviderLogo } from "../CartMaterial/CartProductCardProviderLogo";
import { useGetPrice } from "../CartMaterial/utils/getPrice";
import { Currency } from "../../Reducers/objects/currency";
import { TrainCartMainContentLeg } from "./trainCartMainContentLeg";

type TrainCartMainContentProps = {
    type: 'normal';
    train: TrainCartFromBackend;
    sideActions: (minimal?: boolean) => React.ReactNode;
} & Pick<CartProductCardProps, 'alwaysOpen'>

export const TrainCartMainContent: FC<TrainCartMainContentProps> = ({ sideActions, train, type }) => {
    const { t } = useTranslation();
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const context = useContext(CartConstructionProductsContext);
    const user = useSelector((state: AppState) => state.user.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const to_book = useSelector((state: AppState) => state.cart.to_book);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const language = useSelector((state: AppState) => state.header.tmp_language);

    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === train.provider;
        })?.provider;
    }, [train, providers, manualProviders]);


    const checked = useMemo(() => {
        return !!to_book?.find(book_item => book_item.id === train.id);
    }, [train, to_book]);
    const onToggleForBooking = () => { };


    const getPrice = useGetPrice();
    const totalCost = useMemo(() => {
        let total_cost: {
            cost: number,
            currency: Currency | undefined
        }[] = [];
        let matched = false;
        let price = getPrice(train.prices);
        total_cost.map(currency_cost => {
            if (currency_cost.currency?.id === price.currency?.id) {
                matched = true;
                currency_cost.cost += price.cost + parseFloat(train.taxes ?? '0');
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost + parseFloat(train.taxes ?? '0'),
                currency: price.currency
            });
        }
        return total_cost;
    }, [train, user, getPrice]);

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between">
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    {
                        context.enableBooking &&
                        isProductBookable(
                            { type: 'train', item: train },
                            user,
                            trip?.stack_info ?? null,
                            quotationCode
                        ) &&
                        <Checkbox
                            onChange={onToggleForBooking}
                            checked={checked}
                            sx={{
                                padding: 0
                            }}
                            disableRipple
                        />
                    }
                    <CartProductIconText
                        text={
                            [
                                t(
                                    'roadbook.adults-count',
                                    {
                                        count: train.group_passenger?.travelers_list?.filter((traveler) => {
                                            const result = window.moment.utc(tripEndDate).diff(
                                                window.moment.utc(traveler.birth_date),
                                                'years'
                                            );
                                            return result >= 18;
                                        }).length ?? 0
                                    }
                                ),
                                (() => {
                                    const childrenCount = train.group_passenger?.travelers_list?.filter((traveler) => {
                                        const result = window.moment.utc(tripEndDate).diff(
                                            window.moment.utc(traveler.birth_date),
                                            'years'
                                        );
                                        return result < 18;
                                    }).length ?? 0;
                                    return childrenCount > 0 ?
                                        t(
                                            'roadbook.children-count',
                                            { count: childrenCount }
                                        ) :
                                        null;
                                })()
                            ].filter((item) => item).join(', ')
                        }
                        startIcon={PeopleOutlineOutlined}
                    />
                    {
                        (
                            train.booking_status?.last_ticketing_date
                        ) && (
                            <>
                                <Divider orientation="vertical" flexItem />
                                <Typography variant="caption">
                                    {t("cart-material.train-last-ticketing-date")}{' '}
                                    {
                                        window.moment(
                                            train.booking_status?.last_ticketing_date ??
                                            undefined
                                        ).format("L")
                                    }
                                </Typography>
                            </>
                        )
                    }
                    {
                        !isProductPackaged({
                            product: train,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <>
                            <Divider orientation="vertical" flexItem />
                            <CartConstructionProductsTableItemStatus
                                type="train"
                                item={train}
                                bookingProcessState={train.booking_process_state}
                                bookingStatus={train.booking_status}
                                agencyNeedToBook={train.agency_need_to_book}
                            />
                            {
                                train.booking_status?.item_reference &&
                                <Typography fontWeight={100} fontSize="0.75rem">
                                    {
                                        t(
                                            'cart-material.cart-construction-reference',
                                            { ref: train.booking_status.item_reference }
                                        )
                                    }
                                </Typography>
                            }
                        </>
                    }
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        sx={{ marginRight: 2 }}
                    >
                        {
                            !isProductPackaged({
                                product: train,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.logo?.url ??
                                    provider?.logo?.thumbnail_big ??
                                    provider?.logo?.thumbnail_medium ??
                                    provider?.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.name ?? null}
                                height={24}
                            />
                        }
                        {
                            !isProductPackaged({
                                product: train,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                            (
                                user?.client_full?.type !== 2 ||
                                train.creator?.client?.type === user.client_full.type
                            ) &&
                            <Chip
                                label={t('cart-material.flux')}
                                size="small"
                                sx={{
                                    backgroundColor: '#2ACAEA',
                                    color: '#fff'
                                }}
                                icon={<CloudCircle color="inherit" />}
                            />
                        }
                    </Stack>
                    {
                        !isProductPackaged({
                            product: train,
                            stackInfos: trip?.stack_info ?? null
                        }) &&
                        <Typography fontWeight="bold" textAlign="center" position="relative">
                            <div>
                                {
                                    totalCost.map((currency_cost) => {
                                        return new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency',
                                                currency: currency_cost.currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(currency_cost.cost);
                                    }).join(' + ')
                                }
                            </div>
                        </Typography>
                    }
                    {sideActions(true)}
                </Stack>
            </Stack>
            <Box
                sx={{
                    "width": '100%',
                    '& > table tr.train-segment-details:not(:last-child)': {
                        paddingBottom: 1,
                        marginBottom: 2,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
                    }
                }}>
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'left',
                        td: {
                            height: '1px !important'
                        }
                    }}
                    component="table"
                >
                    {
                        train.trip_legs.map((trip_leg, index) => (
                            <TrainCartMainContentLeg
                                key={`trip_leg-${train.id}-${index}`}
                                is_return={index > 0}
                                trip_leg={trip_leg}
                                train={train}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Stack>
    );
}