//Dependencies
import axios from 'axios';
import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    hide: {
        display: 'none'
    }
});

const CartAccommodationManualBooking = ({ accommodation, onManualBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [rooms, setRooms] = useState([]);
    const activateAllStatuses = useMemo(() => {
        return (
            accommodation.rooms?.findIndex((item) => {
                return item.booking_manually_set;
            }) ?? -1
        ) >= 0 ||
        accommodation.is_custom ||
        accommodation.booking_status?.status_booking !== "CONFIRMED";
    }, [accommodation]);
    const onStatusChange = index_room => event => {
        let tmp_rooms = [...rooms];
        let room = { ...tmp_rooms[index_room] };
        room.status = event.target.value;
        tmp_rooms[index_room] = room;
        setRooms(tmp_rooms);
    };
    const onReferenceChange = index_room => event => {
        let tmp_rooms = [...rooms];
        let room = { ...tmp_rooms[index_room] };
        room.reference = event.target.value;
        tmp_rooms[index_room] = room;
        setRooms(tmp_rooms);
    };
    const onValidateManualBooking = () => {
        const { headers } = CheckBeforeRequest();
        rooms.map(room => {
            axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${room.id}/status/`,
                data: {
                    status_booking: room.status === 'DEFAULT' ? null : room.status,
                    item_reference: room.reference
                }
            }).then(response => {
                dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        });
        onManualBooking();
    };
    const onCancelManualBooking = () => {
        onManualBooking();
    };
    useEffect(() => {
        let tmp_rooms = [];
        accommodation.rooms.map(room => {
            tmp_rooms.push({
                id: room.id,
                status: room.booking_status !== null ? room.booking_status.status_booking : 'DEFAULT',
                reference: room.booking_status !== null && room.booking_status.item_reference !== null ? room.booking_status.item_reference : '',
                booking_manually_set: room.booking_manually_set,
                is_manual: room.is_manual,
                is_custom: room.is_custom
            });
        });
        setRooms(tmp_rooms);
    }, [accommodation]);
    return (
        <Grid container spacing={ 4 }>
            {
                rooms.map((room, index_room) => (
                    <Fragment key={ `room-manual-booking-${room.id}` }>
                        <Grid item xs={ 12 }>
                            <Typography>{ accommodation.hotel && accommodation.hotel[0] && accommodation.hotel[0].type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('global.room') } : { index_room + 1 }</Typography>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControl fullWidth variant={ 'outlined' }>
                                <InputLabel htmlFor={ 'status' }>{ t('cart-material.status') }</InputLabel>
                                <Select id={ 'status' } value={ room.status } label={ t('cart-material.status') } onChange={ onStatusChange(index_room) }>
                                    <MenuItem
                                        value={ 'DEFAULT' }
                                        className={
                                            clsx({
                                                [classes.hide]: !activateAllStatuses ||
                                                                (
                                                                    room.booking_manually_set &&
                                                                    !room.is_manual &&
                                                                    !room.is_custom
                                                                )
                                            })
                                        }
                                    >
                                        { t('cart-material.none') }
                                    </MenuItem>
                                    <MenuItem value={ 'CONFIRMED' } className={clsx({ [classes.hide]: !activateAllStatuses })}>{ t('cart-material.confirmed') }</MenuItem>
                                    <MenuItem
                                        value={ 'WAITING' }
                                        className={
                                            clsx({
                                                [classes.hide]: !activateAllStatuses ||
                                                                (
                                                                    room.booking_manually_set &&
                                                                    !room.is_manual &&
                                                                    !room.is_custom
                                                                )
                                            })
                                        }
                                    >
                                        { t('cart-material.pending') }
                                    </MenuItem>
                                    <MenuItem
                                        value={ 'ERROR' }
                                        className={
                                            clsx({
                                                [classes.hide]: !activateAllStatuses ||
                                                                (
                                                                    room.booking_manually_set &&
                                                                    !room.is_manual &&
                                                                    !room.is_custom
                                                                )
                                            })
                                        }
                                    >
                                        { t('cart-material.error') }
                                    </MenuItem>
                                    <MenuItem
                                        value={ 'REFUSED' }
                                        className={
                                            clsx({
                                                [classes.hide]: !activateAllStatuses ||
                                                                (
                                                                    room.booking_manually_set &&
                                                                    !room.is_manual &&
                                                                    !room.is_custom
                                                                )
                                            })
                                        }
                                    >
                                        { t("cart-material.refused")}
                                    </MenuItem>
                                    <MenuItem value="CANCELLED">{ t('cart-material.cancelled') }</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControl fullWidth variant={ 'outlined' }>
                                <InputLabel htmlFor={ 'note' }>{ t('cart-material.reference') }</InputLabel>
                                <OutlinedInput
                                    id={ 'note' }
                                    type={ 'text' }
                                    onChange={ onReferenceChange(index_room) }
                                    label={ t('cart-material.reference') }
                                    value={ room.reference }
                                />
                            </FormControl>
                        </Grid>
                    </Fragment>
                ))
            }
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ marginTop: 2 }}>
                    <Button onClick={ onCancelManualBooking }>{ t('global.cancel') }</Button>
                    <Button onClick={ onValidateManualBooking }>{ t('global.validate') }</Button>
                </Stack>
            </Grid>
            <br/>
        </Grid>
    );
};

export default CartAccommodationManualBooking;
