//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef, useMemo, useContext } from 'react';
import { EditorState } from 'draft-js';
//Core
import { Alert, AlertTitle, Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Collapse from '@material-ui/core/Collapse';
import green from '@material-ui/core/colors/green';
//Icons
import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';

import DOMPurify from "dompurify";

//Components
import CartRoom from './CartRoom';
import CartAccommodationNote from './CartAccommodationNote';
import CartAccommodationManualBooking from './CartAccommodationManualBooking';
import CartAccommodationRemarkBooking from './CartAccommodationRemarkBooking';
import CartAccommodationManualCancellationRules from './CartAccommodationManualCancellationRules';
import CartAccommodationManualCancellationRulesCustom from './CartAccommodationManualCancellationRulesCustom';
import CartDeleteModal from './CartDeleteModal';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionReplaceProductModal } from './CartConstructionReplaceProductModal';
import { LoadingBackDrop } from '../Common/LoadingBackdrop';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
import RichEditor, { RichEditorStyleMap } from '../Menu/MaterialTripList/utils/editor/editor';

//Common
import { useShowError } from '../Utils/showError';
import { useCartProductUpdate } from './network/cartProductUpdate';
import { useCartProducts } from '../Itinerary/network/cartProducts';
import { findHotel } from '../Menu/MaterialTripList/utils/findHotel';
import { htmlHasEmptyContent } from '../Menu/MaterialTripList/utils/htmlHasEmptyContent';
import { renderRichEditorContentHTML } from '../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { extractHotelDescription } from './utils/extractHotelDescription';
import { isProductPackaged } from './utils/isProductPackaged';
import { createDomObjectsFromHtml } from '../Menu/MaterialTripList/MailVisualEditor/utils/dom';
import { fixNodeEmptyContent } from '../Menu/MaterialTripList/MailVisualEditor/utils/fixNodeEmptyContent';
import { customColors } from '../Menu/MaterialTripList/utils/editor/customColors';
import { ProviderContext } from './utils/providerContext';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';

//Actions
import { AppState } from '../../Reducers/Reducers';
import { AccommodationCart } from '../Itinerary/objects/accommodationCart';
import { TravelerGroup } from '../../Reducers/objects/travelerGroup';
import { Provider } from '../Common/objects/provider';
import { Currency } from '../../Reducers/objects/currency';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { CartProductStatus } from './CartConstructionProductsTableItemMenu';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { Hotel } from '../Menu/MaterialTripList/objects/Hotel';
import { useDynamicTags } from '../Accommodation/hooks/dynamicTags';
import { DynamicIcon } from '../Common/dynamicIcon';

type Props = {
    accommodation: AccommodationCart,
    onChangeOpenMargin: React.Dispatch<React.SetStateAction<boolean>>
}

const EmptyEditorState = EditorState.createEmpty();

export const CartAccommodationCardDetails = withRouter<Props>(
    function CartAccommodationCardDetails({
        accommodation,
        router,
        onChangeOpenMargin
    }): JSX.Element {
        const versionString = GetCookie("trip_id_version");
        const version = versionString ? parseInt(versionString) : -1;
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();

        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const user = useSelector((state: AppState) => state.user.user);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const tripId = useSelector((state: AppState) => state.trip.trip_id);
        const data_trip = useSelector((state: AppState) => state.trip.data_trip);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const providers = useSelector((state: AppState) => state.trip.providers);
        const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
        const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
        const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);

        const [groups, setGroups] = useState<TravelerGroup[]>([]);
        const [showNote, setShowNote] = useState(false);
        const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
        const [showManualBooking, setShowManualBooking] = useState(false);
        const [remarkBooking, setShowRemarkBooking] = useState(false);
        const [references, setReferences] = useState('');
        const [catalogPrice, setCatalogPrice] = useState(false);
        const [resortFees, setResortFees] = useState<{
            cost: number,
            cost_currency: Currency | null,
            converted_cost: number,
            converted_currency: Currency | null
        } | null>(null);
        const [margin, setMargin] = useState<{
            id?: number[],
            ids?: {
                room_id: number,
                price_id: number
            }[],
            rate_type: 'PER' | 'FIX',
            purchase: number,
            purchase_converted: number,
            purchase_currency?: Currency,
            factor_used: string,
            value: number,
            static_value?: number,
            markup: boolean,
            percent: number,
            static_percent?: number,
            selling: number,
            selling_currency?: Currency,
            custom_rate?: boolean,
            custom_rate_type?: string
        } | null>(null);
        const [surcom, setSurcom] = useState<{
            value?: number,
            static?: number,
            purchase: number,
            selling: number,
            percent: number,
            currency: Currency | null
        } | null>(null);
        const [provider, setProvider] = useState<Provider | null>(null);
        const [expanded, setExpanded] = useState(true);
        const [deleteModal, setDeleteModal] = useState(false);
        const [cancellationChargedAmount, setCancellationChargedAmount] = useState<number | null>(null);
        const [marginValue, setMarginValue] = useState<number | null>(null);
        const [marginPercent, setMarginPercent] = useState<number | null>(null);
        const [openReplaceModal, setOpenReplaceModal] = useState(false);
        const [loading, setLoading] = useState(false);
        const [editDescription, setEditDescription] = useState(false);
        const [descriptionEditorState, setDescriptionEditorState] = useState(EmptyEditorState);
        const [hotel, setHotel] = useState<Hotel | null>(null);
        const cart = useCartProducts();
        const accommodations = useMemo(() => {
            return cart.accommodations.map((item) => {
                return {
                    ...item.accommodation,
                    is_manual: item.type === 'manual'
                };
            }).filter((item) => {
                return item.is_displayed;
            });
        }, [cart.accommodations]);
        const status = useMemo(() => {
            let status: CartProductStatus = 'DEFAULT';

            if (accommodation.is_optional) {
                status = 'OPTION';
            } else if (accommodation.is_hidden_for_traveler) {
                status = 'HIDE';
            } else if (accommodation.is_hidden_for_traveler_count_price) {
                status = 'HIDE_COUNT';
            } else if (accommodation.variant && itinerary_type_details_data && itinerary_type_details_data.variant_name) {
                status = 'VARIANT';
            }
            return status;
        }, [accommodation, itinerary_type_details_data]);
        const hotelDescription = useMemo(() => {
            const description = extractHotelDescription({
                locale,
                description: null,
                localization: accommodation.localization,
                hotelLocalization: accommodation.hotel[0]?.localization ?? null,
                hoteldefaultTexts: {
                    situation: accommodation.hotel[0]?.situation ?? null,
                    room_desc: accommodation.hotel[0]?.room_desc ?? null,
                    restoration: accommodation.hotel[0]?.restoration ?? null,
                    children: accommodation.hotel[0]?.children ?? null
                },
                hotel
            });

            const { document } = createDomObjectsFromHtml(description);
            fixNodeEmptyContent(document.body);

            return document.body.innerHTML;
        }, [accommodation, locale, hotel]);
        const tags = useDynamicTags();
        const showError = useShowError();
        const update = useCartProductUpdate({
            onTrigger() {
                setLoading(true);
            },
            onSuccess() {
                setEditDescription(false);
            },
            onError(error) {
                console.error(error);
                showError(error);
            },
            onFinally() {
                setLoading(false);
            }
        });
        const providerContext = useContext(ProviderContext);

        const manualBookingRef = useRef<HTMLDivElement>(null);

        const isDisabled = quotation_code === "cercledesvoyages" ?
            (
                data_trip &&
                data_trip.status_contract === "CONFIRMED" &&
                data_trip.status_modification === "FIXED_PV"
            ) :
            false;

        const scrollIntoManuaBookingView = () => {
            manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        };
        const onManualCancellationRules = () => {
            setShowManualCancellationRules(!showManualCancellationRules);
        };
        const onDeleteManualCancellationRules = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        no_refund: true,
                        cancel_condition_text: null
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                    setShowManualCancellationRules(!showManualCancellationRules);
                }).catch(error => {
                    console.log(error);
                    setShowManualCancellationRules(!showManualCancellationRules);
                });
            });
        };
        const onDelete = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map((room, index_room) => {
                if (room.booking_status && room.booking_status.status_booking === "CANCELLED") {
                    axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                        data: {
                            is_displayed: false
                        }
                    }).then((response) => {
                        dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                        enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                    }).catch(error => {
                        console.log(error);
                        enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                    });
                } else {
                    axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
                    }).then(() => {
                        dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                        enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                    }).catch(error => {
                        console.log(error);
                        enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                    });
                }
            });
        };

        const onSearchMissingRooms = () => {
            let current_groups: TravelerGroup[] = [];
            let traveler_number = 0;
            groups.map(group => {
                current_groups.push(group);
                traveler_number += group.travelers.length;
            });
            dispatch({
                type: 'ACCOMMODATION_GO_TO_CARD',
                payload: {
                    start_date: accommodation.start_date,
                    end_date: accommodation.end_date,
                    current_groups: current_groups,
                    traveler_number: traveler_number
                }
            });
            router.push(`/${window.url_name}/apps/accommodation-card/${accommodation.hotel[0]?.id}`);
        };

        const onRemarkBooking = () => {
            setShowRemarkBooking(!remarkBooking);
        };

        const onManualBooking = () => {
            setShowManualBooking(!showManualBooking);
        };

        const onAddNote = () => {
            setShowNote(!showNote);
        };

        const onDeleteNote = () => {
            const { headers } = CheckBeforeRequest();
            let has_notified = false;
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        custom_information: null
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                    if (!has_notified) {
                        has_notified = true;
                        enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (!has_notified) {
                        has_notified = true;
                        enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
                    }
                });
            });
        };

        const onEditCatalogPrice = () => {
            if (user?.client_full?.type !== 2 && (catalogPrice || (!accommodation.is_available && (provider === null || provider.provider.custom)))) {
                onChangeOpenMargin((state) => !state);
            }
        };

        const priceCurrencyConverter = (
            first_price: number,
            first_currency: Currency | undefined,
            second_currency: Currency
        ) => {
            if (first_currency?.iso_code === second_currency.iso_code) {
                return first_price;
            }
            return (first_currency?.iso_code !== 'USD' ? first_price / (first_currency?.usd_factor ?? 1) : first_price) * second_currency.usd_factor;
        };

        const priceToDisplay = (price: ProductPrice) => {
            return {
                cost: parseFloat(price.selling_price),
                currency: currency_list.find(currency => currency.id === price.selling_currency)
            };
        };

        const getPrice = (prices: ProductPrice[]) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            }
            return {
                cost: 0,
                currency: currency
            };
        };

        const getMargin = (
            tmp_margin: NonNullable<typeof margin>,
            tmp_surcom: NonNullable<typeof surcom>,
            prices: ProductPrice[],
            id: number
        ) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                        tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id?.push(id);
                        tmp_margin.ids?.push({ price_id: prices[i]!.id, room_id: id });
                        break;
                    }
                }
                if (!tmp_margin.id?.includes(id)) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]!.owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                            tmp_margin.factor_used = prices[i]!.factor_used;
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                            tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                            tmp_margin.custom_rate = prices[i]!.custom_rate;
                            tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                            tmp_margin.id?.push(id);
                            tmp_margin.ids?.push({ price_id: prices[i]!.id, room_id: id });
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.id?.push(id);
                        tmp_margin.ids?.push({ price_id: prices[i]!.id, room_id: id });
                        break;
                    }
                }
            }
        };

        const confirmRemarkBookingRecalc = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        moved_with_recalc_remark_booking: false
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch(error => {
                    console.log(error);
                });
            });
        };

        const confirmCustomInformationRecalc = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        moved_with_recalc_custom_information: false
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch(error => {
                    console.log(error);
                });
            });
        };

        const confirmCancelConditionRecalc = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        moved_with_recalc_cancel_condition_text: false
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch(error => {
                    console.log(error);
                });
            });
        };

        const confirmHotelRecalc = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.map(room => {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        before_recalc_hotel_name: null
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch(error => {
                    console.log(error);
                });
            });
        };

        const onDeleteAccommodation = () => {
            const { headers } = CheckBeforeRequest();
            accommodation.rooms.slice(1).map((room) => {
                if (room.booking_status && room.booking_status.status_booking === "CANCELLED") {
                    axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                        data: {
                            is_displayed: false
                        }
                    }).then((response) => {
                        dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
                    }).then(() => {
                        dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        };

        const onReplaceAll = () => {
            setOpenReplaceModal(true);
            const groupPassengers = accommodation.rooms.map((room) => {
                return room.group_passenger;
            });
            dispatch({
                type: 'ACCOMMODATION_REPLACE_ROOM',
                payload: accommodation.rooms[0]
            });
            dispatch({
                type: 'MATERIAL_CHANGE_CURRENT_GROUPS',
                payload: groupPassengers
            });
        };

        const onSaveDescription = async () => {
            const localization = accommodation.localization.find((item) => {
                return item.locale === locale;
            });
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            const description = renderRichEditorContentHTML(
                styles,
                descriptionEditorState.getCurrentContent()
            );
            await update(
                version,
                {
                    id: accommodation.id,
                    type: 'accommodation',
                    roomIds: accommodation.rooms.map((room) => {
                        return room.id;
                    }),
                    isCustom: accommodation.is_custom
                },
                {
                    localization: accommodation.localization.filter((item) => {
                        return item.locale !== locale;
                    }).concat([
                        localization ?
                            { ...localization, description } :
                            {
                                locale,
                                name: accommodation.hotel[0]?.name ?? '',
                                description,
                                long_description: null,
                                cancel_text_condition: ''
                            }
                    ])
                }
            );
        };

        useEffect(() => {
            if (marginValue && marginValue !== margin?.value) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.value = marginValue;
                    tmp_margin.rate_type = 'FIX';
                    if (tmp_margin.markup) {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / (tmp_margin.purchase_converted ?? tmp_margin.value)) * 100).toFixed(2));
                    } else {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / (tmp_margin.selling ?? tmp_margin.value)) * 100).toFixed(2));
                    }
                    setMarginPercent(tmp_margin.percent);
                    setMargin(tmp_margin);
                }
            }
        }, [marginValue]);

        useEffect(() => {
            if (marginPercent && marginPercent !== margin?.percent) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.percent = marginPercent;
                    tmp_margin.rate_type = 'PER';
                    if (tmp_margin.markup) {
                        tmp_margin.value = parseFloat(((tmp_margin.purchase_converted ?? 0) * (tmp_margin.percent / 100)).toFixed(2));
                    } else {
                        tmp_margin.value = parseFloat(((tmp_margin.selling ?? 0) * (tmp_margin.percent / 100)).toFixed(2));
                    }
                    setMarginValue(tmp_margin.value);
                    setMargin(tmp_margin);
                }
            }
        }, [marginPercent]);

        useEffect(() => {
            if (showNote) {
                const element = document.getElementById(`accommodation-${accommodation.id}-note`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            if (showManualCancellationRules) {
                const element = document.getElementById(`accommodation-${accommodation.id}-manual-cancellation-rules`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            let resort_fees: {
                cost: number,
                cost_currency: Currency | null,
                converted_cost: number,
                converted_currency: Currency | null
            } | null = null;
            let total_cost: {
                cost: number,
                currency?: Currency
            }[] = [];
            let catalog_price = false;
            let references = '';
            let references_count = 0;
            let cancellation_charged_amount = 0;
            let tmp_margin: NonNullable<typeof margin> = {
                factor_used: '0',
                markup: false,
                purchase: 0,
                purchase_converted: 0,
                selling: 0,
                value: 0,
                percent: 0,
                rate_type: 'PER',
                id: [],
                ids: []
            };
            let tmp_surcom: NonNullable<typeof surcom> = {
                purchase: 0,
                selling: 0,
                value: 0,
                percent: 0,
                currency: null
            };
            let tmp_groups: TravelerGroup[] = [];
            let tmp_travelers: number[] = [];
            if (!((accommodation.booking_status && accommodation.booking_status.status_booking === 'CANCELLED') || accommodation.booking_process_state === 'CANCEL')) {
                for (let i = 0; i < traveler_groups.length; i++) {
                    if (traveler_groups[i]!.default) {
                        tmp_groups.push({ ...traveler_groups[i]! });
                        tmp_travelers = tmp_travelers.concat(traveler_groups[i]!.travelers);
                    }
                }
            }
            accommodation.rooms.map(room => {
                if (room.cancellation_charged_amount) {
                    cancellation_charged_amount += parseFloat(room.cancellation_charged_amount);
                }
                for (let i = 0; i < tmp_groups.length; i++) {
                    if (tmp_groups[i]!.id === room.group_passenger.id) {
                        tmp_groups.splice(i, 1);
                        break;
                    }
                }
                for (let i = 0; i < room.group_passenger.travelers.length; i++) {
                    if (tmp_travelers.includes(room.group_passenger.travelers[i]!)) {
                        tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger.travelers[i]!), 1);
                    }
                }
                if (tmp_travelers.length === 0) {
                    tmp_groups = [];
                }
                if (room.catalog_price || room.cataloged_price) {
                    catalog_price = true;
                }
                if (room.booking_status && room.booking_status.item_reference && room.booking_status.item_reference !== '' && references_count <= 3) {
                    if (references_count === 3) {
                        references += '...';
                        references_count++;
                    } else if (references_count < 3) {
                        if (references_count > 0) {
                            references += ', ';
                        }
                        references += room.booking_status.item_reference;
                        references_count++;
                    }
                }
                let price = getPrice(room.prices);
                getMargin(tmp_margin, tmp_surcom, room.prices, room.id);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
                if (!room.resort_fees_included && room.resort_fees && room.resort_fees !== '0.00') {
                    if (!resort_fees) {
                        let resort_fees_currency = currency_list.find(currency => currency.id === room.resort_fees_currency_id);
                        resort_fees = {
                            cost: parseFloat(room.resort_fees),
                            cost_currency: room.resort_fees_currency_id ? resort_fees_currency ?? null : null,
                            converted_cost: room.resort_fees_currency_id ? priceCurrencyConverter(parseFloat(room.resort_fees), resort_fees_currency, currency) : parseFloat(room.resort_fees),
                            converted_currency: currency
                        };
                    } else {
                        resort_fees.cost += parseFloat(room.resort_fees);
                        resort_fees.converted_cost += resort_fees.cost_currency ? priceCurrencyConverter(parseFloat(room.resort_fees), resort_fees.cost_currency, currency) : parseFloat(room.resort_fees);
                    }
                }
            });
            accommodations.map((other_accommodation) => {
                if (other_accommodation.id !== accommodation.id && !other_accommodation.is_manual) {
                    if (
                        other_accommodation.is_displayed &&
                        accommodation.hotel[0]?.id === (other_accommodation as AccommodationCart).hotel[0]?.id &&
                        accommodation.start_date === other_accommodation.start_date &&
                        accommodation.end_date === other_accommodation.end_date &&
                        accommodation.on_request === other_accommodation.on_request &&
                        accommodation.is_optional === other_accommodation.is_optional &&
                        accommodation.variant === other_accommodation.variant &&
                        (
                            (accommodation.booking_status === null && other_accommodation.booking_status === null) ||
                            (
                                accommodation.booking_status &&
                                other_accommodation.booking_status &&
                                (
                                    (
                                        accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                        other_accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                        accommodation.booking_process_state !== 'CANCEL' &&
                                        other_accommodation.booking_process_state !== 'CANCEL'
                                    ) ||
                                    (
                                        accommodation.booking_status.status_booking === 'CANCELLED' &&
                                        other_accommodation.booking_status.status_booking === 'CANCELLED'
                                    )
                                )
                            )
                        )
                    ) {
                        other_accommodation.rooms?.map(room => {
                            for (let i = 0; i < tmp_groups.length; i++) {
                                if (tmp_groups[i]!.id === room.group_passenger?.id) {
                                    tmp_groups.splice(i, 1);
                                    break;
                                }
                            }
                            for (let i = 0; i < (room.group_passenger?.travelers.length ?? 0); i++) {
                                if (tmp_travelers.includes(room.group_passenger?.travelers[i]!)) {
                                    tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger?.travelers[i]!), 1);
                                }
                            }
                            if (tmp_travelers.length === 0) {
                                tmp_groups = [];
                            }
                        });
                    }
                }
            });
            let tmp_provider = null;
            for (let i = 0; i < providers.length; i++) {
                if (providers[i]!.provider.id === accommodation.provider) {
                    tmp_provider = providers[i];
                }
            }
            for (let i = 0; i < manual_providers.length; i++) {
                if (manual_providers[i]!.provider.id === accommodation.provider) {
                    tmp_provider = manual_providers[i];
                }
            }
            /*if (resort_fees) {
                resort_fees.cost = resort_fees.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                resort_fees.converted_cost = resort_fees.converted_cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            } */
            /*total_cost.map((currency_cost) => {
                currency_cost.cost = currency_cost.cost.toLocaleString(
                    language,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )
            }); */
            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(
                (
                    tmp_margin.percent / accommodation.rooms.length
                ) - (
                    100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100
                )
            ) > Math.abs(
                (
                    tmp_margin.percent / accommodation.rooms.length
                ) - (
                    (tmp_margin.value / tmp_margin.purchase_converted) * 100
                )
            );
            tmp_margin.percent = tmp_margin.percent / accommodation.rooms.length;
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_margin.custom_rate = tmp_margin.custom_rate;
            tmp_surcom.static = tmp_surcom.value;
            tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
            tmp_surcom.percent = tmp_surcom.percent / accommodation.rooms.length;
            setMargin(tmp_margin);
            setSurcom(tmp_surcom);
            setGroups(tmp_groups);
            setCatalogPrice(catalog_price);
            setResortFees(resort_fees);
            setProvider(tmp_provider ?? null);
            setReferences(references);
            setCancellationChargedAmount(cancellation_charged_amount !== 0 ? cancellation_charged_amount : null);
        }, [providers, manual_providers, accommodation]);

        useEffect(() => {
            if (showManualBooking) {
                scrollIntoManuaBookingView();
            }
        });

        useEffect(() => {
            if (
                tripId &&
                accommodation.hotel[0]?.id &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.situation ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.room_desc ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.restoration ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.children ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].situation ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].room_desc ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].restoration ?? ''
                ) &&
                htmlHasEmptyContent(
                    accommodation.hotel[0].children ?? ''
                )
            ) {
                (async () => {
                    setHotel(
                        await findHotel(
                            accommodation.hotel[0]!.id,
                            tripId
                        )
                    );
                })();
            }
        }, [accommodation, tripId, locale]);

        useEffect(() => {
            if (editDescription) {
                const styles = {
                    ...RichEditorStyleMap,
                    ...(quotation_code ? customColors[quotation_code] : null)
                };
                setDescriptionEditorState(
                    restoreRichEditorStateFromHtml(
                        styles,
                        hotelDescription
                    )
                );
            }
        }, [editDescription, hotelDescription]);

        return (
            <>
                <Grid
                    id={`product-${accommodation.id}`}
                    className={classes.scrollMargin}
                    container
                    item
                    xs
                    direction={"column"}
                    spacing={2}
                >
                    {
                        (accommodation.hotel[0]?.tags?.length ?? 0) > 0 &&
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {
                                    accommodation.hotel[0]?.tags?.map((tagId) => {
                                        const tag = tags.find((item) => {
                                            return item.id === tagId;
                                        });
                                        if (tag) {
                                            return (
                                                <Chip
                                                    key={tagId}
                                                    avatar={
                                                        tag.icon ?
                                                            <Avatar>
                                                                <DynamicIcon iconName={tag.icon} />
                                                            </Avatar> :
                                                            undefined
                                                    }
                                                    label={
                                                        tag.localization.find((item) => {
                                                            return item.locale === locale;
                                                        })?.name ??
                                                        tag.name
                                                    }
                                                />
                                            );
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item xs={12}>
                                    <CartConstructionProviderQuotationStatusLayout
                                        status={
                                            <CartConstructionProductsTableItemProviderQuotationStatus
                                                item={accommodation}
                                            />
                                        }
                                        buttons={
                                            <CartPackageItemChangeProviderQuotationStatusButtons
                                                item={{
                                                    ...accommodation,
                                                    stackNumber: accommodation.stack_number,
                                                    providerId: accommodation.provider,
                                                    type: 'accommodations',
                                                    isCustom: accommodation.is_custom
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            }
                            <Grid item>
                                <Grid container direction={"row-reverse"} spacing={4}>
                                    {
                                        user?.client_full?.type !== 2 &&
                                        <Fragment>
                                            {
                                                accommodation.before_recalc_hotel_name &&
                                                <Grid item>
                                                    <Tooltip title={t('cart-material.recalc-change-hotel-name', { name: accommodation.before_recalc_hotel_name })}>
                                                        <div
                                                            className={clsx(classes.traverlerModification)}
                                                            onClick={confirmHotelRecalc}
                                                            style={{ fontSize: 13, cursor: 'pointer', color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                        >
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t('cart-material.recalc-change-hotel')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                        </Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs>
                            <div>
                                {
                                    accommodation.rooms.map((room, room_index) => (
                                        <CartRoom
                                            key={`room-${room.id}`}
                                            room={room}
                                            room_index={room_index}
                                            provider={provider}
                                            accommodation={accommodation}
                                            showProduct
                                        />
                                    ))
                                }
                                {
                                    !providerContext.module &&
                                    (
                                        accommodation.booking_status?.status_booking !== StatusBooking.CONFIRMED ||
                                        accommodation.is_custom
                                    ) &&
                                    <Button
                                        color="inherit"
                                        sx={{
                                            textDecoration: 'underline',
                                            textTransform: 'none',
                                            marginBottom: 2
                                        }}
                                        onClick={onReplaceAll}
                                    >
                                        {accommodation.hotel && accommodation.hotel[0] && accommodation.hotel[0].type === 5 ? t('cart-material.replace-all-cabins') : t('cart-material.replace-all-rooms')}
                                    </Button>
                                }
                                {
                                    accommodation.rooms.map((room, room_index) => {
                                        if (!!room.additional_information) {
                                            return (
                                                <Grid key={`room-additional-information-${room.id}`} item xs={12}>
                                                    <Accordion className={classes.additionalInformation}>
                                                        <AccordionSummary expandIcon={<ExpandMore className={classes.additionalInformationExpandIcon} />}>
                                                            <InfoOutlined className={classes.additionalInformationInfoIcon} /><Typography>{t('cart-material.additional-information')} :</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                <strong>{t('global.room')} {room_index + 1} :</strong>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: DOMPurify.sanitize(room.additional_information)
                                                                    }}
                                                                />
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                                <Grid item xs={12}>
                                    {
                                        !providerContext.module &&
                                        !editDescription &&
                                        <Box sx={{ marginBottom: 3 }}>
                                            <Box
                                                className={classes.containerMargin}
                                                sx={{
                                                    '& p': {
                                                        marginTop: '0 !important',
                                                        marginBottom: '0 !important'
                                                    }
                                                }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: hotelDescription
                                                    }}
                                                />
                                            </Box>
                                            {
                                                (
                                                    quotation_code !== "visiteurs" ||
                                                    user?.client_full?.type !== 2
                                                ) &&
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Typography
                                                        sx={{
                                                            textDecoration: 'underline',
                                                            pointer: 'cursor',
                                                            cursor: 'pointer',
                                                            fontSize: '1rem',
                                                            display: 'inline'
                                                        }}
                                                        onClick={() => setEditDescription(true)}
                                                    >
                                                        {t("cart-material.edit-description")}
                                                    </Typography>
                                                </Box>
                                            }
                                        </Box>
                                    }
                                    {
                                        editDescription &&
                                        <Box sx={{ marginBottom: 3 }}>
                                            <RichEditor
                                                values={{}}
                                                pictures={null}
                                                editorState={descriptionEditorState}
                                                setEditorState={setDescriptionEditorState}
                                                controlledState
                                            />
                                            <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                                                <Button onClick={() => setEditDescription(false)}>
                                                    {t('shared.cancel')}
                                                </Button>
                                                <Button onClick={onSaveDescription}>
                                                    {t('shared.save')}
                                                </Button>
                                            </Stack>
                                        </Box>
                                    }
                                </Grid>
                                {
                                    resortFees &&
                                    <Alert
                                        className={classes.resortFees}
                                        classes={{ icon: classes.alertIcon }}
                                        variant={'filled'}
                                        severity={'warning'}
                                    >
                                        {t('cart-material.resort-fees-1')} {resortFees.cost}{resortFees.cost_currency ? resortFees.cost_currency.symbol : ''}{' '}
                                        (
                                        {t('cart-material.resort-fees-2')}{' '}
                                        {resortFees.converted_cost}{resortFees.converted_currency ? resortFees.converted_currency.symbol : ''}
                                        ) {t('cart-material.resort-fees-3')}
                                    </Alert>
                                }
                                {
                                    resortFees === null && accommodation.resort_fees_included &&
                                    <Alert
                                        className={classes.resortFees}
                                        classes={{ icon: classes.alertIcon }}
                                        variant={'filled'}
                                        severity={'warning'}
                                    >
                                        {t('cart.resort_fees_text_included_1_v1')}
                                    </Alert>
                                }
                                {
                                    !!accommodation.resort_fees_text && accommodation.rooms.length === 1 &&
                                    <Alert
                                        className={classes.resortFees}
                                        classes={{ icon: classes.alertIcon }}
                                        variant={'filled'}
                                        severity={'warning'}
                                    >
                                        {accommodation.resort_fees_text}
                                    </Alert>
                                }
                                {
                                    !accommodation.is_available && (provider === null || !provider.provider.custom) &&
                                    <Typography className={clsx(classes.price, classes.unavailable)}>
                                        {t('cart-material.product-unavailable')}
                                    </Typography>
                                }
                                {
                                    (
                                        !isProductPackaged({
                                            product: accommodation,
                                            stackInfos: data_trip?.stack_info ?? null
                                        }) ||
                                        ['verdie'].includes(quotation_code)
                                    ) &&
                                    accommodation.catalog_price &&
                                    <Typography
                                        className={
                                            clsx(
                                                classes.price,
                                                {
                                                    [classes.priceUnavailable]: !accommodation.is_available && (provider === null || !provider.provider.custom),
                                                    [classes.catalogedPrice]: catalogPrice
                                                }
                                            )
                                        }
                                        onClick={onEditCatalogPrice}
                                    >
                                        {t('cart-material.catalog-price')}
                                    </Typography>
                                }
                                {
                                    user?.client_full?.type !== 2 &&
                                    (catalogPrice || (!accommodation.is_available && (provider === null || provider.provider.custom))) &&
                                    !isProductPackaged({
                                        product: accommodation,
                                        stackInfos: data_trip?.stack_info ?? null
                                    }) &&
                                    <Typography className={classes.catalogedPrice} onClick={onEditCatalogPrice}>
                                        {t('cart-material.cataloged-price')}
                                    </Typography>
                                }
                                {
                                    accommodation.rooms.length === 1 && (
                                        <Fragment>
                                            {
                                                accommodation.booking_status && accommodation.booking_status.status_booking === 'CANCELLED' && cancellationChargedAmount &&
                                                <Typography className={clsx(classes.booking, classes.error)}>
                                                    {t('flight_search.cancel_fee')} : {
                                                        new Intl.NumberFormat(
                                                            language,
                                                            {
                                                                style: 'currency',
                                                                currency: currency_list.find((currency) => {
                                                                    return currency.id === accommodation.prices[GetPricesId(accommodation.prices, user?.client, user, true)]?.purchase_currency;
                                                                })?.iso_code ?? 'EUR',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }
                                                        ).format(cancellationChargedAmount)}</Typography>
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    accommodation.rooms.length > 1 &&
                                    <Typography>
                                        {
                                            accommodation.rooms.map((room) => {
                                                return (
                                                    <div key={room.id} style={{ fontWeight: 700 }}>
                                                        {
                                                            room.booking_status && room.booking_status.status_booking === 'CANCELLED' && cancellationChargedAmount &&
                                                            <span>
                                                                {t('flight_search.cancel_fee')} :{' '}
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: currency_list.find((currency) => {
                                                                                return currency.id === room.prices[GetPricesId(room.prices, user?.client, user, true)]?.purchase_currency;
                                                                            })?.iso_code ?? 'EUR',
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(cancellationChargedAmount)}
                                                            </span>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                    </Typography>
                                }
                            </div>
                        </Grid>
                        {
                            accommodation.rooms.map(room => {
                                if (room.booking_status && room.booking_status.status_booking === 'WAITING' && room.booking_status.async_fetch_required) {
                                    return (
                                        <Grid item xs={12}>
                                            <Alert
                                                className={classes.errorAlert}
                                                classes={{ icon: classes.alertIcon }}
                                                variant={'filled'}
                                                severity={'warning'}
                                                sx={(theme) => ({
                                                    [theme.breakpoints.up('sm')]: {
                                                        maxWidth: '50%'
                                                    }
                                                })}
                                            >
                                                {t('cart-material.expedia-booking-waiting')}
                                            </Alert>
                                        </Grid>
                                    );
                                }
                            })
                        }
                        {
                            accommodation.booking_status && accommodation.booking_status.status_booking === 'UNAVAILABLE' &&
                            <Grid item xs={12}>
                                <Alert
                                    className={classes.errorAlert}
                                    classes={{ icon: classes.alertIcon }}
                                    variant={'filled'}
                                    severity={'error'}
                                >
                                    {t('cart-material.booking-unavailable')} : {references}
                                </Alert>
                            </Grid>
                        }
                        {
                            accommodation.booking_status &&
                            (accommodation.booking_status.status_booking === 'ERROR' || accommodation.booking_status.status_booking === 'UNAVAILABLE') &&
                            accommodation.booking_errors && accommodation.booking_errors.length > 0 &&
                            <Grid item xs={12}>
                                <Alert
                                    className={classes.errorAlert}
                                    classes={{ icon: classes.alertIcon }}
                                    variant={'filled'}
                                    severity={'error'}
                                >
                                    <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                                    {accommodation.booking_errors.map(error => <Typography key={`booking-error-${error.created_date}`}>{window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}</Typography>)}
                                </Alert>
                            </Grid>
                        }
                        {
                            groups.length > 0 &&
                            <Grid item xs={12}>
                                <Alert
                                    variant={'filled'}
                                    severity={'warning'}
                                    action={
                                        <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onSearchMissingRooms}>
                                            {t('cart-material.search-missing-rooms')}
                                        </Button>
                                    }
                                >
                                    {t('cart-material.search-missing-rooms-info')}
                                </Alert>
                            </Grid>
                        }
                        {
                            remarkBooking &&
                            <Grid item xs={12}>
                                <CartAccommodationRemarkBooking accommodation={accommodation} provider={provider} onRemarkBooking={onRemarkBooking} />
                            </Grid>
                        }
                        {
                            showManualBooking &&
                            <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                                <CartAccommodationManualBooking accommodation={accommodation} onManualBooking={onManualBooking} />
                            </Grid>
                        }
                        {
                            !providerContext.module &&
                            <>
                                {
                                    showNote ? (
                                        <Grid id={`accommodation-${accommodation.id}-note`} item xs={12}>
                                            <CartAccommodationNote accommodation={accommodation} onAddNote={onAddNote} />
                                        </Grid>
                                    ) : accommodation.custom_information && (
                                        <Grid item xs={12}>
                                            <Alert
                                                className={classes.cancelConditionAlert}
                                                variant={'filled'}
                                                severity={'info'}
                                                action={
                                                    <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                        <Grid item>
                                                            <Grid container spacing={2} alignItems={'center'}>
                                                                <Grid item>
                                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                                        {t('global.edit')}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{ width: '100%' }}>
                                                            {
                                                                accommodation.moved_with_recalc_custom_information &&
                                                                <Tooltip title={t('cart-material.recalc-product-note')}>
                                                                    <div
                                                                        className={clsx(classes.traverlerModification, classes.delete)}
                                                                        onClick={confirmCustomInformationRecalc}
                                                                        style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                                    >
                                                                        <Grid container alignItems={'center'} justify={'space-between'}>
                                                                            <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                            <Grid item>{t('cart-material.recalc')}</Grid>
                                                                            <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }
                                            >
                                                <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                                {accommodation.custom_information}
                                            </Alert>
                                        </Grid>
                                    )
                                }
                            </>
                        }
                        {
                            (accommodation.remark_booking || (accommodation.rooms.length !== 0 && accommodation.rooms.find((el, index) => {
                                return index !== 0 && el.remark_booking;
                            }))) && (
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <Alert className={`${classes.remarkExpand}`} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} alignItems={'flex-end'}>
                                            <Grid item>
                                                {
                                                    !expanded && (
                                                        <ExpandMoreIcon className={`${classes.deleteCustomInformation}`} onClick={() => {
                                                            setExpanded(!expanded);
                                                        }} />
                                                    )
                                                }
                                                {
                                                    expanded && (
                                                        <ExpandLessIcon className={`${classes.deleteCustomInformation}`} onClick={() => {
                                                            setExpanded(!expanded);
                                                        }} />
                                                    )
                                                }
                                            </Grid>
                                            {
                                                accommodation.moved_with_recalc_remark_booking &&
                                                <Grid item style={{ width: '100%' }}>
                                                    <Tooltip title={t('cart-material.recalc-product-remark-booking')}>
                                                        <div
                                                            className={clsx(classes.traverlerModification, classes.delete)}
                                                            onClick={confirmRemarkBookingRecalc}
                                                            style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                        >
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t('cart-material.recalc')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                        </Grid>
                                    }>
                                        <AlertTitle>{t('cart-material.remark-booking')} :</AlertTitle>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            {
                                                accommodation.rooms.length === 1 && (
                                                    <span>{accommodation.remark_booking}</span>
                                                )
                                            }
                                            {
                                                accommodation.rooms.length > 1 && accommodation.rooms.map((room, room_index) => {
                                                    if (room.remark_booking) {
                                                        return (
                                                            <p key={`remark_bookking-${room.id}`}>{accommodation.hotel && accommodation.hotel[0] && accommodation.hotel[0].type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('global.room')} {room_index + 1} : {room.remark_booking}</ p>
                                                        );
                                                    }
                                                })
                                            }
                                        </Collapse>
                                    </Alert>
                                </Grid>
                            )
                        }
                        {
                            provider &&
                            <Fragment>
                                {
                                    showManualCancellationRules ?
                                        <Grid id={`accommodation-${accommodation.id}-manual-cancellation-rules`} item xs={12}>
                                            {
                                                provider.provider.custom ?
                                                    <CartAccommodationManualCancellationRulesCustom accommodation={accommodation} onManualCancellationRules={onManualCancellationRules} /> :
                                                    <CartAccommodationManualCancellationRules accommodation={accommodation} onManualCancellationRules={onManualCancellationRules} />
                                            }
                                        </Grid> :
                                        accommodation.cancel_condition_text &&
                                        <Grid item xs={12}>
                                            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                    {
                                                        !isDisabled && (
                                                            <Fragment>
                                                                <Grid item>
                                                                    <Grid container spacing={2} alignItems={'center'}>
                                                                        {
                                                                            //!['verdie'].includes(quotation_code) &&
                                                                            <Fragment>
                                                                                <Grid item>
                                                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                                                        {t('global.edit')}
                                                                                    </Button>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                                                                </Grid>
                                                                            </Fragment>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item style={{ width: '100%' }}>
                                                                    {
                                                                        accommodation.moved_with_recalc_cancel_condition_text &&
                                                                        <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                                                            <div
                                                                                className={clsx(classes.traverlerModification, classes.delete)}
                                                                                onClick={confirmCancelConditionRecalc}
                                                                                style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}>
                                                                                <Grid container alignItems={'center'} justify={'space-between'}
                                                                                >
                                                                                    <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                                    <Grid item>{t('cart-material.recalc')}</Grid>
                                                                                    <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                </Grid>
                                                            </Fragment>
                                                        )
                                                    }
                                                </Grid>
                                            }>
                                                <AlertTitle>
                                                    {
                                                        !['verdie'].includes(quotation_code) ?
                                                            t('cart-material.manual-cancellation-rules') :
                                                            t('cart-material.provider-cancellation-rules')
                                                    } : {
                                                        accommodation.no_refund ?
                                                            t('cart-material.not-cancellable') :
                                                            t('cart-material.cancellable-with-conditions')
                                                    }
                                                </AlertTitle>
                                                {
                                                    provider.provider.custom ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(accommodation.cancel_condition_text === t('cart-material.not-cancellable') ? '' : accommodation.cancel_condition_text)
                                                            }}
                                                        />
                                                    ) : (
                                                        <Fragment>
                                                            {
                                                                accommodation.rooms.map((room, room_index) => (
                                                                    <div key={`room-description-${room.id}`}>
                                                                        <br />
                                                                        <Typography><strong>{accommodation.hotel && accommodation.hotel[0] && accommodation.hotel[0].type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('global.room')} {room_index + 1} :</strong> {room.description}</Typography>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: DOMPurify.sanitize(room.cancel_condition_text === t('cart-material.not-cancellable') ? '' : room.cancel_condition_text ?? '')
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </Alert>
                                        </Grid>
                                }
                            </Fragment>
                        }
                    </Grid>
                    <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
                </Grid>
                {
                    openReplaceModal &&
                    <CartConstructionReplaceProductModal
                        type="accommodation"
                        mode="replace"
                        status={status}
                        accommodationParams={{ id: accommodation.rooms[0]?.hotel[0]?.id ?? -1 }}
                        startDestination={accommodation.start_destination.id}
                        onDelete={onDeleteAccommodation}
                        onClose={() => setOpenReplaceModal(false)}
                    />
                }
                <LoadingBackDrop open={loading} />
            </>
        );
    }
);

const useStyles = makeStyles(theme => ({
    scrollMargin: {
        margin: 0,
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    resortFees: {
        margin: 0
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    remarkBooking: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    remarkBookingHide: {
        textDecoration: 'underline',
        marginBottom: 4,
        color: "gray",
        cursor: "not-allowed"
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMarginName: {
        marginBottom: 8
    },
    containerMargin: {
        marginBottom: 4
    },
    hotelName: {
        float: 'left',
        marginRight: 10,
        cursor: 'pointer',
        fontSize: 20,
        fontWeight: 'bold'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    stars: {
        fontSize: 16,
        verticalAlign: 'middle',
        marginRight: 2
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    includedAmenities: {
        display: 'inline-block',
        marginRight: 4
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    catalogedPrice: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 12
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: 20
    },
    priceDetail: {
        textAlign: 'right',
        textDecoration: 'underline'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '0px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0 0 16px',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    additionalInformation: {
        backgroundColor: '#2196f3',
        color: 'white',
        margin: '16px 0 !important'
    },
    additionalInformationExpandIcon: {
        color: 'white'
    },
    additionalInformationInfoIcon: {
        marginRight: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    remarkExpand: {
        "&& .MuiAlert-action": {
            display: "block !important",
            width: '18%',
            [theme.breakpoints.down('md')]: {
                width: '21%'
            }
        }
    },
    pictureContainer: {
        overflow: "hidden",
        height: 200,
        //width: 300,
        position: "relative",
        cursor: "pointer"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer',
        float: 'right'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    overflowText: {
        lineHeight: '1.5em',
        width: '100%',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));
