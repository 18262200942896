import React, { FC, useMemo, useState } from "react";
import { TripTrainLeg } from "./objects/trainCart";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { Box, Collapse, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { getProviderIcon } from "./utils/provider/getProviderIcon";
import { ExpandLess, ExpandMore, TrainOutlined, Work } from "@mui/icons-material";
import { TrainCartMainContentLegSegmentDetail } from "./trainCartMainContentLegSegmentDetail";
import { TrainCartSegmentInfoPerPax } from "./trainCartSegmentInfoPerPax";

interface TrainCartMainContentLeg {
    is_return: boolean;
    trip_leg: TripTrainLeg;
    train: TrainCartFromBackend;
}

export const TrainCartMainContentLeg: FC<TrainCartMainContentLeg> = ({ is_return, trip_leg, train }) => {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.user.user);
    const [openDetails, setOpenDetails] = useState(false);
    const lastIndex = trip_leg.segments.length - 1;
    const outboundDatesDiff = window.moment.utc(trip_leg.segments[lastIndex]?.arrival_datetime).startOf('day').diff(
        window.moment.utc(trip_leg.segments[0]?.departure_datetime).startOf('day'),
        'days'
    );
    const stopover_iata = useMemo(() =>
        trip_leg.segments.slice(1).map(segment => segment.origin_code),
        [trip_leg.segments]);



    return (<>
        <Box
            onClick={() => setOpenDetails((state) => !state)}
            sx={{
                cursor: 'pointer',
                textAlign: 'center',
                verticalAlign: 'bottom'
            }}
            component="tr"
        >
            {/* 
                props.enableSelection &&
                <td>
                    <Checkbox
                        checked={props.selected}
                        onChange={(_, checked) => props.onChangeSelection(checked)}
                        onClick={(event) => event.stopPropagation()}
                        sx={{ padding: 0 }}
                    />
                </td>
             */}
            <td>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                    <Box
                        src={
                            getProviderIcon(trip_leg.segments[0]!.marketing_information,
                                {
                                    operatingCarrier: trip_leg.segments[0]?.operating_carrier,
                                    marketingCarrier: trip_leg.segments[0]!.marketing_carrier
                                })
                        }
                        component="img"
                        sx={{
                            width: 100,
                            height: 30,
                            maxWidth: 100,
                            maxHeight: 30,
                            objectFit: 'contain'
                        }}
                    />
                    <Typography>
                        {t('global.train')} {is_return ? t('global.return') : t('global.go')}
                    </Typography>
                </Stack>
            </td>
            <td>
                <Typography>
                    {(window.moment.utc(trip_leg.segments[0]!.departure_datetime).format('LL'))}
                </Typography>
            </td>

            <td>
                <Typography>
                    {trip_leg.origin_label}
                </Typography>
            </td>
            <Box
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    textAlign: 'center'
                }}
                component="td"
            >
                <TrainOutlined
                    sx={{
                        verticalAlign: 'bottom'
                    }}
                />
            </Box>
            <td>
                <Typography>
                    {trip_leg.destination_label}
                    {
                        outboundDatesDiff > 0 &&
                        <Box
                            sx={{
                                fontSize: 10,
                                verticalAlign: 'top',
                                marginLeft: 0.5
                            }}
                            component="span"
                        >
                            {' '}
                            <Box sx={{ color: 'red' }} component="span">
                                {"+" + outboundDatesDiff + t("global.abrev_day")}
                            </Box>
                            {' '}
                            <Box sx={{ opacity: 0.65 }} component="span">
                                ({window.moment.utc(trip_leg.segments[lastIndex]?.arrival_datetime).format('DD MMM')})
                            </Box>
                        </Box>
                    }
                </Typography>
            </td>
            <td>
                <Typography>
                    {window.moment.utc(trip_leg.segments[lastIndex]?.arrival_datetime).format('LL')}
                </Typography>
            </td>

            <Box
                sx={{
                    paddingLeft: 2,
                    textAlign: 'center'
                }}
                component="td"
            >
                {
                    openDetails ?
                        <ExpandLess sx={{ verticalAlign: 'middle' }} /> :
                        <ExpandMore sx={{ verticalAlign: 'middle' }} />
                }
            </Box>
        </Box>

        {/* second line */}
        <Box
            onClick={() => setOpenDetails((state) => !state)}
            sx={{
                cursor: 'pointer',
                textAlign: 'center',
                lineHeight: 1
            }}
            component="tr"
        >
            <td />
            <td>
                <Typography variant="caption">
                    (
                    {
                        trip_leg.segments.map((segment) => {
                            return `${segment.marketing_carrier}`;
                        }).join(', ')
                    }
                    )
                </Typography>
            </td>
            <td>
                <Typography variant="caption">
                    {window.moment.utc(trip_leg.segments[0]!.departure_datetime).format('HH:mm')}
                </Typography>
            </td>
            <td>
                <Typography variant="caption">
                    (
                    {
                        trip_leg.segments.map((segment) => {
                            return `${segment.vehicle_type}-${segment.vehicle_reference}`;
                        }).join(', ')
                    }
                    )
                </Typography>
            </td>
            <td>
                <Typography variant="caption">
                    {window.moment.utc(trip_leg.segments[lastIndex]?.arrival_datetime).format('HH:mm')}
                </Typography>
            </td>
            <td>
                {
                    trip_leg.segments.length === 1 ?
                        <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
                            {t("flight_search.direct_flight")}
                        </Typography> :
                        <Typography variant="caption">
                            {
                                trip_leg.segments.length === 2 ?
                                    `1 ${t('shared.stopover')}` :
                                    `${trip_leg.segments.length - 1} ${t('shared.stopovers')}`
                            }
                            {
                                trip_leg.segments.length > 1 &&
                                ` (${stopover_iata.join(', ')})`
                            }
                        </Typography>
                }
            </td>
        </Box>

        {/* segment detail */}
        <tr className="flight-segment-details">
            <td colSpan={12}>
                <Collapse in={openDetails}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1.5}
                        sx={{
                            marginTop: 1,
                            marginBottom: 1
                        }}
                    >
                        <Box sx={{ flex: 1.5 }}>
                            {
                                trip_leg.segments.map((leg, index, array) => (
                                    <TrainCartMainContentLegSegmentDetail
                                        key={`segment-main-content-${index}`}
                                        leg={leg}
                                        trip_leg={trip_leg}
                                        leg_index={index}
                                        max_index={array.length - 1}
                                        stopover_duration={
                                            index < trip_leg.segment_connections.length ?
                                                trip_leg.segment_connections[index]?.connecting_time ?? null :
                                                null
                                        }
                                        // duration_days={props.outbound.duration_days}
                                        // duration_days={1}
                                        small
                                    />
                                ))
                            }
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <TrainCartSegmentInfoPerPax
                            train={train}
                            trip_leg={trip_leg}
                        />
                    </Stack>
                </Collapse>
            </td>
        </tr>

    </>);
}