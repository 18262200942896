import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    paper: {
        marginBottom: 8,
    },
    background: {
        backgroundColor: grey[200]
    },
    padding: {
        padding: 8
    },
    pointer: {
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: 0
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white',
        borderRadius: 24
    },
    selection: {
        fontWeight: 'bold',
        textAlign: 'center'
    }
});

const AccommodationCardPreCart = ({pre_cart, onDelete, accommodation_type}) => {
    console.log('pre cart render');
    const { t } = useTranslation();
    const user = useSelector(store => store.user.user);
    const classes = useStyles();
    return (
        <Fragment>
            {
                pre_cart.length > 0 && (
                    <Typography variant={ 'h6' } className={ classes.selection }>{ t('accommodation.your_selection') } :</Typography>
                )
            }
            {
                pre_cart.map((room, index_room) => {
                    return (
                        <Paper key={ index_room } id={ `pre-cart-${index_room}` } className={ classes.paper } variant={ 'outlined' }>
                            <Grid container spacing={ 0 }>
                                <Grid container item xs={ 12 } className={ `${classes.padding} ${classes.background}` } justify={ 'space-between' }>
                                    <Grid item>
                                        <Typography>{ accommodation_type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('accommodation.room') } { room.room_nb + 1 } : { room.adult_nb } { t('global.adults') } { (room.child_nb > 0 ? `/ ${ room.child_nb } ${ t('global.children') }` : '') }</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Close className={ classes.pointer } onClick={() => { onDelete(index_room); }}/>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={ 12 } className={ classes.padding } justify={ 'space-between' }>
                                    <Grid item>
                                        <Typography>{ room.variant.description }</Typography>
                                    </Grid>
                                    {
                                        user.client_full.type !== 2 && room.provider_loaded &&
                                        <Grid item>
                                            {
                                                room.provider_loaded.logo !== null ? <img src={ room.provider_loaded.logo.url } height={ 20 }/> : <Typography>{ room.provider_loaded.name }</Typography>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })
            }
        </Fragment>
    );
};

export default React.memo(AccommodationCardPreCart);