import { TrainTrip, TrainTripSolution } from "../../objects/train";
import { TrainBackendData, TrainBackendTraveler } from "../../objects/backendData/trainBackendDate";

const transformOfferItemForUi = (data: TrainBackendData): { train_trip_departure: TrainTrip[], train_trip_return?: TrainTrip[] } => {

    const train_trip_departure = data.legs.outbound_legs.solutions.map((solution) => {
        return {
            id: solution.id,
            offers: solution.offers,
            type: 'OUTBOUND' as "OUTBOUND" | "RETURN",
            solution: solution as unknown as TrainTripSolution,
            booking_status: null,
            provider_id: data.provider_id,
            search_criteria: data.search_criteria
        }
    });
    const train_trip_return = data.legs.return_legs?.solutions.map((solution) => {
        return {
            id: solution.id,
            offers: solution.offers,
            type: 'RETURN' as "OUTBOUND" | "RETURN",
            solution: solution as unknown as TrainTripSolution,
            booking_status: null,
            provider_id: data.provider_id,
            search_criteria: data.search_criteria
        }
    });
    return { train_trip_departure, train_trip_return };
}

export const getTrainListForUi = (train: TrainBackendData): { train_trip_departure: TrainTrip[], train_trip_return?: TrainTrip[]; travelers: TrainBackendTraveler[] } => {
    const { train_trip_departure, train_trip_return } = transformOfferItemForUi(train);
    const travelers = train?.travelers || [];
    return { train_trip_departure, train_trip_return, travelers };
};