import React from "react";
import {
    TextField as BaseTextField,
    TextFieldProps,
    chipClasses,
    formLabelClasses,
    iconButtonClasses,
    inputAdornmentClasses,
    inputBaseClasses,
    styled,
} from "@mui/material";

export const CustomTextField = styled((props: TextFieldProps) => {
    return (
        <BaseTextField
            variant={"standard"}
            {...props}
        />
    );
}, {
    shouldForwardProp(propName) {
        return propName !== 'withEndAdornment';
    }
})<{
    withEndAdornment?: boolean
}>((props) => ({
    border:
        `1px solid ${props.error ? props.theme.palette.error.main : props.theme.palette.grey[400]}`,
    borderRadius: '0.6rem',
    padding: `0 ${props.theme.spacing(1)}`,
    paddingBottom: props.theme.spacing(0.5),
    minHeight: '3rem',
    [`.${inputBaseClasses.root}`]: {
        borderRadius: '0.6rem',
        marginTop: 0,
        paddingRight: props.withEndAdornment ? undefined : '0 !important',
        paddingLeft: 0,
        paddingBottom: `${props.theme.spacing(0.25)}`,
        paddingTop: '9px',
        '&:before':
            {
                borderBottom: 'none'
            },
    },

    [`.${formLabelClasses.root}`]:
        {
            opacity: 0.7,
            top: props.theme.spacing(1.65),
            fontSize: '1.40rem',
            zIndex: 1,
            cursor: 'text',
            transition: 'all 0.1s ease-in-out', 
            marginLeft: props.theme.spacing(
                props.InputProps?.startAdornment ?
                    4 :
                    1
            ),
            [`&.${formLabelClasses.focused}`]: {
                opacity: 1,
                top: props.theme.spacing(0.45),
                fontSize: props.theme.typography.body2.fontSize,
            },
            [`&.${formLabelClasses.filled}`]: {
                opacity: 1,
                top: props.theme.spacing(0.45),
                fontSize: props.theme.typography.body2.fontSize,
            },    
            [`&:has(+ * .${chipClasses.filled}, ~ * .${chipClasses.filled})`]: {
                opacity: '1 !important',
                top: `${props.theme.spacing(0.45)} !important`,
                fontSize: `${props.theme.typography.body2.fontSize} !important`,
            },
        },
    [`.${inputAdornmentClasses.root}`]:
        {
            marginRight: '3px !important'
        },
    [`.${inputAdornmentClasses.root} svg:not(.${iconButtonClasses.root} svg)`]:
        {
            // transform: 'translateY(-20%)',
            fontSize: '1.3em',
        },
    [`.${inputBaseClasses.input}`]:
        {
            paddingBottom: '0 !important'
        },
    [`&:hover .${inputBaseClasses.root}:after`]:
        {
            transform: 'scaleX(1) translateX(0)'
        },
    'input::placeholder':
        {
            opacity: 1,
            fontStyle: 'normal'
        },
    [`.${chipClasses.root}`]: {
        margin: '0 !important',
        marginTop: '6px !important',
        height: '20px !important'
    }
}));
