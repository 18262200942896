import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { flattenDeep, intersection } from "lodash";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { useProductDays } from "./productDays";
import { filterCartProductsByStatus } from "../../Menu/MaterialTripList/utils/filterCartProductsByStatus";
import { sortProducts } from "./sortProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { AppState } from "../../../Reducers/Reducers";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { Flight } from "../../Itinerary/network/flight";
import { ManualProductFlightCart } from "../../Itinerary/objects/manualProductFlightCart";
import { TrainCartFromBackend } from "../../train/objects/backendData/cart/trainCartFromBackend";
import { TripTrainLegSegment } from "../../train/objects/trainCart";
import { ManualProductTrainCart } from "../../Itinerary/objects/manualProductTrainCart";

type Products = Pick<
    ReturnType<typeof useCartProducts>,
    'cars' |
    'accommodations' |
    'transfers' |
    'pois'
> & {
    manualProducts: ManualProduct[]
} & {
    flights: ({
        type: 'normal',
        startDate: string,
        leg: Flight['outbounds'][number]['legs'][number],
        flight: Flight
    } | {
        type: 'manual',
        startDate: string,
        leg: ManualProductFlightCart['flight_segment'][number],
        flight: ManualProductFlightCart
    })[]
} & {
    trains: ({
        type: 'normal',
        startDate: string,
        leg: TripTrainLegSegment,
        train: TrainCartFromBackend
    } | {
        type: 'manual',
        train: ManualProductTrainCart
    })[]
}

type Callback = (content: ItineraryContentStep, index: number) => {
    [K in keyof Products]: (Products[K][number] & { isDayIncluded: boolean, isOnEndDate: boolean })[]
}

export function useItineraryContentCartProducts(): Callback {
    const manualProducts = useSelector((state: AppState) => state.cart.manual_item_list);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const getProductDays = useProductDays();
    const steps = useMemo(() => {
        return itinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary, getProductDays]);
    const cart = useCartProducts();
    const filteredCart = useMemo(() => {
        return filterCartProductsByStatus(
            cart,
            itinerary_type_details_data,
            false
        );
    }, [cart]);
    return useCallback(
        (data, index) => {
            return {
                cars: filteredCart.cars.filter((item) => {
                    if (!window.moment.utc(item.car.start_date).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.car.start_date, item.car.end_date);

                    if (data.mode === 'by-day') {
                        return intersection(days, data.content.day).length > 0;
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return intersection(days, stepDays).length > 0;
                    }

                    return false;
                }).sort((a, b) => {
                    return sortProducts(a.car, b.car);
                }).map((item) => {
                    const days = getProductDays(item.car.start_date, item.car.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                accommodations: filteredCart.accommodations.filter((item) => {
                    if (!window.moment.utc(item.accommodation.start_date).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.accommodation.start_date, item.accommodation.end_date);
                    if (days.length > 1) {
                        days.pop();
                    }

                    if (data.mode === 'by-day') {
                        return intersection(days, data.content.day).length > 0;
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        if (stepDays.length > 1) {
                            stepDays.pop();
                        }
                        return intersection(days, stepDays).length > 0;
                    }

                    return false;
                }).sort((a, b) => {
                    return sortProducts(a.accommodation, b.accommodation);
                }).map((item) => {
                    const days = getProductDays(item.accommodation.start_date, item.accommodation.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                trains: flattenDeep(
                    filteredCart.trains.map((item) => {
                        return item.type === 'normal' ?
                            item.train.trip_legs.map((leg) => {
                                return leg.segments.map((segment, _, array): Products['trains'][number] => {
                                    return {
                                        type: 'normal',
                                        startDate: leg?.departure_datetime as unknown as string ?? '',
                                        leg: segment,
                                        train: item.train
                                    };
                                });
                            }) :
                            item;
                    })
                ).filter((item) => {
                    if (!window.moment.utc(item.train.start_date).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.train.start_date);

                    if (data.mode === 'by-day') {
                        return days[0] && data.content.day.includes(days[0]);
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return days[0] && stepDays.includes(days[0]);
                    }

                    return false;
                }).sort((a, b) => {
                    return sortProducts(a.train, b.train);
                }).map((item) => {
                    const days = getProductDays(item.train.start_date, item.train.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                transfers: filteredCart.transfers.filter((item) => {
                    if (!window.moment.utc(item.transfer.start_date).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.transfer.start_date);

                    if (data.mode === 'by-day') {
                        return days[0] && data.content.day.includes(days[0]);
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return days[0] && stepDays.includes(days[0]);
                    }

                    return false;
                }).sort((a, b) => {
                    return sortProducts(a.transfer, b.transfer);
                }).map((item) => {
                    const days = getProductDays(item.transfer.start_date, item.transfer.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                pois: filteredCart.pois.filter((item) => {
                    if (!window.moment.utc(item.poi.start_date).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.poi.start_date);

                    if (data.mode === 'by-day') {
                        return days[0] && data.content.day.includes(days[0]);
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return days[0] && stepDays.includes(days[0]);
                    }

                    return false;
                }).sort((a, b) => {
                    return sortProducts(a.poi, b.poi);
                }).map((item) => {
                    const days = getProductDays(item.poi.start_date, item.poi.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                flights: flattenDeep(
                    filteredCart.flights.map((item) => {
                        return item.type === 'normal' ?
                            item.flight.outbounds.map((outbound) => {
                                return outbound.legs.map((leg, _, array): Products['flights'][number] => {
                                    return {
                                        type: 'normal',
                                        startDate: array[0]?.departure_time ?? '',
                                        leg,
                                        flight: item.flight
                                    };
                                });
                            }) :
                            item.flight.flight_segment.map((segment): Products['flights'][number] => {
                                return {
                                    type: 'manual',
                                    startDate: segment.start_date,
                                    leg: segment,
                                    flight: item.flight
                                };
                            });
                    })
                ).filter((item) => {
                    if (!window.moment.utc(item.startDate).isValid()) {
                        return false;
                    }

                    const days = getProductDays(item.startDate ?? '');

                    if (data.mode === 'by-day') {
                        return intersection(days, data.content.day).length > 0 ||
                            (
                                data.content.day.includes(1) &&
                                window.moment.utc(item.startDate).isBefore(
                                    window.moment.utc(steps[0]?.start_date)
                                )
                            );
                    }

                    const step = steps[index];
                    if (step && step?.destination?.id === data.content.destination?.id) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return intersection(days, stepDays).length > 0;
                    }

                    return false;
                }).sort((a, b) => {
                    const aStartDate = a.type === 'normal' ?
                        a.leg.departure_time :
                        a.leg.start_date;
                    const bStartDate = b.type === 'normal' ?
                        b.leg.departure_time :
                        b.leg.start_date;
                    const aDate = window.moment.utc(aStartDate);
                    const bDate = window.moment.utc(bStartDate);
                    return aDate.isBefore(bDate) ? -1 : 1;
                }).map((item) => {
                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                }),
                manualProducts: (
                    manualProducts?.filter((item) => {
                        if (!window.moment.utc(item.start_date).isValid()) {
                            return false;
                        }

                        const days = getProductDays(item.start_date);

                        if (![9, 13].includes(item.product_type)) {
                            return false;
                        }

                        if (data.mode === 'by-day') {
                            return days[0] && data.content.day.includes(days[0]);
                        }

                        const step = steps[index];
                        if (step && step?.destination?.id === data.content.destination?.id) {
                            const stepDays = getProductDays(step.start_date, step.end_date);
                            return days[0] && stepDays.includes(days[0]);
                        }

                        return false;
                    }) ?? []
                ).sort((a, b) => {
                    return sortProducts(a, b);
                }).map((item) => {
                    const days = getProductDays(item.start_date, item.end_date);

                    if (data.mode === 'by-day') {
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !data.content.day.includes(days[0]!),
                            isOnEndDate: days.length > 1 && data.content.day.includes(days[days.length - 1]!)
                        };
                    }

                    const step = steps[index];
                    if (step) {
                        const stepDays = getProductDays(step.start_date, step.end_date);
                        return {
                            ...item,
                            isDayIncluded: days.length > 0 && !stepDays.includes(days[0]!),
                            isOnEndDate: days.length > 1 && stepDays.includes(days[days.length - 1]!)
                        };
                    }

                    return {
                        ...item,
                        isDayIncluded: false,
                        isOnEndDate: false
                    };
                })
            };
        },
        [
            filteredCart,
            manualProducts,
            getProductDays
        ]
    );
}
