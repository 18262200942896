import { makeStyles } from "@material-ui/core";
import { Stack, Typography, Button, Divider, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrainForm } from "./objects/trainForm";
import { ArrowCircleLeftRounded, ExpandCircleDownRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateTrainDepartureSelected, updateTrainReturnSelected } from "./redux/reducer";
import { TicketingOptionsEntity, TravelPreferences, TravelPreferencesSelected } from "./objects/backendData/trainBackendDate";
import { TrainSegment } from "./objects/train";
import { AppState } from "../../Reducers/Reducers";
import { TrainListContentFormAddToCart } from "./trainListContentFormAddToCart";



const useStyles = makeStyles((theme: any) => ({
    drawerContainer: {
        height: '100vh',
        flexDirection: 'column',
        backgroundColor: '#F3F3F8'
    },
    drawerHeader: {
        padding: '1rem 1rem',
        backgroundColor: 'black'
    },
    drawerContent: {
        flex: '0 1 100%',
        overflow: 'auto',
        flexDirection: 'column',
        padding: '16px',
        gap: theme.spacing(2)
    },
    drawerBottom: {
        flex: '0 1 96px'
    },
    fareOfferContainer: {
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    validateButton: {
        color: "white !important",
        padding: '0.5rem 1.5rem !important',
        backgroundColor: "rgb(20, 112, 138) !important",
        borderRadius: '1.5rem !important',
        '&:hover': {
            backgroundColor: 'rgb(20, 112, 138, 0.9) !important'
        },
    }
}));

interface TrainListContentFormPreferenceByOfferProps {
    train_form: TrainForm;
    setTrainFormAction: (train_form: TrainForm | null) => void;
    is_departure: boolean;
}

export const TrainListContentFormPreferenceByOffer: FC<TrainListContentFormPreferenceByOfferProps> = ({ is_departure, train_form, setTrainFormAction }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectPreferenceOption = useCallback((preference: TravelPreferences, option: string) => {
        dispatch(setTrainFormAction({
            ...train_form,
            selected_preferences: train_form.selected_preferences?.map(preference_form => {
                if (preference_form.id === preference.id && preference_form.preference_type === preference.preference_type)
                    return { ...preference_form, option };
                else return preference_form;
            })
        }))
    }, [dispatch, train_form?.selected_preferences]);

    return <Stack direction={'column'} spacing={1}>
        <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: 700 }}>{t('train.segment.no-warenti')}</Typography>
        <Stack direction={'column'} sx={{ borderRadius: '0.5rem', overflow: 'hidden' }}>
            {/* <Stack direction={'row'} sx={{ padding: '0.75rem 1rem', backgroundColor: 'rgb(218, 234, 243)' }}>
                <Typography variant="body2" sx={{ fontSize: '1.1rem', lineHeight: '1.5rem', fontWeight: 700 }}>{train_form.solution.origin.label} {t('train.to')} {train_form.solution.destination.label}</Typography>
            </Stack> */}
            <Stack direction={'column'} spacing={1} flexWrap={'wrap'} sx={{ padding: '1rem', backgroundColor: 'white' }}>
                {train_form.selected_offer?.travel_preferences.map((preference, pref_index) =>
                    <Stack key={`segment-preference-${preference.id}`} spacing={1} direction={'column'} >
                        <Typography variant="subtitle2">
                            {t(`train.traveler-preference.${preference.preference_type}`)} :
                        </Typography>
                        <Stack>
                            <RadioGroup
                                value={train_form.selected_preferences?.find((selected_pref) =>
                                    preference.id === selected_pref.id &&
                                    preference.preference_type === selected_pref.preference_type
                                )?.option || ''}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => selectPreferenceOption(preference, event.target.value)}
                            >
                                <FormControlLabel key={`preference-option-${preference.id}-no-preference`} value={''} control={<Radio />} label={t(`train.preference.option.no-preference`)} />
                                {
                                    preference.options.map((preference_option) => <FormControlLabel key={`preference-option-${preference.id}-${preference_option}`} value={preference_option} control={<Radio />} label={t(`train.preference.option.${preference_option}`)} />)
                                }
                            </RadioGroup>
                        </Stack>
                        {pref_index < (train_form.selected_offer?.travel_preferences || []).length - 1 && <Divider />}
                    </Stack>)}
            </Stack>
        </Stack>
    </Stack>
};


interface TrainListContentFormPreferenceProps {
    closeDrawer: () => void;
    train_form: TrainForm;
    setTrainFormAction: (train_form: TrainForm | null) => void;
    is_departure: boolean;
}
export const TrainListContentFormPreference: FC<TrainListContentFormPreferenceProps> = ({ is_departure, train_form, closeDrawer, setTrainFormAction }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [add_to_cart, setAddToCart] = useState<boolean>(false);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    // const ticketing_options: TicketingOptionsEntity[] = useMemo(() => {
    //     const options = train_form.selected_offer?.ticketing_options || [];
    //     const seen = new Set<string>();

    //     return options.filter(opt =>
    //         seen.has(opt.code) ? false : (seen.add(opt.code), true)
    //     );
    // }, [train_form.selected_offer]);


    const closeOfferPriceCondition = () => {
        dispatch(setTrainFormAction({ ...train_form, selected_offer: undefined }));
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const ticketing = ticketing_options.find((ticketing) => ticketing.code === event.target.value);
    //     dispatch(setTrainFormAction({ ...train_form, selected_ticketing: ticketing }));
    // };

    const selectTrainForm = async () => {
        const trainSelectedAction = is_departure ? updateTrainDepartureSelected : updateTrainReturnSelected;
        if (can_return && is_departure) {
            dispatch(trainSelectedAction({ ...train_form }));
            // dispatch(setTrainFormAction(null));
        }
        if ((!can_return && is_departure) || (can_return && !is_departure)) {
            setAddToCart(true)
        }
    };

    const initPreferenceForm = () => {
        const preference_form: TravelPreferencesSelected[] = [];
        train_form.selected_offer?.travel_preferences.forEach((preference) => {
            preference_form.push({
                id: preference.id,
                // option: preference.options[0] || '',
                option: '',
                preference_type: preference.preference_type,
            });
        });
        // const selected_ticketing = ticketing_options.length > 0 ? ticketing_options[0] : undefined;
        dispatch(setTrainFormAction({ ...train_form, selected_preferences: preference_form }));
    };

    useEffect(() => {
        initPreferenceForm();
    }, [train_form.selected_offer?.travel_preferences]);

    return (<>
        <Stack className={classes.drawerContainer}>
            <Stack justifyContent={'space-between'} direction='row' className={classes.drawerHeader}>
                <Stack direction={'row'} spacing={1} sx={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={closeOfferPriceCondition}>
                    <ArrowCircleLeftRounded sx={{ color: 'white', width: '35px', height: '35px' }} />
                    <Typography variant="subtitle1" fontSize={20} color="white">{t("train.preferences")}</Typography>
                </Stack>
                <Button onClick={closeDrawer}>{t("shared.close")}</Button>
            </Stack>
            <Stack className={classes.drawerContent}>
                {/* <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={1} >
                    <Stack direction={'row'} spacing={1} sx={{ cursor: 'pointer' }} onClick={selectTrainForm}>
                        <Typography variant={'caption'} sx={{ color: 'rgb(20, 112, 138)', fontSize: '0.8rem' }}>{t('train.skip-step')}  </Typography>
                        <ExpandCircleDownRounded sx={{ color: 'rgb(20, 112, 138)', transform: 'rotate(-90deg)', '&:hover': { color: 'rgb(20, 112, 138, 0.9)' } }} />
                    </Stack>
                </Stack>
                {ticketing_options.length > 0 && <Stack direction={'column'} sx={{ borderRadius: '0.5rem', overflow: 'hidden', flex: '0 0 fit-content' }}>
                    <Stack direction={'row'} sx={{ padding: '0.75rem 1rem', backgroundColor: 'rgb(218, 234, 243)' }}>
                        <Typography variant="body2" sx={{ fontSize: '1.125rem', lineHeight: '1.5rem', fontWeight: 700 }}>{t('train.ticketing-option')}</Typography>
                    </Stack>
                    <Stack direction={'row'} flexWrap={'wrap'} sx={{ padding: '1rem', backgroundColor: 'white' }}>
                        <RadioGroup row value={train_form.selected_ticketing?.code || ''} onChange={handleChange}>
                            {ticketing_options?.map((ticketing_option) => (
                                <FormControlLabel key={`ticketing-option-${ticketing_option.code}`} value={ticketing_option.code} control={<Radio />} label={t(`train.ticketing.${ticketing_option.code}`)} />
                            ))}
                        </RadioGroup>
                    </Stack>
                </Stack>} */}

                {/* <TrainListContentFormPreferenceBySegment train_form={train_form} setTrainFormAction={setTrainFormAction} is_departure={is_departure} /> */}
                {((train_form.selected_offer?.travel_preferences?.length ?? 0) === 0) &&
                    <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: 700 }}>{t('shared.no-train-preference')}</Typography>}
                {((train_form.selected_offer?.travel_preferences?.length ?? 0) > 0) && <TrainListContentFormPreferenceByOffer train_form={train_form} setTrainFormAction={setTrainFormAction} is_departure={is_departure} />}
            </Stack>
            <Stack className={classes.drawerBottom}>
                <Divider sx={{ borderColor: 'white', borderWidth: '1.5px' }} />
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                    <Button className={classes.validateButton} onClick={selectTrainForm}>
                        {t('shared.continue')}</Button>
                </Stack>
            </Stack>
        </Stack >
        <TrainListContentFormAddToCart setTrainFormAction={setTrainFormAction} train_form={train_form} is_departure={is_departure} open={add_to_cart} onClose={() => setAddToCart(false)} />
    </>
    );
};