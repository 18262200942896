
import moment from "moment";
import { TrainLegCriteria, TrainLegsCriteria, TrainListFiltersCriteria, TrainListFiltersFrontCriteria, TrainLocation, TrainTravelersCriteria } from "../../objects/trainList";
import { combineDateAndTimeWithLocalTimezone } from "../dates/combineDateAndTime";

export const getTrainFilterCriteria = (train_list_filter_front: TrainListFiltersFrontCriteria, only?: { departure?: boolean; return?: boolean }): TrainListFiltersCriteria[] => {
    const leg: TrainLegCriteria = {
        origin: {type: (train_list_filter_front.origin as TrainLocation)?.type, code: (train_list_filter_front.origin  as TrainLocation)?.code} as TrainLocation,
        destination: {type: (train_list_filter_front.destination as TrainLocation)?.type, code: (train_list_filter_front.destination  as TrainLocation)?.code} as TrainLocation,
        departure: moment(combineDateAndTimeWithLocalTimezone(train_list_filter_front.departure, train_list_filter_front.departure_hour)).format("YYYY-MM-DDTHH:mm:ss"),
        // multiProviderEnabled: false
    };

    const return_leg: TrainLegCriteria = {
        origin: {type: (train_list_filter_front.destination as TrainLocation)?.type, code: (train_list_filter_front.destination  as TrainLocation)?.code} as TrainLocation,
        destination: {type: (train_list_filter_front.origin as TrainLocation)?.type, code: (train_list_filter_front.origin  as TrainLocation)?.code} as TrainLocation,
        departure: moment(combineDateAndTimeWithLocalTimezone(train_list_filter_front.return, train_list_filter_front.return_hour)).format("YYYY-MM-DDTHH:mm:ss"),
        // multiProviderEnabled: false
    };

    const final_leg = only?.departure ? [leg] : (only?.return? [return_leg] : (train_list_filter_front.can_return ? [leg, return_leg] : [leg]));

    return train_list_filter_front.providers.map((provider) => ({
        legs: final_leg as TrainLegsCriteria,
        travelers: train_list_filter_front.travelers?.map((traveler) => ({traveler_id: traveler.id})) as TrainTravelersCriteria[],
        provider_id: provider.id
    }));
};