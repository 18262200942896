import { FormControl, FormHelperText, InputAdornment, MenuItem, MenuProps, Select, Stack } from "@mui/material";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next"
import { TrainListFiltersFrontCriteria } from "./objects/trainList";
import { AppState } from "../../Reducers/Reducers";
import { useSelector } from "react-redux";
import { searchNextDate } from "./utils/dates/searchNextDate";

import { LocalizationProvider, DatePicker } from "@material-ui/pickers";
import { TextField, makeStyles } from '@material-ui/core';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import moment from "moment";
import { CustomTextField } from "./components/inputs/customTextField";
import { CalendarToday } from "@mui/icons-material";

interface DateRangeFilters {departure: Date, departure_hour: string, return: Date | null, return_hour: string}

export type DateRangeFiltersKeyErrors = `${keyof DateRangeFilters}_error`;
export type DateRangeFiltersErrors = {
    [K in DateRangeFiltersKeyErrors]?: string
}
type DateRangeFiltersForm = DateRangeFilters & DateRangeFiltersErrors;


const useStyles = makeStyles((theme) => ({
    inputRoot: {
      borderRadius: '8px', // Set your desired border radius
    },
    stackContainerResponsive: {
        gap: theme.spacing(4),
        flexDirection: 'row !important' as unknown as undefined, // For desktop
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column  !important'as unknown as undefined, // Stack vertically for mobile
          gap: theme.spacing(2),
        },
    },
    stackContentResponsive: {
        gap: theme.spacing(1),
        flexDirection: 'row  !important' as unknown as undefined, // For desktop
        flex:'0 1 50%',
        [theme.breakpoints.down('sm')]: {
          flex:'0 1 100%',
        },
    },
  }));

  const select_sx = {
    borderRadius: '0.6rem !important',
    minHeight: '3rem',
  }
  const menuProps: Partial<MenuProps> = {
    PaperProps: {
        sx: {
            maxHeight: 400, 
            borderRadius: '0.6rem',
            border: '0.5px solid rgba(0, 0, 0, 0.3)',
            boxShadow: 'none !important',
            ['& .Mui-selected']: {
                backgroundColor: 'rgb(0, 97, 121) !important',
                color: 'white !important',
            }
        },
    },
};
export const TrainSearchDatesRangePickerHeader: FC<{setFilters:  React.Dispatch<React.SetStateAction<DateRangeFiltersForm>>, filters: DateRangeFiltersForm; can_return: boolean; return_required?: boolean}> = ({setFilters, filters, can_return, return_required}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const language = useSelector((state: AppState) => state.header.language);
    
    const departure_avaliable_hours = Array.from({ length: 10 }, (_, i) => 
        `${((i + 2) * 2).toString().padStart(2, '0')}h`
    );

    const return_avaliable_hours = useMemo(() => {
        if (filters.departure && filters.return && moment(filters.departure).isSame(moment(filters.return), 'day')) {
            // Extraire l'heure depuis `filters.departure_hour`
            const departureHour = parseInt(filters.departure_hour.split('h')[0] || '00', 10);

            // Filtrer les heures disponibles en fonction de l'heure de départ
            return departure_avaliable_hours.filter((hour) => {
                const hourValue = parseInt(hour.split('h')[0] || '00', 10);
                return hourValue > departureHour;
            });
        } else {
            return departure_avaliable_hours;
        }
    }, [filters]);

    const onFieldChange = (key: keyof TrainListFiltersFrontCriteria, value: any) => {
        if(key === 'return' && filters.departure) {
            if(value?.isSame(moment(filters.departure), 'day')) {
                const {return_hour} = searchNextDate(filters.departure, filters.departure_hour);
                setFilters((state) => ({
                    ...state, 
                    [key]: value,
                    return_hour,
                    return_error: undefined
                }));
            } else {
                setFilters((state) => ({ ...state, [key]: value, [`${key}_error`]: undefined }));
            }
        } else {
            setFilters((state) => ({ ...state, [key]: value, [`${key}_error`]: undefined }));
        }
    };

    return (<Stack className={classes.stackContainerResponsive}>
        <Stack className={classes.stackContentResponsive}>
            <Stack flex={'0 1 80%'}>
                <FormControl fullWidth error={!!filters.departure_error} required>
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language } >
                        <DatePicker
                            label={t('global.go')}
                            value={filters.departure}
                            minDate={new Date()}
                            onChange={(date) => onFieldChange('departure', date)}
                            disabled={!!return_required}
                            renderInput={(props) => (
                                <CustomTextField {...props} required helperText={""} disabled={!!return_required} error={!!filters.departure_error}
                                InputProps={{
                                    ...props.InputProps,
                                    startAdornment: (<InputAdornment position="start">
                                        <CalendarToday sx={{color: 'rgba(0, 0, 0, 0.7)'}}/>
                                    </InputAdornment>
                                    ),
                                    endAdornment: null,
                                }}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder: '',
                                }}
                                />
                                // <TextField {...props}  error={!!filters.departure_error} variant="outlined" size="small" InputProps={{ classes: { root: classes.inputRoot } }}/>
                            )}         
                        />
                        {filters.departure_error && <FormHelperText>{filters.departure_error}</FormHelperText>}
                    </LocalizationProvider>
                </FormControl>
            </Stack>
            <Stack flex={'0 1 20%'}>
                <Select
                    value={filters.departure_hour}
                    onChange={(event) => onFieldChange('departure_hour', event.target.value)}
                    size="small"
                    sx={select_sx}
                    MenuProps={menuProps}
                    disabled={!!return_required}
                >
                    {departure_avaliable_hours.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
        </Stack>
        <Stack className={classes.stackContentResponsive}>
            <Stack flex={can_return ? '0 1 80%': '0 1 100%'}>
                <FormControl fullWidth error={!!filters.return_error} required={!!return_required}>
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                        <DatePicker
                            label={t('global.Return')}
                            value={filters.return}
                            minDate={filters.departure}
                            onChange={(date) => onFieldChange('return', date)}
                            disabled={!!return_required}
                            renderInput={(props) => (
                            <CustomTextField {...props} helperText={""} required={!!return_required} error={!!filters.return_error}
                                InputProps={{
                                    ...props.InputProps,
                                    startAdornment: (<InputAdornment position="start">
                                        <CalendarToday sx={{color: 'rgba(0, 0, 0, 0.7)'}}/>
                                    </InputAdornment>
                                    ),
                                    endAdornment: null,
                                }}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder: '',
                                }}
                                />
                                // <TextField {...props}  error={!!filters.departure_error} variant="outlined" size="small" InputProps={{ classes: { root: classes.inputRoot } }}/>
                            )}        
                        />
                    </LocalizationProvider>
                    {filters.return_error && <FormHelperText>{filters.return_error}</FormHelperText>}
                </FormControl>
            </Stack>
            {can_return && <Stack flex={'0 1 20%'}>
                <Select
                    value={filters.return_hour}
                    onChange={(event) => onFieldChange('return_hour', event.target.value)}
                    size="small"
                    sx={select_sx}
                    disabled={!!return_required}
                    MenuProps={menuProps}
                >
                    {return_avaliable_hours.map((hour) => (
                        <MenuItem key={`return-${hour}`} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>}
        </Stack>
    </Stack>);
};