import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from 'react-redux';
import green from "@material-ui/core/colors/green";
import GetPictureBySize from "../Functions/GetPictureBySize";
import AccommodationCardAmerigo from "./AccommodationCardAmerigo";
import AccommodationCardVerdie from "./AccommodationCardVerdie";
import AccommodationCardVisiteurs from './AccommodationCardVisiteurs';
import AccommodationCardGroups from "./AccommodationCardGroups";
import Info from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import GetCookie from '../../Common/Functions/GetCookie';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    card: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        maxHeight: 'calc(100vh - 95px)',
        overflowY: 'auto'
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    priceContainer: {
        marginBottom: theme.spacing(2)
    },
    perNight: {
        marginLeft: theme.spacing(1)
    },
    floatRight: {
        float: "right"
    },
    resortFeesIncluded: {
        color: "green"
    },
    orangeButton: {
        backgroundColor: `${main_color} !important`,
        color: "white !important",
        borderRadius: '24px !important'
    },
    beta: {
        fontSize: 12,
        fontStyle: 'italic',
        marginTop: 4,
        marginBottom: 0,
        textAlign: 'center'
    },
    invertedOrangeButton: {
        backgroundColor: 'white',
        border: `'1px solid ${main_color}`,
        color: `${main_color}`,
        // color: '#E6592F',
        borderRadius: 24
    },
    alignCenter: {
        textAlign: "center"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    providerWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    negMarginTop: {
        marginTop: -theme.spacing(1)
    },
    validateWrapper: {
        position: 'relative'
    },
    firstInfo: {
        position: 'absolute',
        marginTop: 4,
        marginLeft: 4
    },
    secondInfo: {
        float: 'right',
        marginLeft: 4
    },
    lineBreak: {
        whiteSpace: 'pre'
    }
}));

const AccommodationCardPrices = ({min_date, max_date, cart, pre_cart, prices, start_date, end_date, diff_day, onDateChange, group_modal_open, current_groups, current_travelers, toggleGroupModal, provider_loading, adding, prices_loaded, setNewGroups, getRoomPrices, onValidate, openStandalone, setNewGroupsFromHeader, destination, hotel_indentifier, setRoomMappingResults, accommodation_type}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(state => state.header.tmp_language);
    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.trip.currency);
    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    const currency_list = useSelector(state => state.base.currency_list);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const [roomMappingLoading, setRoomMappingLoading] = useState(false);
    const onRoomMapping = () => {
        dispatch({type: 'ACCOMMODATION_SET_ADDITIONAL_PRODUCTS', payload: []});
        setRoomMappingLoading(true);
        const requests = [];
        const grouped_by_passenger_group_id = [];

        const { headers } = CheckBeforeRequest();
        let rooms = "";
        for (let i = 0; i < current_groups.length; i++) {
            rooms += "&rooms=" + current_groups[i].id;
            grouped_by_passenger_group_id[current_groups[i].id] = [];
        }
        prices.map(price => {
            requests.push(axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/accommodations/rooms_prices/?${rooms}`,
                params: {
                    arrival_date: start_date,
                    departure_date: end_date,
                    custom_provider: price.data.custom,
                    hotels_identifiers: hotel_indentifier,
                    provider_id: price.data.id,
                    trip: GetCookie("trip_id"),
                    start_destination: destination.destination.id
                }
            }));
        });
        Promise.allSettled(requests).then((results) => {
            const final_rooms = [];
            results.map(result => {
                if (result.status === "fulfilled") {
                    let provider = null;
                    prices.map(price => {
                        if (price.data.id === result.value.config.params.provider_id) {
                            provider = price.data;
                        }
                    });
                    result.value.data.map(data => {
                        data.rooms.map(room => {
                            room.provider = provider;
                            room.variants.map(variant => {
                                variant.provider = provider;
                            });
                        });
                        grouped_by_passenger_group_id[data.passenger_group_id] = grouped_by_passenger_group_id[data.passenger_group_id].concat(data.rooms);
                    });
                }
            });
            const room_mapping_requests = [];
            Object.keys(grouped_by_passenger_group_id).map((key) => {
                const rooms_name = [];
                grouped_by_passenger_group_id[key].map(room => {
                    rooms_name.push(room.description);
                });
                room_mapping_requests.push(axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/accommodations/giata_room_mapping/`,
                    params: {
                        passenger_group_id: key
                    },
                    data: rooms_name
                }));
            });
            Promise.allSettled(room_mapping_requests).then((results) => {
                results.map(result => {
                    if (result.status === "fulfilled") {
                        const mapped = {
                            passenger_group_id: parseInt(result.value.config.params.passenger_group_id),
                            rooms: []
                        };
                        result.value.data.map(data => {
                            let variants = [];
                            let infos = {
                                cancel_condition_text: null,
                                check_in: null,
                                check_in_instructions: null,
                                check_out: null,
                                customer_service_phone: null,
                                description: data.groupName,
                                internet: null,
                                is_available: true,
                                max_passengers: null,
                                pictures: [],
                                room_code: "1",
                                roomTypes: data.roomTypes,
                                size_sq_feet: null,
                                size_sq_meters: null,
                                terms_n_conditions: null
                            };
                            grouped_by_passenger_group_id[result.value.config.params.passenger_group_id].map(room => {
                                if (data.roomTypes.includes(room.description)) {
                                    infos.cancel_condition_text = room.cancel_condition_text ?? infos.cancel_condition_text;
                                    infos.check_in = room.check_in ?? infos.check_in;
                                    infos.check_in_instructions = room.check_in_instructions ?? infos.check_in_instructions;
                                    infos.check_out = room.check_out ?? infos.check_out;
                                    infos.customer_service_phone = room.customer_service_phone ?? infos.customer_service_phone;
                                    infos.internet = room.internet ?? infos.internet;
                                    infos.is_available = room.is_available ?? infos.is_available;
                                    infos.max_passengers = room.max_passengers ?? infos.max_passengers;
                                    infos.room_code = room.room_code ?? infos.room_code;
                                    infos.size_sq_feet = room.size_sq_feet ?? infos.size_sq_feet;
                                    infos.size_sq_meters = room.size_sq_meters ?? infos.size_sq_meters;
                                    infos.terms_n_conditions = room.terms_n_conditions ?? infos.terms_n_conditions;
                                    infos.pictures = room.pictures ? infos.pictures.concat(room.pictures) : infos.pictures;
                                    variants = variants.concat(room.variants);
                                }
                            });
                            let has_breakfast_variant = false;
                            let has_lunch_variant = false;
                            let has_dinner_variant = false;
                            let has_cancellable_variant = false;
                            infos.price_from = null;
                            variants.map((variant) => {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        variant.showing_price = variant.price_stay[i].selling_price;
                                        variant.showing_currency = variant.price_stay[i].selling_currency;
                                        variant.fees_curreency = variant.price_stay[i].purchase_currency;
                                        if (variant.bed_type !== null) {
                                            variant.bed_type = variant.bed_type.split('|');
                                        }
                                        if (infos.price_from === null || parseFloat(infos.price_from) > parseFloat(variant.showing_price)) {
                                            infos.price_from = variant.showing_price;
                                            infos.price_from_currency = variant.showing_currency;
                                        }
                                    }
                                }
                                if (variant.breakfast_included) {
                                    has_breakfast_variant = true;
                                }
                                if (variant.lunch_included) {
                                    has_lunch_variant = true;
                                }
                                if (variant.dinner_included) {
                                    has_dinner_variant = true;
                                }
                                if (variant.all_inclusive) {
                                    has_breakfast_variant = true;
                                    has_lunch_variant = true;
                                    has_dinner_variant = true;
                                }
                                if (variant.is_cancellable) {
                                    has_cancellable_variant = true;
                                }
                            });
                            variants = variants.sort((a, b) => {
                                return parseFloat(a.showing_price) - parseFloat(b.showing_price);
                            });
                            mapped.rooms.push({
                                ...infos,
                                has_cancellable_variant: has_cancellable_variant,
                                has_breakfast_variant: has_breakfast_variant,
                                has_lunch_variant: has_lunch_variant,
                                has_dinner_variant: has_dinner_variant,
                                variants: variants
                            });
                        });
                        mapped.rooms = mapped.rooms.sort((a, b) => {
                            return parseFloat(a.price_from) - parseFloat(b.price_from);
                        });
                        final_rooms.push(mapped);
                    }
                });
                let current_group = {
                    group: current_groups[0],
                    index_group: 1
                };
                if (pre_cart.length > 0) {
                    let next = null;
                    current_groups.map((group, index_group) => {
                        let has_room = false;
                        pre_cart.map((room) => {
                            if (!has_room && group.id === room.passenger_group_id) {
                                has_room = true;
                            }
                        });
                        if (!has_room && next === null) {
                            next = {
                                group: group,
                                index_group: index_group + 1
                            };
                        }
                    });
                    current_group = next;
                }
                setRoomMappingResults({
                    provider_loading: null,
                    provider_loaded: null,
                    nb_results: final_rooms[0].rooms.length,
                    group_rooms: final_rooms,
                    current_group: current_group,
                    price_modal_open: false
                });
                setRoomMappingLoading(false);
            });
        });
    };
    const getIsoCode = (price_currency) => {
        let iso_code = currency.iso_code;
        if (price_currency !== null) {
            iso_code = currency_list.find((currency) => {
                return currency.id === price_currency;
            }).iso_code;
        }
        return iso_code;
    };
    useEffect(() => {
        setNewGroupsFromHeader();
    }, [traveler_groups]);
    return (
        <CardContent className={ classes.card }>
            {
                prices !== null || prices_loaded ? (
                    <div className={ classes.priceContainer }>
                        <Typography className={ `${classes.bold} ${classes.inline}` }>{ prices !== null && prices.length > 0 ? (new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(prices[0].price_currency) }).format(Math.ceil(parseFloat( prices[0].price / diff_day )))) : t("accommodation.full") }</Typography>
                        {
                            prices !== null && prices.length > 0 && (
                                <Typography className={ `${classes.inline} ${classes.perNight}` }> / { accommodation_type === 5 ? t("accommodation.cabin_price_night") : t("accommodation.room_price_night") }</Typography>
                            )
                        }
                    </div>
                ) : (
                    <Fragment>
                        <Skeleton/>
                        <br/>
                    </Fragment>
                )
            }
            <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                <DateRangePicker
                    startText={ t("accommodation.start_date") }
                    endText={ t("accommodation.end_date") }
                    value={[start_date, end_date]}
                    onChange={dates => onDateChange(dates)}
                    minDate={ moment.utc(min_date).subtract(1, 'days') }
                    maxDate={ moment.utc(max_date).add(1, 'days') }
                    renderInput={(startProps, endProps) => {
                        startProps.helperText = undefined;
                        startProps.fullWidth = true;
                        endProps.helperText = undefined;
                        endProps.fullWidth = true;
                        return (
                            <Fragment>
                                <TextField {...startProps} />
                                <DateRangeDelimiter>{ t("accommodation.to") }</DateRangeDelimiter>
                                <TextField {...endProps} />
                            </Fragment>
                        )
                    }}
                />
            </LocalizationProvider>
            <br/>
            {
                current_groups !== null && (
                    <AccommodationCardGroups group_modal_open={ group_modal_open } current_groups={ current_groups } current_travelers={ current_travelers } toggleGroupModal={ toggleGroupModal } setNewGroups={ setNewGroups } accommodation_type={ accommodation_type }/>
                )
            }
            {
                !group_modal_open && pre_cart.length > 0 && (
                    <Fragment>
                        <br/>
                        <div className={ classes.validateWrapper }>
                            <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } disabled={ adding } onClick={ onValidate }>{ t("accommodation.validate_cart") }</Button>
                            { adding && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                        </div>
                        {
                            current_groups.length !== pre_cart.length && (
                                <div>
                                    <br/>
                                    <Typography className={ `${classes.bold} ${classes.alignCenter}` }>{ t("accommodation.or_choose_from_another_provider") }</Typography>
                                </div>
                            )
                        }
                    </Fragment>
                )
            }
            {
                !group_modal_open && user.client_full.type === 2 && current_groups.length !== pre_cart.length && quotation_code === "amerigo" && prices !== null &&
                <AccommodationCardAmerigo prices={ prices } diff_day={ diff_day } iso_code={ getIsoCode(prices[0].price_currency) } language={ language } provider_loading={ provider_loading } getRoomPrices={ getRoomPrices }/>
            }
            {/*{*/}
            {/*    !group_modal_open && user.client_full.type === 2 && current_groups.length !== pre_cart.length && quotation_code === "verdie" && prices !== null &&*/}
            {/*    <AccommodationCardVerdie prices={ prices } diff_day={ diff_day } language={ language } provider_loading={ provider_loading } getRoomPrices={ getRoomPrices }/>*/}
            {/*}*/}
            {
                !group_modal_open && user.client_full.type === 2 && current_groups.length !== pre_cart.length && ['visiteurs', 'beachcomber'].includes(quotation_code) && prices !== null &&
                <AccommodationCardVisiteurs prices={ prices } diff_day={ diff_day } language={ language } provider_loading={ provider_loading } getRoomPrices={ getRoomPrices }/>
            }
            {
                !group_modal_open && (user.client_full.type !== 2 || quotation_code === "verdie") && current_groups.length !== pre_cart.length && prices !== null && prices.map((price, index_price) => {
                    if (index_price === 0 && pre_cart.length === 0) {
                        return (
                            <div key={ index_price } className={ classes.alignCenter }>
                                {
                                    GetPictureBySize(price.data.logo, "MEDIUM") !== "/Img/hotel.png" ?
                                        <img src={ GetPictureBySize(price.data.logo, "MEDIUM") } width={ "50%" } alt={ "provider logo" }/> :
                                        price.data.name
                                }
                                <Typography variant={ "h6" } className={ `${classes.bold} ${classes.paddingBottom}` } style={{position: "relative"}}>{ prices.length > 0 ? (new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(price.price_currency) }).format(Math.ceil(parseFloat( price.price / diff_day )))) : t("accommodation.full") }{ price.name !== null && <Tooltip className={ classes.lineBreak } title={ <span className={ classes.lineBreak }>{ price.name }</span> }><Info className={ `${classes.firstInfo} ${price.prices?.details?.resort_fees_included || price.prices?.details?.resort_fees === 0 ? classes.resortFeesIncluded : ''}` }/></Tooltip> }</Typography>
                                <div className={ classes.providerWrapper }>
                                    <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } disabled={ provider_loading === price.data.id } onClick={() => { getRoomPrices(price.data); } }>{ t("accommodation.choose") }</Button>
                                    { provider_loading === price.data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div key={ index_price }>
                            <br/>
                            {
                                GetPictureBySize(price.data.logo, "MEDIUM") !== "/Img/hotel.png" ?
                                    <img className={ classes.inline } src={ GetPictureBySize(price.data.logo, "MEDIUM") } width={ 75 } alt={ "provider logo" }/> :
                                    price.data.name
                            }
                            <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.floatRight} ${classes.negMarginTop}` }>
                                <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ provider_loading === price.data.id } onClick={() => { getRoomPrices(price.data); } }>{ t("accommodation.choose") }</Button>
                                { provider_loading === price.data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                            </div>
                            <Typography className={ `${classes.bold} ${classes.inline} ${classes.marginRight} ${classes.floatRight}` }>{ prices.length > 0 ? (new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(price.price_currency) }).format(Math.ceil(parseFloat( price.price / diff_day )))) : t("accommodation.full") }{ price.name !== null && <Tooltip title={ <span className={ classes.lineBreak }>{ price.name }</span> }><Info className={ `${classes.secondInfo} ${price.prices?.details?.resort_fees_included || price.prices?.details?.resort_fees === 0 ? classes.resortFeesIncluded : ''}` }/></Tooltip> }</Typography>
                        </div>
                    );
                })
            }
            {
                current_groups.length !== pre_cart.length && (quotation_code !== "visiteurs" || user.client_full.type !== 2) && prices !== null && prices.length > 1 &&
                <>
                    <LoadingButton className={ classes.orangeButton } loading={ roomMappingLoading } fullWidth onClick={ onRoomMapping } style={{marginTop: 8}}>
                        { t("accommodation.room-mapping") }
                    </LoadingButton>
                    <p className={ classes.beta }>(BETA)</p>
                </>
            }
            {
                current_groups.length !== pre_cart.length &&
                <Fragment>
                    <br/>
                    <Button variant={ "contained" } className={ classes.invertedOrangeButton } fullWidth={ true } onClick={() => { openStandalone(); } }>{ t("accommodation.add-standalone-hotel") }</Button>
                </Fragment>
            }
        </CardContent>
    )
};

export default React.memo(AccommodationCardPrices);