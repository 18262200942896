import { makeStyles } from "@material-ui/core"
import { AppBar, IconButton, Stack, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../Reducers/Reducers"
import { combineDateAndTimeWithLocalTimezone } from "./utils/dates/combineDateAndTime"
import moment from "moment"
import clsx from "clsx"
import { updateTrainDepartureForm, updateTrainDepartureSelected } from "./redux/reducer"
import { Close } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: "1440px",
        flexDirection: 'row',
        width: '100%',
        padding: '0 0.7rem',
    },
    appBar: {
        position: 'fixed',
        transform: 'translateY(-100px)',
        transition: 'transform 500ms ease-in-out !important',
        backgroundColor: 'black !important',
        padding: '0.5rem 0 !important',
        zIndex: '2 !important' as unknown as undefined,
        cursor: 'pointer'

    },
    appBarShowed: {
        transform: 'translateY(70px)',
        transition: 'transform 500ms ease-in-out !important',
    }
}));

export const TrainListHeader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const train_list_criteria_filters = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    moment.locale(i18n.language);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected]);

    const [isElementVisible, setIsElementVisible] = useState(true);

    const goBackOnDeparture = () => {
        dispatch(updateTrainDepartureForm({ ...train_departure_form!, selected_offer: undefined }));
        dispatch(updateTrainDepartureSelected(null));
    };

    const scrollToSearch = () => {
        window.scrollTo({
            top: 77,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const targetElement = document.querySelector('#train-search-bar');
        if (!targetElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsElementVisible(!!entry?.isIntersecting);
            },
            {
                root: null,
                threshold: 0,
            }
        );

        observer.observe(targetElement);

        return () => {
            observer.disconnect();
        };
    }, []);

    return <AppBar className={clsx(classes.appBar, { [classes.appBarShowed]: !isElementVisible })} onClick={scrollToSearch}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            <Stack className={classes.container}>
                <Stack direction='column' spacing={0.2}>
                    <Typography variant={'h5'} color={'white'}>{train_departure_selected ? t('global.Return') : t('global.go')} : {train_departure_selected ? `${train_list_criteria_filters.destination?.label} -> ${train_list_criteria_filters.origin?.label}` : `${train_list_criteria_filters.destination?.label} -> ${train_list_criteria_filters.origin?.label}`} </Typography>
                    <Typography variant="subtitle2" color={"rgb(255,255,255,0.6)"}>{
                        !train_departure_selected ?
                            moment(combineDateAndTimeWithLocalTimezone(train_list_criteria_filters.departure, train_list_criteria_filters.departure_hour)).format(`dddd D MMMM, [${t('shared.from-hour')}] HH:mm`)
                            : moment(combineDateAndTimeWithLocalTimezone(train_list_criteria_filters.return, train_list_criteria_filters.return_hour)).format(`dddd D MMMM, [${t('shared.from-hour')}] HH:mm`)
                    }</Typography>
                </Stack>
            </Stack>
            {
                !is_departure && <IconButton onClick={goBackOnDeparture}><Close sx={{ color: 'white' }}/></IconButton>
            }
        </Stack>
    </AppBar>
}