import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import { TrainCart } from "../objects/trainCart";

const { headers } = CheckBeforeRequest();
export const createGroupTravelers = (travelers: number[]) => axios.post(
    `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
    {
        travelers: travelers,
        default: false
    },
    { headers }
);

export const addTrainToCart = (train_cart: TrainCart) => axios.post(
    `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/train/?token=${GetCookie("trip_token")}`,
    { ...train_cart },
    { headers }
);