import { useMemo } from "react";
import { useSelector } from "react-redux";
import { sortProducts } from "../../CartMaterial/utils/sortProducts";
import { useCartProducts } from "./cartProducts";
import { useApplyDaysFilter } from "../../CartMaterial/utils/applyDaysFilter";
import { useProductDays } from "../../CartMaterial/utils/productDays";
import { BookingStatus } from "../objects/bookingStatus";
import { CartProductStatus } from "../../CartMaterial/CartConstructionProductsTableItemMenu";
import { Creator } from "../../Menu/MaterialTripList/objects/creator";
import { AppState } from "../../../Reducers/Reducers";

export function useFilteredCartProducts() {
    const show_cancelled_products = useSelector((state: AppState) => state.cart.show_cancelled_products);
    const productTypeFilters = useSelector((state: AppState) => state.cart.product_type_filters);
    const manualProducts = useSelector((state: AppState) => state.cart.manual_item_list);
    const filters_masked_items = useSelector((state: AppState) => state.cart.filters_masked_items);
    const filters_visible_items = useSelector((state: AppState) => state.cart.filters_masked_items);
    const filters_cancellable_items = useSelector((state: AppState) => state.cart.filters_cancellable_items);
    const filters_non_cancellable_items = useSelector((state: AppState) => state.cart.filters_non_cancellable_items);
    const productsMode = useSelector((state: AppState) => state.cartConstruction.productsMode);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const user = useSelector((state: AppState) => state.user.user);
    const statusFilters = useSelector((state: AppState) => state.cartConstruction.filters.status);
    const cart = useCartProducts();
    const applyDaysFilter = useApplyDaysFilter();
    const getDays = useProductDays();
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    return useMemo(() => {
        const applyFilters = (
            type: keyof typeof cart | 'manual',
            isManual: boolean,
            product: {
                product_type: number,
                poi_type: boolean,
                booking_status: BookingStatus | null,
                booking_process_state: string,
                creator: Creator | null,
                start_date: string | null,
                is_displayed: boolean,
                is_hidden_for_traveler: boolean,
                is_cancellable: boolean,
                is_optional: boolean,
                is_hidden_for_traveler_count_price: boolean,
                force_to_display?: boolean,
                variant: unknown,
            }
        ) => {
            const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
            if (
                product.is_displayed &&
                (
                    quotation_code !== 'marcovasco' ||
                    (
                        (product.booking_status && product.booking_status.status_booking !== 'CANCELLED') ||
                        product.booking_process_state !== 'CANCEL'
                    ) ||
                    show_cancelled_products
                )
            ) {
                if (isManual && !applyManualProductsFilter(product)) {
                    return false;
                }

                if (!applyProductTypeFilter(type, product)) {
                    return false;
                }
    
                if (!applyMaskedAndVisibleItemsFilters(product)) {
                    return false;
                }
    
                if (!applyCancellableItemsFilters(product)) {
                    return false;
                }

                if (!applyStatusFilter(product)) {
                    return false;
                }
    
                return true;
            }
            return false;
        };

        const applyManualProductsFilter = (product: {product_type: number, creator: Creator | null, force_to_display?: boolean}) => {
            if (user?.client_full?.type !== 2) {
                return (
                    !product.creator ||
                    product.creator.client?.type !== 2
                ) ||
                quotationCode === 'verdie' ||
                product.force_to_display;
            }
            return true;
        };

        const applyProductTypeFilter = (
            type: keyof typeof cart | 'manual',
            product: {start_date: string | null, product_type: number, poi_type: boolean}
        ) => {
            //show all products
            //@TODO: find another solution
            if (!productTypeFilters || [0, 8].includes(productTypeFilters.length)) {
                return true;
            }

            for (const filterItem of productTypeFilters) {
                switch (filterItem.type) {
                    case 0: {
                        if (type === 'accommodations') {
                            return true;
                        }
                        break;
                    }
                    case 2: {
                        if (type === 'cars') {
                            return true;
                        }
                        break;
                    }
                    case 4: {
                        if (type === 'transfers') {
                            return true;
                        }
                        break;
                    }
                    case 6: {
                        if (type === 'flights') {
                            return true;
                        }
                        break;
                    }
                    case 5: {
                        if (type === 'trains') {
                            return true;
                        }
                        break;
                    }
                    case 8: {
                        if (type === 'insurances' || type === 'assistances') {
                            return true;
                        }
                        break;
                    }
                    case 12: {
                        if (type === 'pois' && !product.poi_type) {
                            return true;
                        }
                        break;
                    }
                    case 998: {
                        if (type === 'pois' && product.poi_type) {
                            return true;
                        }
                        break;
                    }
                    case 999: {
                        if (type === 'manual' && getDays(product.start_date ?? '').length === 0) {
                            return true;
                        }
                        break;
                    }
                }
            }
            return false;
        };

        const applyMaskedAndVisibleItemsFilters = (product: {is_hidden_for_traveler: boolean}) => {
            if (filters_masked_items && !filters_visible_items) {
                return (product.is_hidden_for_traveler);
            } else if (!filters_masked_items && filters_visible_items) {
                return (!product.is_hidden_for_traveler);
            }
            return true;
        };

        const applyCancellableItemsFilters = (product: {is_cancellable: boolean}) => {
            if (filters_cancellable_items && !filters_non_cancellable_items) {
                return (product.is_cancellable);
            } else if (!filters_cancellable_items && filters_non_cancellable_items) {
                return (!product.is_cancellable);
            }
            return true;
        };

        const applyStatusFilter = (
            product: {
                is_optional: boolean,
                is_hidden_for_traveler: boolean,
                is_hidden_for_traveler_count_price: boolean,
                variant: unknown,
            }
        ) => {
            let status: CartProductStatus = 'DEFAULT';

            if (product.is_optional) {
                status = 'OPTION';
            } else if (product.is_hidden_for_traveler) {
                status = 'HIDE';
            } else if (product.is_hidden_for_traveler_count_price) {
                status = 'HIDE_COUNT';
            } else if (product.variant !== null && itinerary_type_details_data !== null && itinerary_type_details_data.variant_name !== null) {
                status = 'VARIANT';
            }

            return statusFilters.includes(status);
        };

        return {
            flights: cart.flights.filter((item) => {
                if (
                    !applyFilters(
                        'flights',
                        item.type === 'manual',
                        { ...item.flight, poi_type: false }
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.flight.start_date, item.flight.end_date);
            }).sort((a, b) => {
                return sortProducts(a.flight, b.flight);
            }),
            trains: cart.trains.filter((item) => {
                if (
                    !applyFilters(
                        'trains',
                        item.type === 'manual',
                        { ...item.train, poi_type: false }
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.train.start_date, item.train.end_date);
            }).sort((a, b) => {
                return sortProducts(a.train, b.train);
            }),
            cars: cart.cars.filter((item) => {
                if (
                    !applyFilters(
                        'cars',
                        item.type === 'manual',
                        { ...item.car, poi_type: false }
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.car.start_date, item.car.end_date);
            }).sort((a, b) => {
                return sortProducts(a.car, b.car);
            }),
            accommodations: cart.accommodations.filter((item) => {
                if (
                    !applyFilters(
                        'accommodations',
                        item.type === 'manual',
                        { ...item.accommodation, poi_type: false }
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.accommodation.start_date, item.accommodation.end_date);
            }).sort((a, b) => {
                return sortProducts(a.accommodation, b.accommodation);
            }),
            transfers: cart.transfers.filter((item) => {
                if (
                    !applyFilters(
                        'transfers',
                        item.type === 'manual',
                        item.transfer
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.transfer.start_date, item.transfer.end_date);
            }).sort((a, b) => {
                return sortProducts(a.transfer, b.transfer);
            }),
            assistances: cart.assistances.filter((item) => {
                if (
                    !applyFilters(
                        'assistances',
                        item.type === 'manual',
                        { ...item.assistance, poi_type: false }
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.assistance.start_date, item.assistance.end_date);
            }).sort((a, b) => {
                return sortProducts(a.assistance, b.assistance);
            }),
            pois: cart.pois.filter((item) => {
                if (
                    !applyFilters(
                        'pois',
                        item.type === 'manual',
                        item.poi
                    )
                ) {
                    return false;
                }
                return productsMode === 'all-days' ||
                       applyDaysFilter(item.poi.start_date, item.poi.end_date);
            }).sort((a, b) => {
                return sortProducts(a.poi, b.poi);
            }),
            manualProducts: (
                manualProducts?.filter((item) => {
                    if (
                        !applyFilters(
                            'manual',
                            true,
                            item
                        )
                    ) {
                        return false;
                    }
                    return ![2, 0, 1, 12, 5, 6, 4].includes(item.product_type) &&
                           (
                               productsMode === 'all-days' ||
                               (
                                   applyDaysFilter(item.start_date, item.end_date) ||
                                   !window.moment.utc(item.start_date).isValid()
                               )
                           );
                }) ?? []
            ).sort((a, b) => {
                return sortProducts(a, b);
            })
        };
    }, [
        cart,
        manualProducts,
        getDays,
        applyDaysFilter,
        productsMode,
        productTypeFilters,
        show_cancelled_products,
        itinerary_type_details_data,
        statusFilters,
        filters_masked_items,
        filters_visible_items,
        filters_cancellable_items,
        filters_non_cancellable_items,
        quotationCode
    ]);
}
