import React, { FC } from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { TripTrainLeg, TripTrainLegSegment } from "./objects/trainCart";
import { Divider, Typography } from "@mui/material";
import { AirlineSeatReclineExtra, Schedule, Train } from "@mui/icons-material";
import { getProviderIcon } from "./utils/provider/getProviderIcon";


const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    bold: {
        fontWeight: "bold"
    },
    fontSize9: {
        fontSize: '0.85rem'
    },
    fontSize12: {
        fontSize: '1rem'
    },
    fontSize14: {
        fontSize: '1.15rem'
    },
    lightGrey: {
        color: '#00000040'
    },
    red: {
        color: "#FF0000"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px 0px"
    },
    spacerSmall: {
        padding: 4
    },
    marginIcon: {
        marginTop: 6
    },
    redCircle: {
        border: "3px solid #FF0000",
        width: 15,
        height: 15,
        borderRadius: 8
    },
    redLine: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 50,
        marginLeft: 5
    },
    redLineSmall: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 29,
        marginLeft: 5
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 28,
        left: 47
    },
    durationDaysPositionSmall: {
        position: "relative",
        bottom: 23,
        left: 32
    }
}));

interface TrainCartMainContentLegSegmentDetailProps {
    trip_leg: TripTrainLeg,
    leg: TripTrainLegSegment,
    leg_index: number,
    max_index: number,
    stopover_duration: string | null,
    // duration_days: number,
    small: boolean
}

export const TrainCartMainContentLegSegmentDetail: FC<TrainCartMainContentLegSegmentDetailProps> = ({ trip_leg, leg, leg_index, max_index, stopover_duration, small }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));

    const language = i18n.language;

    // const airlineObj = leg.operating_airline;

    const segment_duration = `${Math.floor(require('moment').duration(leg.duration).asHours())}h${require('moment').duration(leg.duration).minutes()}m`;


    moment.locale(language);


    return (<Grid className={(isTablet || small) ? "" : classes.spacer}>
        <Grid container alignItems="baseline" justify="flex-start" spacing={1} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
            <Grid item>
                <img
                    src={getProviderIcon(leg!.marketing_information, {operatingCarrier: leg?.operating_carrier, marketingCarrier: leg?.marketing_carrier})} alt={"provider logo"}
                    style={{
                        width: 100,
                        height: 30,
                        maxWidth: 100,
                        maxHeight: 30,
                        objectFit: 'contain'
                    }}
                />
            </Grid>
            <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize12}`} style={{ display: 'flex', alignItems: 'baseline', gap: 2}}>
                {leg.marketing_carrier}
            </Grid>
        </Grid>
        <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 3} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
            <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                {t("global.flight") + " " + (leg_index + 1)}
            </Grid>
            <Grid item>
                <div className={classes.redCircle} />
                <div className={(isTablet || small) ? classes.redLineSmall : classes.redLine} />
                <div className={classes.redCircle} />
            </Grid>
            <Grid item>
                <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                        {t("flight_search.departure")}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`} style={small ? { paddingLeft: 9 } : { paddingLeft: 15 }}>
                        {moment.utc(leg.departure_datetime).format("DD MMM")}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}>
                        {language === "fr" ? moment.utc(leg.departure_datetime).format("HH[h]mm") : moment.utc(leg.departure_datetime).format("HH[:]mm")}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                        {leg.origin_code}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                        {
                            leg.origin_label
                        }
                        {/* {leg.origin_terminal !== null && leg.origin_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.origin_terminal : ""} */}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                    <Grid item style={{ paddingRight: 0 }}>
                        <Schedule className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                    </Grid>
                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                        {segment_duration}
                    </Grid>
                    {
                        (
                            <Grid item style={{ paddingRight: 0 }}>
                                <Train className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                            </Grid>
                        )
                    }
                    {
                        (
                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`} style={{ display: 'flex', alignItems: 'baseline'}}>
                                {leg.vehicle_type}  <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>n° {leg.vehicle_reference}</Typography>
                            </Grid>
                        )
                    }
                    {
                        (
                            <Grid item style={{ paddingRight: 0 }}>
                                <AirlineSeatReclineExtra className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                            </Grid>
                        )
                    }
                    {
                        (
                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                {t(`train.comfort.${trip_leg.offer.comfort_category_code}`)}, {t(`train.flexibility.${trip_leg.offer.flexibility_code}`)}
                            </Grid>
                        )
                    }
                </Grid>
                <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                        {t("flight_search.arrival")}
                    </Grid>
                    {/* <Grid item
                        className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}
                        style={(leg_index === max_index && duration_days !== undefined && duration_days !== 0) ? (small ? { marginBottom: -13 } : { marginBottom: -12 }) : {}}>
                        {moment.utc(leg.arrival_datetime).format("DD MMM")}
                        {
                            leg_index === max_index && duration_days !== undefined && duration_days !== 0 && (
                                <div className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${small ? classes.durationDaysPositionSmall : classes.durationDaysPosition}`}>
                                    {"+" + duration_days + t("global.abrev_day")}
                                </div>
                            )
                        }
                    </Grid> */}
                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}>
                        {language === "fr" ? moment.utc(leg.arrival_datetime).format("HH[h]mm") : moment.utc(leg.arrival_datetime).format("HH[:]mm")}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                        {leg.destination_code}
                    </Grid>
                    <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                        { leg.destination_label }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {
            leg_index !== max_index && (
                <Grid className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                    <Divider />
                    <Grid className={`${(isTablet || small) ? classes.spacerSmall : classes.spacer} ${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.textCenter}`}>
                        <div className={classes.genericText}>
                            {
                                t("flight_search.change_train") + leg.destination_label + " - "
                            }
                            <span className={classes.bold}>
                                {
                                    leg.destination_label
                                }
                                {/* {` (${leg.destination !== undefined && leg.destination !== null ? leg.destination.airport_code : leg.destination_station.station_code})`} */}
                            </span>
                        </div>
                        <div className={classes.genericText}>
                            {t("flight_search.stopover_duration")}
                            <span className={classes.bold}>
                                {/*{ ConvertTime(stopover_duration, "seconds", language, t) } */}
                                {stopover_duration ? 
                                moment.utc(stopover_duration).format('HH[h]mm') :
                                t('shared.stopover-duration-too-short')}
                            </span>
                        </div>
                        <div className={classes.genericText}>
                            {t("train.stopover_change")}
                        </div>
                    </Grid>
                    <Divider />
                </Grid>
            )
        }
    </Grid>);
}