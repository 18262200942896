import React, { FC } from "react";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";
import { Box, Divider } from "@mui/material";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { ReportProblemOutlined } from "@mui/icons-material";
import { CartProductIconText } from "../CartMaterial/CartProductIconText";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";

interface TrainCartCardFooterProps {
    train: TrainCartFromBackend
}

export const TrainCartCardFooter: FC<TrainCartCardFooterProps> = (props) => {
    const { t } = useTranslation();
    const trip = useSelector((state: AppState) => state.trip.data_trip);

    return (!isProductPackaged({
        product: props.train,
        stackInfos: trip?.stack_info ?? null
    }) &&
        !props.train.is_cancellable ?
        <>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ whiteSpace: 'nowrap' }}>
                <CartProductIconText
                    text={t('cart-material.not-cancellable')}
                    startIcon={ReportProblemOutlined}
                    color="#FF8100"
                />
            </Box>
        </> :
        undefined);
}