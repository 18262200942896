import axios, { AxiosResponse } from 'axios';

export const translateText = (
  value: string | string[], 
  value_lang: string, 
  target_lang: string
): Promise<AxiosResponse> => {
  let google_key = null;
  const config = JSON.parse(localStorage?.getItem("config") || '{}'); // Handle missing or invalid JSON
  
  // Find the Google API key in the configuration
  if (config.keys && Array.isArray(config.keys)) {
    const googleKeyConfig = config.keys.find((key: { identifier: string }) => key.identifier === "google_api");
    google_key = googleKeyConfig ? googleKeyConfig.value : null;
  }

  if (!google_key) {
    throw new Error("Google API key not found in configuration.");
  }

  // Prepare the data for translation
  const data = Array.isArray(value) ? value : [value]; // Ensure `value` is always an array

  return axios({
    method: 'POST',
    url: `https://translation.googleapis.com/language/translate/v2`,
    params: {
      key: google_key,
    },
    data: {
      q: data, // Google API supports an array of strings
      source: value_lang,
      target: target_lang,
      format: 'text'
    }
  });
};
