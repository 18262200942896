import React, { FC, useEffect, useMemo, useState } from "react"
import { TrainForm } from "./objects/trainForm";
import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { makeStyles } from "@material-ui/core";
import { ComfortCategory, SolutionOffer } from "./objects/backendData/trainBackendDate";
import clsx from "clsx";
import { ServiceTypeIcons } from "./components/services/serviceTypeIcon";
import { getExchangeStatus } from "./utils/cancelationFunction/getCancelationStatus";
import { ExpandCircleDownRounded } from "@mui/icons-material";
import { useShowError } from "../Utils/showError";
import { translateText } from "./services/translation";
import { updateTrainDepartureSelected, updateTrainReturnSelected } from "./redux/reducer";

const useStyles = makeStyles((theme) => ({
    comfortCodeContainer: {
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        overflow: 'hidden'
    },
    comfortCode: {
        padding: '16px 8px',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(0,0,0,0.1)'
        }
    },
    comfortCodeSelected: {
        backgroundColor: 'rgb(20, 112, 138)',
        color: 'white',
        position: 'relative',
        '&:hover': {
            backgroundColor: 'rgb(20, 112, 138, 0.9) !important'
        },
        '&:before': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            width: '20px',
            height: '4px',
            backgroundColor: 'white',
            zIndex: 1,
            left: 'calc(50% - 10px)',
        }
    },
    offerButton: {
        color: "white !important",
        padding: '0.5rem 1.5rem !important',
        backgroundColor: "rgb(20, 112, 138) !important",
        borderRadius: '1.5rem !important',
        '&:hover': {
            backgroundColor: 'rgb(20, 112, 138, 0.9) !important'
        },
    }
}));
interface TrainListContentFormTarifOffersDetailProps {
    offer: SolutionOffer;
    setTrainFormAction: (train_form: TrainForm | null) => void;
    train_form: TrainForm;
}

const TrainListContentFormTarifOffersDetail: FC<TrainListContentFormTarifOffersDetailProps> = ({ offer, train_form, setTrainFormAction }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const showError = useShowError();
    const exchage_status = getExchangeStatus(offer.fare_offers);
    const currencies = useSelector((state: AppState) => state.train.currencies);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const { last_edited_offer } = train_form;

    const [detail_open, setDetailOpen] = useState<boolean>(false);
    const [fare_offers, setFareOffers] = useState<{ names: string[]; price: number; currency: number }[]>([]);
    const classes = useStyles();
    const openDetail = () => {
        !detail_open && setDetailOpen(true);
    };
    const closeDetail = () => {
        setDetailOpen(false);
    };
    const openPricingConditions = () => {
        dispatch(setTrainFormAction({ ...train_form, offer_price_condition: offer, last_edited_offer: offer }));
    };
    const selectOffer = () => {
        if (can_return && is_departure && train_form) {
            dispatch(updateTrainDepartureSelected({ ...train_form, selected_offer: offer, last_edited_offer: offer }));
            // dispatch(setTrainFormAction(null));
        } else {
            dispatch(setTrainFormAction({ ...train_form, selected_offer: offer, last_edited_offer: offer }));
        }
    };
    const getFareOffers = async () => {
        try {
            for (const fare_offer of offer.fare_offers) {
                const names: string[] = [];
                fare_offer.fares.map((fare) => {
                    names.push(fare.name);
                });
                const response = await translateText(names, 'en', i18n.language);
                const translatedNames = response.data.data.translations.map((translation: any) => translation.translatedText);
                setFareOffers(state => ([...state, { names: translatedNames, price: fare_offer.prices[0]!.purchase_price, currency: fare_offer.prices[0]!.purchase_currency_id }]))
            }

        } catch (e) {
            showError(e as Error);
        }
    };

    useEffect(() => {
        last_edited_offer && setDetailOpen(last_edited_offer?.id === offer.id);
    }, [last_edited_offer])
    useEffect(() => {
        setDetailOpen(offer.id === train_form?.offer?.id);
    }, [offer, train_form])

    useEffect(() => {
        getFareOffers();
    }, [offer.fare_offers, i18n.language]);


    return <Stack direction={'column'} sx={{ borderRadius: '0.5rem', overflow: 'hidden' }}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ padding: '0.75rem 1rem', backgroundColor: 'rgb(218, 234, 243)' }}>
            <Stack>
                <Typography variant="body2" sx={{ fontSize: '1.125rem', lineHeight: '1.5rem', fontWeight: 700 }}>{t(`train.comfort.${offer.comfort_category.code}`)}, {t(`train.flexibility.${offer.flexibility.code}`)}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
                {offer.service_offers.map((service, index) => {
                    const Icon = ServiceTypeIcons[service.service_type];
                    return index < 3 ? <Tooltip key={`offer-service-${service.service_id}-${service.service_type}`} title={t(`train.service.${service.service_type}`)}><Icon sx={{ width: '20px', height: '20px', color: 'rgb(0,0,0,0.6)' }} /></Tooltip> : <></>
                })}
                {offer.service_offers.length > 3 ? <Typography variant="caption" sx={{ fontSize: '1rem' }}>{` + ${offer.service_offers.length - 3}`}</Typography> : ''}
            </Stack>
        </Stack>
        <Stack direction={'column'} sx={{ padding: '1rem', backgroundColor: 'white', cursor: detail_open ? 'default' : 'pointer', '&:hover': detail_open ? undefined : { backgroundColor: 'rgb(255,255,255,0.5)' } }} onClick={openDetail} gap={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="body2" sx={{ fontSize: '1rem', lineHeight: '1.375rem', fontWeight: 500 }}>{t(`train.cancelation.${exchage_status}`)}</Typography>
                <Typography variant="h1" sx={{ fontSize: '1.875rem', lineHeight: '2.375rem', fontWeight: 700 }}>{
                    new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        minimumFractionDigits: (offer.prices[0]?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                        currency: currencies[offer.prices[0]!.purchase_currency_id]?.iso_code || 'EUR'
                    }).format(offer.prices[0]?.purchase_price ?? 0)
                }</Typography>
            </Stack>
            {!detail_open && <Stack sx={{ color: 'rgb(20, 112, 138)', cursor: 'pointer', '&:hover': { color: 'rgb(20, 112, 138, 0.9)' } }} direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0.5}>{t('train.offer.open-detail')} <ExpandCircleDownRounded sx={{ color: 'rgb(20, 112, 138)', '&:hover': { color: 'rgb(20, 112, 138, 0.9)' } }} /> </Stack>}

            {detail_open && <Typography variant="subtitle1" sx={{ color: 'rgb(20, 112, 138)', cursor: 'pointer', fontSize: '0.8rem', textAlign: 'center' }} onClick={openPricingConditions}>{t('train.offer.price-detail')}</Typography>}

            {detail_open && offer.service_offers?.length > 0 &&
                <Stack padding={'1rem'} direction={'column'} spacing={1} sx={{ backgroundColor: 'rgb(243, 243, 248)', borderRadius: '1.5rem' }}>
                    <Stack><Typography variant="h5" sx={{ fontWeight: 500, fontSize: '1.2rem' }}>{t('train.offer.our-services')}</Typography></Stack>
                    <Stack direction={'row'} flexWrap={'wrap'} >
                        {offer.service_offers.map((service) => {
                            const Icon = ServiceTypeIcons[service.service_type];
                            return <Stack direction={'row'} flex={'0 1 50%'} spacing={2} key={`offer-service-detail-${service.service_id}-${service.service_type}`}><Icon sx={{ width: '20px', height: '20px' }} /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>{t(`train.service.${service.service_type}`)}</Typography></Stack>
                        })}
                    </Stack>
                </Stack>
            }

            {detail_open && <Stack direction={'column'} spacing={1}>
                {fare_offers.map((fare_offer, index) => (<>
                    <Stack key={`fare-offer-list-${index}`} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                        <Stack direction={'column'} spacing={0.5}>
                            {fare_offer.names.map((name, name_index) => <Typography key={`fare-offer-name-${index}-${name_index}`}>{name}</Typography>)}
                        </Stack>
                        <Stack><Typography variant='h5' sx={{ fontWeight: 700, fontSize: '1rem' }}>
                            {new Intl.NumberFormat(i18n.language, {
                                style: 'currency',
                                minimumFractionDigits: (fare_offer.price ?? 0) % 1 === 0 ? 0 : 2,
                                currency: currencies[fare_offer.currency]?.iso_code || 'EUR'
                            }).format(fare_offer.price ?? 0)}
                        </Typography></Stack>
                    </Stack>

                    {index + 1 < fare_offers.length && <Stack flex={'1 1 100%'}>
                        <Divider sx={{ borderColor: "rgb('0,0,0,0.3')", borderWidth: '1.5px' }} />
                    </Stack>}
                </>))}
            </Stack>}

            {detail_open && <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                <Button className={classes.offerButton} onClick={selectOffer}>
                    {t(is_departure ? 'train.offer.choose-offer-outbound' : 'train.offer.choose-offer-return', {
                        amount: new Intl.NumberFormat(i18n.language, {
                            style: 'currency',
                            minimumFractionDigits: (offer.prices[0]?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                            currency: currencies[offer.prices[0]!.purchase_currency_id]?.iso_code || 'EUR'
                        }).format(offer.prices[0]?.purchase_price ?? 0)
                    })}</Button>
            </Stack>}

            {detail_open && <Stack sx={{ color: 'rgb(20, 112, 138)', cursor: 'pointer', '&:hover': { color: 'rgb(20, 112, 138, 0.9)' } }} onClick={closeDetail} direction={'row'} justifyContent={'center'} alignItems={'center'} gap={0.5}>{t('train.offer.close-detail')} <ExpandCircleDownRounded sx={{ color: 'rgb(20, 112, 138)', transform: 'rotate(180deg)', '&:hover': { color: 'rgb(20, 112, 138, 0.9)' } }} /> </Stack>}
        </Stack>
    </Stack>;
};


interface TrainListContentFormTarifOfferProps {
    setTrainFormAction: (train_form: TrainForm | null) => void;
}
export const TrainListContentFormTarifOffers: FC<TrainListContentFormTarifOfferProps> = ({ setTrainFormAction }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const currencies = useSelector((state: AppState) => state.train.currencies);
    const train_return_form = useSelector((state: AppState) => state.train.train_return_form);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const train_form = useMemo(() => (is_departure ? train_departure_form : train_return_form), [is_departure, train_departure_form, train_return_form]);
    const offer = useMemo(() => train_form?.offer, [train_form]);
    const available_offers = useMemo(() => train_form?.solution.offers || [], [train_form?.solution.offers]);
    const available_offers_by_comfort = available_offers.reduce((acc, offer) => {
        if (acc[offer.comfort_category.code]) {
            acc[offer.comfort_category.code]!.push(offer);
        } else {
            acc[offer.comfort_category.code] = [offer];
        }
        return acc;
    }, {} as Record<ComfortCategory['code'], SolutionOffer[]>);


    const selectOffer = (comfort_code: ComfortCategory['code']) => () => {
        if (train_form && available_offers_by_comfort[comfort_code] && (available_offers_by_comfort[comfort_code].length || 0) > 0) {
            dispatch(setTrainFormAction({ ...train_form, offer: available_offers_by_comfort[comfort_code][0] as SolutionOffer, last_edited_offer: available_offers_by_comfort[comfort_code][0] as SolutionOffer }));
        }
    };

    return <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'} className={classes.comfortCodeContainer}>
            {Object.keys(available_offers_by_comfort).map((comfort_code) => <Stack key={`available-offer-comfort-category-${comfort_code}`} className={clsx(classes.comfortCode, { [classes.comfortCodeSelected]: offer?.comfort_category.code === comfort_code })} onClick={selectOffer(comfort_code)}>
                <Typography>{t(`train.comfort.${comfort_code}`)}</Typography>
                <Typography sx={{ fontSize: '0.8rem', textAlign: 'center' }}>{t('train.comfort-detail', {
                    count: available_offers_by_comfort[comfort_code]!.length,
                    amount: new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        minimumFractionDigits: (available_offers_by_comfort[comfort_code]![0]!.prices[0]?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                        currency: currencies[available_offers_by_comfort[comfort_code]![0]!.prices[0]!.purchase_currency_id]?.iso_code || 'EUR'
                    }).format(available_offers_by_comfort[comfort_code]![0]!.prices[0]?.purchase_price ?? 0)
                })}</Typography>
            </Stack>)}
        </Stack>
        {
            available_offers_by_comfort[offer?.comfort_category.code || '']?.map((offer) => <TrainListContentFormTarifOffersDetail train_form={train_form!} setTrainFormAction={setTrainFormAction} key={`available-offer-by-comfort-${offer.id}`} offer={offer} />)
        }
    </Stack>;
};