import { makeStyles } from "@material-ui/core";
import { Stack, Typography, Button, Divider } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrainForm } from "./objects/trainForm";
import { ArrowCircleLeftRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { FareOffersEntity, FaresEntity } from "./objects/backendData/trainBackendDate";
import { UserIcon } from "./assets/trainIcon";
import { getExchangeRefundableStatus } from "./utils/cancelationFunction/getCancelationStatus";
import { translateText } from "./services/translation";
import { travelerColor } from "./style/colors";
import { cleanTrailingEmptyTags } from "./utils/htmlText/clearTrailingEmptyTags";



const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        height: '100vh',
        flexDirection: 'column',
        backgroundColor: '#F3F3F8'
    },
    drawerHeader: {
        padding: '1rem 1rem',
        backgroundColor: 'black'
    },
    drawerContent: {
        flex: '0 1 100%',
        overflow: 'auto',
        flexDirection: 'column',
        padding: '0 16px'
    },
}));
interface TrainListContentFormTarifOfferPricingCoonditionsFareProps {
    fare: FaresEntity;
    fare_index: number;
    fares_lenght: number;
}

export const TrainListContentFormTarifOfferPricingCoonditionsFare: FC<TrainListContentFormTarifOfferPricingCoonditionsFareProps> = ({ fare, fare_index, fares_lenght }) => {
    const { t, i18n } = useTranslation();
    const travelers = useSelector((state: AppState) => state.train.train_travelers);
    const train_return_form = useSelector((state: AppState) => state.train.train_return_form);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_departure_form = useSelector((state: AppState) => state.train.train_departure_form);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const train_form = useMemo(() => (is_departure ? train_departure_form : train_return_form), [is_departure, train_departure_form, train_return_form]);
    const conserned_segments = useMemo(() => train_form?.solution.segments.filter(segment => fare.segments?.includes(segment.id)), [train_form?.solution.segments])
    const considered_travelers = useMemo(() => travelers.filter((traveler) => fare.travelers?.includes(traveler.id)), [travelers]);
    const exchage_status = getExchangeRefundableStatus(fare.product.exchangeable, fare.product.refundable);
    // {fare.product.cabin_class.label},

    const [translatedCondition, setTranslatedCondition] = useState('');
    const [translatedName, setTranslatedName] = useState('');

    const translateCondition = async () => {
        try {
            const response = await translateText(fare.product.conditions, 'en', i18n.language);
            const translatedText = response.data.data.translations[0].translatedText;
            setTranslatedCondition(cleanTrailingEmptyTags(translatedText));
        } catch (error) {
            console.error('Error translating condition:', error);
        }
    };
    const translateName = async () => {
        try {
            const response = await translateText(fare.name, 'en', i18n.language);
            const translatedText = response.data.data.translations[0].translatedText;
            setTranslatedName(translatedText);
        } catch (error) {
            console.error('Error translating condition:', error);
        }
    };

    // Use useEffect to watch for changes
    useEffect(() => {
        if (fare.product.conditions) {
            translateCondition();
        }
    }, [fare.product.conditions, i18n.language]);


    useEffect(() => {
        if (fare.name) {
            translateName();
        }
    }, [fare.name, i18n.language]);

    return <Stack direction={'column'} spacing={1}>
        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>{translatedName}</Typography>
        <Typography variant='subtitle1' sx={{ fontSize: '1rem' }}>{t(`train.comfort.${fare.product.cabin_class.comfort_category.code}`)}, {t(`train.flexibility.${fare.product.flexibility.code}`)}, {t(`train.cancelation.${exchage_status}`)}</Typography>
        <Stack direction={'row'} flexWrap={'wrap'}>
            {

                conserned_segments?.map((segment, segment_index) =>
                    <Stack key={`fare-segment-${fare.id}-${segment.id}`}>
                        <Typography variant='body1' sx={{ fontSize: '1rem', fontWeight: 500 }}>{segment.vehicle.type} n° {segment.vehicle.reference}{segment_index + 1 < conserned_segments.length ? <>{','}&nbsp;</> : ''}</Typography>
                    </Stack>
                )
            }
        </Stack>
        <Stack>
            <div style={{ fontSize: '0.9rem', lineHeight: '1.2rem' }} dangerouslySetInnerHTML={{ __html: translatedCondition }} />
        </Stack>
        <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
            {considered_travelers?.map((traveler, traveler_id) => <>
                <Stack direction={'row'} spacing={1} key={`fare-segment-traveler-${fare.id}-${traveler.id}`}>
                    <Stack justifyContent={'center'} alignItems={'center'} sx={{ borderRadius: '50%', backgroundColor: travelerColor[traveler_id % 3 as 0 | 1 | 2], width: '2.5rem', height: '2.5rem' }}><UserIcon /></Stack>
                    <Stack direction={'column'}>
                        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 500 }}>{t('shared.year', { count: traveler.age })}</Typography>
                        <Typography variant='subtitle1' sx={{ fontSize: '1rem' }}>{t(`train.comfort.${fare.product.cabin_class.comfort_category.code}`)}</Typography>
                    </Stack>
                </Stack>
            </>)}
        </Stack>
        {fare_index + 1 < fares_lenght && <Stack >
            <Divider sx={{ borderColor: 'white' }} />
        </Stack>}
    </Stack>;
}

interface TrainListContentFormTarifOfferPricingCoonditionsFareOfferProps {
    fare_offer: FareOffersEntity;
    fare_offer_index: number;
    fare_offers_length: number;
}

export const TrainListContentFormTarifOfferPricingCoonditionsFareOffer: FC<TrainListContentFormTarifOfferPricingCoonditionsFareOfferProps> = ({ fare_offer, fare_offer_index, fare_offers_length }) => {
    const { i18n } = useTranslation();
    const currencies = useSelector((state: AppState) => state.train.currencies);
    const fare_offer_prices = fare_offer.prices;

    return (<>
        <Stack direction={'row'} sx={{ padding: '32px 0' }}>
            <Stack direction={'column'} spacing={2} flex={'0 1 95%'} >
                {fare_offer.fares.map((fare, fare_index) => <TrainListContentFormTarifOfferPricingCoonditionsFare key={`fare-offer-fares-${fare_offer.id}-${fare.id}`} fare={fare} fare_index={fare_index} fares_lenght={fare_offer.fares.length} />)}
            </Stack>
            <Stack flex={'0 1 5%'}>
                <Typography variant='h3' sx={{ fontWeight: 800, fontSize: '1.5rem' }}>
                    {new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        minimumFractionDigits: (fare_offer_prices[0]?.purchase_price ?? 0) % 1 === 0 ? 0 : 2,
                        currency: currencies[fare_offer_prices[0]!.purchase_currency_id]?.iso_code || 'EUR'
                    }).format(fare_offer_prices[0]?.purchase_price ?? 0)}
                </Typography>
            </Stack>
        </Stack>
        {fare_offer_index + 1 < fare_offers_length && <Stack flex={'1 1 100%'}>
            <Divider sx={{ borderColor: 'white', borderWidth: '1.5px' }} />
        </Stack>}
    </>
    );
};


interface TrainListContentFormTarifOfferPricingCoonditionsProps {
    closeDrawer: () => void;
    train_form: TrainForm;
    setTrainFormAction: (train_form: TrainForm) => void;
    is_departure: boolean;
}
export const TrainListContentFormTarifOfferPricingCoonditions: FC<TrainListContentFormTarifOfferPricingCoonditionsProps> = ({ is_departure, train_form, closeDrawer, setTrainFormAction }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const closeOfferPriceCondition = () => {
        dispatch(setTrainFormAction({ ...train_form, offer_price_condition: undefined }));
    };
    const fare_offers = useMemo(() => train_form?.offer?.fare_offers || [], [train_form]);


    return (
        <Stack className={classes.drawerContainer}>
            <Stack justifyContent={'space-between'} direction='row' className={classes.drawerHeader}>
                <Stack direction={'row'} spacing={1} sx={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} onClick={closeOfferPriceCondition}>
                    <ArrowCircleLeftRounded sx={{ color: 'white', width: '35px', height: '35px' }} />
                    <Typography variant="subtitle1" fontSize={20} color="white">{t("train.offer.price-condition")}</Typography>
                </Stack>
                <Button onClick={closeDrawer}>{t("shared.close")}</Button>
            </Stack>
            <Stack className={classes.drawerContent}>
                {fare_offers.map((fare_offer, fare_offer_index) => <TrainListContentFormTarifOfferPricingCoonditionsFareOffer key={`fare-offer-detail-${fare_offer.id}`} fare_offer={fare_offer} fare_offer_index={fare_offer_index} fare_offers_length={fare_offers.length} />)}
            </Stack>
        </Stack>
    );
};