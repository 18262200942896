import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, paperClasses, Stack, Typography } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../Reducers/Reducers"
import { useMediaQuery, useTheme } from '@material-ui/core';
import { updateTrainListFiltersFrontCriteria, updateTrainListFiltersFrontTravelers } from "./redux/reducer"
import { useSnackbar } from "notistack"


const dialog_sx = {
  [`.${paperClasses.root}`]: {
    borderRadius: '1.5rem !important', // Set your desired border radius
  },
};
export const TrainSearchTravelersPicker: FC<{ is_open: boolean, onClose: () => void }> = ({ is_open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const traveler_available = useSelector((store: AppState) => store.trip.travelers);
  const travelers = useSelector((store: AppState) => store.train.train_list_criteria.filters.travelers);
  const travelers_error = useSelector((store: AppState) => store.train.train_list_criteria.filters.travelers_error);
  const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
  const [checked_travelers, setCheckedTravelers] = useState(travelers);
  const onValidate = () => {
    if (checked_travelers.length === 0) {
      dispatch(updateTrainListFiltersFrontCriteria({...train_list_filters_criteria, travelers_error: t('train.traveler-required')}));
      enqueueSnackbar(t('train.traveler-required'), { variant: 'error' });
      return;
    }
    dispatch(updateTrainListFiltersFrontTravelers(checked_travelers));
    onClose();
  };
  const handleChange = (traveler: any, checked: boolean) => {
    dispatch(updateTrainListFiltersFrontCriteria({...train_list_filters_criteria, travelers_error: undefined}));
    setCheckedTravelers(state => checked ? [...state, traveler] : state.filter(t => t.id !== traveler.id));
  };

  useEffect(() => {
    setCheckedTravelers(travelers);
  }, [travelers, is_open]);
  return (<Dialog maxWidth={!isMobile ? "sm" : undefined} fullScreen={isMobile} fullWidth sx={!isMobile ? dialog_sx : undefined} open={is_open} onClose={onClose}>
    {isMobile && <DialogTitle sx={{ backgroundColor: 'black' }} >
      <Stack justifyContent={'space-between'} direction='row'>
        <Stack><Typography variant="subtitle1" fontSize={20} color="white">{t("shared.travelers")}</Typography></Stack>
        <Button onClick={onClose}>{t("shared.close")}</Button>
      </Stack>
    </DialogTitle >}
    <DialogContent dividers>
      <Box sx={{ display: 'flex' }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            {t("shared.travelers")}
            <FormHelperText error>{travelers_error}</FormHelperText>
          </FormLabel>
          <FormGroup>
            {traveler_available?.map((traveler, index) => <FormControlLabel key={`travelers-${index}`} sx={{ margin: 0 }}
              control={
                <Checkbox checked={checked_travelers.some((_traveler) => _traveler.id === traveler.id)} onChange={(event) => handleChange(traveler, event.target.checked)} />
              }
              label={`${traveler.first_name} ${traveler.last_name}`}
            />)}
          </FormGroup>
        </FormControl>
      </Box>
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'center', gap: 2, backgroundColor: isMobile ? '#F3F3F8' : undefined }}>
      <Button variant="contained" className="train-date-validate-button" onClick={onValidate}>{t("shared.validate")}</Button>
    </DialogActions>
  </Dialog>
  )
}