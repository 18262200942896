//Dependencies
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useEffect } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckBoxFilled from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
//Icons
import Close from '@material-ui/icons/Close';
//Commons
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from "clsx";

const useStyles = makeStyles({
    select: {
        width: '100%'
    },
    travelersContainer: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 0 12px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    travelersError: {
        borderColor: 'red'
    },
    travelersErrorText: {
        color: 'red',
        fontSize: 12
    },
    datesErrorText: {
        color: 'red',
        fontSize: 12
    },
    descriptionContainer: {
        position: 'relative'
    },
    descriptionDelete: {
        position: 'absolute',
        right: 10,
        top: 10,
        fontSize: 20
    }
});

const AccommodationStandaloneProductsFormAccommodation = ({ roomNameError, datesError, travelersError, detail, start_date, end_date, current_groups }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [stateModified, setStateModified] = React.useState(false);
    const loading = open && options.length === 0;
    const trip_start_date = useSelector(state => state.trip.start_date);
    const trip_end_date = useSelector(state => state.trip.end_date);
    const language = useSelector(state => state.header.language);
    const address = `${detail.house_number !== null ? detail.house_number : ''} ${detail.street !== null ? detail.street : ''}, ${detail.city_name !== null ? detail.city_name : ''}`;
    const stars = detail.stars;
    const rooms = useSelector(state => state.cart.manual_product_rooms);
    const resort_fees = useSelector(state => state.cart.manual_product_resort_fees);
    const resort_fees_currency = useSelector(state => state.cart.manual_product_resort_fees_currency);
    const travelers = useSelector(state => state.trip.travelers);
    const traveler_groups = current_groups;
    const onSetDates = dates => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_DATES', payload: { start_date: dates[0] === null ? null : dates[0].format('YYYY-MM-DD'), end_date: dates[1] === null ? null : dates[1].format('YYYY-MM-DD') } });
    };
    const onAddressChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ADDRESS', payload: event.target.value });
    };
    const onStarsChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_STARS', payload: event.target.value });
    };
    const onRoomsNbChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ROOMS_NB', payload: event.target.value });
    };
    const onRoomNameChange = index => event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ROOM_NAME', payload: { index: index, value: event.target.value } });
    };
    const onRoomDescriptionChange = (index_room, index_description) => event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ROOM_DESCRIPTION', payload: { index_room: index_room, index_description: index_description, value: event.target.value } });
    };
    const onDeleteDescription = (index_room, index_description) => () => {
        dispatch({ type: 'CART_DELETE_MANUAL_PRODUCT_ROOM_DESCRIPTION', payload: { index_room: index_room, index_description: index_description } });
    };
    const onAddDescription = index_room => () => {
        dispatch({ type: 'CART_ADD_MANUAL_PRODUCT_ROOM_DESCRIPTION', payload: index_room });
    };
    const toggleTraveler = (index_room, traveler_id) => () => {
        console.log('>>>>>>>>>>>>>>>>>', index_room, traveler_id);
        dispatch({ type: 'CART_TOGGLE_MANUAL_PRODUCT_ROOM_TRAVELER', payload: { index_room: index_room, traveler_id: traveler_id } });
    };
    const onBreakfastIncludedChange = index_room => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_BREAKFAST_INCLUDED', payload: index_room });
    };
    const onLunchIncludedChange = index_room => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_LUNCH_INCLUDED', payload: index_room });
    };
    const onDinerIncludedChange = index_room => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_DINER_INCLUDED', payload: index_room });
    };
    const onAllInclusiveChange = index_room => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ALL_INCLUSIVE', payload: index_room });
    };
    const onResortFeesChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_RESORT_FEES', payload: event.target.value });
    };
    const onResortFeesCurrencyChange = (event, value) => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_RESORT_FEES_CURRENCY', payload: value });
    };
    const onSearchChange = event => {
        setSearch(event.target.value);
    };
    if (!stateModified) {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_DATES', payload: { start_date, end_date } });
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_ADDRESS', payload: address });
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_STARS', payload: stars });
        if (rooms && travelers && rooms.length && travelers.length) {
            rooms.map((room, index_room) => {
                console.log(`insert ${index_room}`);
                travelers.forEach((traveler, index_traveler) => {
                    console.log(`insert ${index_room} and ${traveler.id}`);
                });
            });
        }
        setStateModified(true);
    }
    useEffect(() => {
        let active = true;
        if (open) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                url: `${API_HREF}currencies/`,
                headers: headers,
                params: {
                    search: search,
                    limit: 10000
                }
            }).then((response) => {
                if (active) {
                    setOptions(response.data.results);
                }
            }).catch((error) => {
                console.log('error : ', error);
            });
        }
        return () => {
            active = false;
        };
    }, [loading, search]);
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    useEffect(() => {
        if (resort_fees_currency !== null) {
            setOptions([...options, resort_fees_currency]);
        }
    }, [resort_fees_currency]);
    useEffect(() => {
        let new_rooms = [];
        traveler_groups.map((group) => {
            new_rooms.push({
                name: '',
                description_list: [],
                group: group.travelers,
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            });
        });
        dispatch({ type: 'CART_MANUAL_PRODUCT_SET_ROOMS', payload: new_rooms });
    }, []);
    return (
        <Fragment>
            <Grid item xs={ 12 }>
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                    <DateRangePicker
                        startText={ t('accommodation.start_date') }
                        endText={ t('accommodation.end_date') }
                        value={ [start_date, end_date] }
                        onChange={ onSetDates }
                        minDate={ trip_start_date }
                        maxDate={ trip_end_date }
                        calendars={ 2 }
                        disabled={true}
                        desktopModeMediaQuery={ '@media (min-width: 0px)' }
                        renderInput={(startProps, endProps) => {
                            startProps.helperText = undefined;
                            startProps.fullWidth = true;
                            endProps.helperText = undefined;
                            endProps.fullWidth = true;
                            return (
                                <Fragment>
                                    <TextField {...startProps} error={ datesError }/>
                                    <DateRangeDelimiter>{ t('accommodation.to') }</DateRangeDelimiter>
                                    <TextField {...endProps} error={ datesError }/>
                                </Fragment>
                            );
                        }}
                    />
                </LocalizationProvider>
                { datesError && <Typography className={ classes.datesErrorText }>{ t('cart-material.manual-product-no-dates') }</Typography> }
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'manual-product-address' }>{ t('cart-material.manual-product-address') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-address' }
                        type={ 'text' }
                        onChange={ onAddressChange }
                        label={ t('cart-material.manual-product-address') }
                        disabled={true}
                        value={ address !== null ? address : null }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel id={ 'manual-product-stars-label' }>{ t('cart-material.manual-product-stars') }</InputLabel>
                    <Select className={ classes.select } 
                        labelId={ 'manual-product-stars-label' } 
                        label={ t('cart-material.manual-product-stars') } 
                        value={ stars } 
                        onChange={ onStarsChange }
                        disabled={true}>
                        <MenuItem value={ 0 }>0</MenuItem>
                        <MenuItem value={ 1 }>1</MenuItem>
                        <MenuItem value={ 2 }>2</MenuItem>
                        <MenuItem value={ 3 }>3</MenuItem>
                        <MenuItem value={ 4 }>4</MenuItem>
                        <MenuItem value={ 5 }>5</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel id={ 'manual-product-nb-room-label' }>{ t('cart-material.manual-product-nb-room') }</InputLabel>
                    <Select className={ classes.select } labelId={ 'manual-product-nb-room-label' } label={ t('cart-material.manual-product-nb-room') }
                        value={ rooms.length } onChange={ onRoomsNbChange }
                        disabled={true}>
                        <MenuItem value={ 0 }>0</MenuItem>
                        <MenuItem value={ 1 }>1</MenuItem>
                        <MenuItem value={ 2 }>2</MenuItem>
                        <MenuItem value={ 3 }>3</MenuItem>
                        <MenuItem value={ 4 }>4</MenuItem>
                        <MenuItem value={ 5 }>5</MenuItem>
                        <MenuItem value={ 6 }>6</MenuItem>
                        <MenuItem value={ 7 }>7</MenuItem>
                        <MenuItem value={ 8 }>8</MenuItem>
                        <MenuItem value={ 9 }>9</MenuItem>
                        <MenuItem value={ 10 }>10</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {
                rooms.map((room, index_room) => (
                    <Fragment key={ `manual-product-room-${index_room}` }>
                        <Grid item xs={ 12 }>
                            <Typography>{ detail.type === 5 ? t('custom-accommodation.info.type-list.cabin') : t('global.room') } : { index_room + 1 }</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <FormControl fullWidth variant={ 'outlined' } error={ roomNameError && (room.name === null || room.name === '') }>
                                <InputLabel htmlFor={ 'manual-product-room-name' }>{ t('cart-material.manual-product-room-name') }</InputLabel>
                                <OutlinedInput
                                    id={ 'manual-product-room-name' }
                                    type={ 'text' }
                                    onChange={ onRoomNameChange(index_room) }
                                    label={ t('cart-material.manual-product-room-name') }
                                    value={ room.name !== null ? room.name : '' }
                                />
                                { roomNameError && (room.name === null || room.name === '') && <FormHelperText>{ t('cart-material.manual-product-no-room-name') }</FormHelperText> }
                            </FormControl>
                        </Grid>
                        {
                            room.description_list.map((description, index_description) => (
                                <Grid className={ classes.descriptionContainer } item xs={ 12 }>
                                    <FormControl fullWidth variant={ 'outlined' }>
                                        <InputLabel htmlFor={ `manual-product-room-description-${index_description}` }>{ t('cart-material.manual-product-room-description') }</InputLabel>
                                        <OutlinedInput
                                            id={ `manual-product-room-description-${index_description}` }
                                            type={ 'text' }
                                            multiline
                                            rows={ 3 }
                                            onChange={ onRoomDescriptionChange(index_room, index_description) }
                                            label={ t('cart-material.manual-product-room-description') }
                                            value={ description !== null ? description : '' }
                                        />
                                    </FormControl>
                                    { room.description_list.length > 1 && <Close className={ classes.descriptionDelete } onClick={ onDeleteDescription(index_room, index_description) }/> }
                                </Grid>
                            ))
                        }
                        <Grid item xs={ 12 }>
                            <Button variant={ 'contained' } color={ 'primary' } onClick={ onAddDescription(index_room) }>{ t('cart-material.manual-product-room-add-description') }</Button>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <div className={ clsx(classes.travelersContainer, { [classes.travelersError]: travelersError }) }>
                                <Typography>{ t('cart-material.manual-product-travelers') } :</Typography>
                                { travelers.map((traveler, index_traveler) => <FormControlLabel control={ <Checkbox disabled={true} checked={ room.group?.includes(traveler.id) } onChange={ toggleTraveler(index_room, traveler.id) }/> } label={ `${traveler.first_name === t('global.first_name') ? t('global.adult') : traveler.first_name} ${traveler.last_name === t('global.last_name') ? index_traveler + 1 : traveler.last_name}` }/>) }
                            </div>
                            { travelersError && <Typography className={ classes.travelersErrorText }>{ t('cart-material.manual-product-no-room-comp') }</Typography> }
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ room.breakfast_included } onChange={ onBreakfastIncludedChange(index_room) }/> } label={ t('accommodation.breakfast_included') }/>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ room.lunch_included } onChange={ onLunchIncludedChange(index_room) }/> } label={ t('accommodation.lunch_included') }/>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ room.dinner_included } onChange={ onDinerIncludedChange(index_room) }/> } label={ t('accommodation.dinner_included') }/>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ room.all_inclusive } onChange={ onAllInclusiveChange(index_room) }/> } label={ t('cart-material.manual-product-room-all-inclusive') }/>
                        </Grid>
                    </Fragment>
                ))
            }
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'manual-product-resort-fees' }>{ t('cart-material.manual-product-resort-fees') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-resort-fees' }
                        type={ 'number' }
                        onChange={ onResortFeesChange }
                        label={ t('cart-material.manual-product-resort-fees') }
                        value={ resort_fees !== null ? resort_fees : '' }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <Autocomplete
                    id={ 'manual-product-resort-fees-currency' }
                    open={ open }
                    onOpen={() => {
                        setOpen(true);
                        setSearch('');
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    clearOnBlur={ true }
                    clearOnEscape={ true }
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.international_name}
                    options={ options }
                    loading={ loading }
                    value={ resort_fees_currency }
                    onChange={ onResortFeesCurrencyChange }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={ t('cart-material.manual-product-resort-fees-currency') }
                            variant={ 'outlined' }
                            onChange={ onSearchChange }
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color={ 'inherit' } size={ 20 }/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}
                />
            </Grid>
        </Fragment>
    );
};

export default AccommodationStandaloneProductsFormAccommodation;
