import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { browserHistory } from "react-router";
import { useShowError } from "../Utils/showError";
import { updateTrainListOutbound, updateTrainListLoaded, updateTrainListLoading, updateTrainListVisibleItem, setCurrencies, updateTrainListReturn, setTrainTravelers, updateTrainDepartureForm, updateTrainReturnForm, updateTrainReturnSelected, updateTrainDepartureSelected } from "./redux/reducer";
import { searchTrains } from "./services/trainServices";
import { getTrainFilterCriteria } from "./utils/mapping/getTrainCriteria";
import { Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTrainListForUi } from "./utils/mapping/getTrainForUI";
import axios, { CancelTokenSource } from "axios";
import { TrainListHeader } from "./trainListHeader";
import { TrainListContent } from "./trainListContent";
import { getCurrencies } from "./services/currencyServices";
import { TrainListContentForm } from "./trainLIstContentForm";

let timeout: NodeJS.Timeout;
let cancelToken: CancelTokenSource;

const TrainList = () => {
    const { t } = useTranslation();
    const train_departure_selected = useSelector(
        (state: AppState) => state.train.train_departure_selected
    );
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const train_trip_list_outbound = useSelector((state: AppState) => state.train.train_trip_list_outbound);
    const train_trip_list_return = useSelector((state: AppState) => state.train.train_trip_list_return);
    const departure_date = useSelector((state: AppState) => state.train.train_list_criteria.filters.departure);

    return (
        <>
            <TrainListHeader />
            {train_departure_selected && can_return ? (
                <TrainListContent
                    title={t("global.Return")}
                    train_trip_list={train_trip_list_return}
                    minDate={departure_date}
                    return_required={true}
                // no_search_button={true}
                />
            ) : (
                <TrainListContent
                    title={t("global.go")}
                    train_trip_list={train_trip_list_outbound}
                // no_search_button={true}
                />
            )}
            <TrainListContentForm />
        </>
    );
};

const TrainListRoot = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showError = useShowError();
    const train_list_loading = useSelector((state: AppState) => state.train.train_list_loading);
    const train_list_loaded = useSelector((state: AppState) => state.train.train_list_loaded);
    const train_list_filters_criteria = useSelector((state: AppState) => state.train.train_list_criteria.filters);
    const train_departure_selected = useSelector((state: AppState) => state.train.train_departure_selected);
    const can_return = useSelector((state: AppState) => state.train.train_list_criteria.filters.can_return);
    const currencies = useSelector((state: AppState) => state.train.currencies);

    const is_departure = useMemo(() => !(train_departure_selected && can_return), [train_departure_selected, can_return]);
    const updateSearchCriteria = async () => {

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
        cancelToken = axios.CancelToken.source();
        try {
            dispatch(updateTrainListLoading(true));
            // const searchParams = getTrainFilterCriteria(train_list_filters_criteria, { departure: is_departure, return: !is_departure });
            const searchParams = getTrainFilterCriteria(train_list_filters_criteria);
            const requests = searchParams.map(params => searchTrains(params, cancelToken.token));
            const responses = await Promise.all(requests);
            const data = responses[0]?.data; // TODO : update when new provider arrive
            // const setListAction = !is_departure ? updateTrainListReturn : updateTrainListOutbound;
            const { train_trip_departure, train_trip_return, travelers } = getTrainListForUi(data);
            dispatch(updateTrainListOutbound(train_trip_departure));
            if(can_return) dispatch(updateTrainListReturn(train_trip_return ?? []));
            dispatch(setTrainTravelers(travelers));
            dispatch(updateTrainListLoaded(true));
            dispatch(updateTrainListVisibleItem(5));
            dispatch(updateTrainListLoading(false));
        } catch (error) {
            if (!axios.isCancel(error)) {
                showError(error as Error);
                console.error(error);
                dispatch(updateTrainListLoading(false));
            }
        }
    };
    const loadCurrencies = async () => {
        try {
            const currencies = await getCurrencies();
            dispatch(setCurrencies(currencies.data.results));
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (train_list_filters_criteria.origin && train_list_filters_criteria.destination && is_departure) {
            clearTimeout(timeout);
            timeout = setTimeout(() => updateSearchCriteria(), 500);
        }
    }, [is_departure]);

    useEffect(() => {
        if (!train_list_filters_criteria.origin || !train_list_filters_criteria.destination) {
            browserHistory.push(`/${window.url_name}/apps/train/search`);
        } else {
            if (Object.values(currencies).length < 1) {
                loadCurrencies();
            }
        }
        dispatch(updateTrainDepartureForm(null));
        dispatch(updateTrainReturnForm(null));
        dispatch(updateTrainReturnSelected(null));
        dispatch(updateTrainDepartureSelected(null));
    }, []);
    return <Container maxWidth={"lg"}>
        <Stack gap={1} direction='column'>
            {
                !train_list_loaded && train_list_loading ?
                    <div style={{ position: 'relative', width: '100%', minHeight: '300px' }}>
                        <Typography variant={'h5'} className={'product-title'} gutterBottom sx={{ paddingTop: '24px', margin: 'auto' }}>{t('shared.search-trip-offer-loading')}...</Typography>

                        <div style={{ width: "100%", height: '200px', position: 'absolute', zIndex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="train-loader" style={{ margin: 'auto' }} />
                        </div>
                    </div>
                    : (train_list_loaded ? <TrainList /> : (() => { browserHistory.push(`/${window.url_name}/apps/train/search`); return <></> })())
            }
        </Stack>
    </Container>;
};

export default TrainListRoot;