import moment from "moment";

export const combineDateAndTimeWithLocalTimezone = <T extends Date | null>(date: T, time: string): T => {
    if (!date || !time) return null as null as T;

    const [hours, minutes] = time.split('h').map(Number);
    const localMoment = moment(date);

    localMoment.set({
        hour: hours,
        minute: 0,
        second: 0,
        millisecond: 0
    });

    return localMoment.toDate() as Date as T; // Converts moment to a JavaScript Date in the local timezone
};