import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
    Box,
    Divider,
    Paper
} from "@mui/material";
import LockedItems from "./LockedItems";
import CartFlights from "./CartFlights";
import CartCars from "./CartCars";
import CartTransfers from "./CartTransfers";
import CartAccommodations from "./CartAccommodations";
import CartPois from "./CartPois";
import CartAssistances from "./CartAssistances";
import CartManualProducts from "./CartManualProducts";
import { CartConstructionProductsHeader } from "./CartConstructionProductsHeader";
import { CartConstructionProductsByType } from "./CartConstructionProductsByType";
import { CartConstructionProductsByDay } from "./CartConstructionProductsByDay";
import { CartConstructionProductsPackages } from "./CartConstructionProductsPackages";
import { CartAnnexProducts } from "./CartAnnexProducts";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import { usePackagedProducts } from "./utils/packagedProducts";
import { useUnpackagedProducts } from "./utils/unpackagedProduts";
import { AppState } from "../../Reducers/Reducers";
import { TrainCart } from "../train/trainCart";

type Props = {
    stickedActions?: (content: React.ReactNode) => React.ReactNode,
    extraStickedActions?: () => React.ReactNode,
    enableBooking?: boolean,
    stickyOffset?: number,
    fullHeight?: boolean,
    fullWidth?: boolean,
    disableElevation?: boolean
}

export const CartConstructionProducts = withRouter<Props>(
    function CartConstructionProducts(props): JSX.Element {
        const [cartDisplay, setCartDisplay] = useState<'product' | 'chronological'>('product');
        const contextValue = useMemo(() => {
            return { enableBooking: props.enableBooking };
        }, [props.enableBooking]);
    
        useEffect(() => {
            const savedView: typeof cartDisplay = localStorage.getItem(
                'cart-construction-cart-display'
            ) as typeof cartDisplay | null ?? 'product';
            setCartDisplay(savedView);
        }, []);
    
        useEffect(() => {
            localStorage.setItem('cart-construction-cart-display', cartDisplay);
        }, [cartDisplay]);
    
        return (
            <CartConstructionProductsContext.Provider
                value={contextValue}
            >
                <Paper
                    elevation={props.disableElevation ? 0 : 2}
                    sx={(theme) => ({
                        padding: !props.fullWidth ? 2.5 : 0,
                        paddingTop: 0,
                        height: !props.fullHeight ?
                            '100vh' :
                            undefined,
                        maxHeight: !props.fullHeight ?
                            `calc(100vh - 78px - ${theme.spacing(4)})` :
                            undefined,
                        overflowY: !props.fullHeight ?
                            'auto' :
                            undefined
                    })}
                >
                    <CartConstructionProductsHeader
                        page="products"
                        stickedActions={props.stickedActions}
                        extraStickedActions={props.extraStickedActions}
                        cartDisplay={cartDisplay}
                        onToggleCartDisplay={setCartDisplay}
                        stickyOffset={props.stickyOffset}
                        fullHeight={props.fullHeight}
                    />
                    <Divider sx={{ marginTop: 1 }} />
                    <ContentWrapper cartDisplay={cartDisplay} />
                </Paper>
            </CartConstructionProductsContext.Provider>
        );
    }
);

type ContentWrapperProps = {
    cartDisplay: 'product' | 'chronological'
}

const ContentWrapper = React.memo(
    function ContentWrapper(props: ContentWrapperProps): JSX.Element {
        const view = useSelector((state: AppState) => state.cartConstruction.productsView);
        const unpackagedProducts = useUnpackagedProducts();
        const packagedProducts = usePackagedProducts();

        return (
            <>
                {
                    view === 'cards' &&
                    <Box sx={{ marginTop: 3 }}>
                        <LockedItems />
                        {
                            props.cartDisplay === 'product' && (
                                <>
                                    <CartFlights />
                                    <TrainCart />
                                    <CartTransfers />
                                    <CartCars />
                                    <CartAccommodations />
                                    <CartPois />
                                    <CartAssistances />
                                    <CartAnnexProducts />
                                    <CartManualProducts />
                                </>
                            )
                        }
                        {
                            props.cartDisplay === 'chronological' && (
                                <CartConstructionProductsByDay
                                    cart={unpackagedProducts}
                                    enableCardView
                                />
                            )
                        }
                    </Box>
                }
                {
                    view === 'table' &&
                    <Box sx={{ marginTop: 3 }}>
                        {
                            Object.values(packagedProducts).length > 0 &&
                            <CartConstructionProductsPackages
                                cartDisplay={props.cartDisplay}
                                cart={packagedProducts}
                            />
                        }
                        {
                            props.cartDisplay === 'product' &&
                            <CartConstructionProductsByType cart={unpackagedProducts} />
                        }
                        {
                            props.cartDisplay === 'chronological' &&
                            <CartConstructionProductsByDay cart={unpackagedProducts} />
                        }
                    </Box>
                }
            </>
        );
    }
);
