export enum MarketingInformationIcon {
    EUROSTAR_ER = "EUROSTAR",
    RENFE_RENFE = "renfe",
    BENE_INTERCITY = "Benerail",
    RENFE_AVE = "renfe",
    OUIGOESP_OUIGO_ESP = "ouigo_esp",
    TRENITALIA_TRENITALIA = "trenitalia",
    TRENITALIA_INTERCITY_NOTTE = "trenitalia",
    TRENITALIA_INTERCITY = "trenitalia",
    TRENITALIA_FRECCIARGENTO = "trenitalia_frecciargento",
    TRENITALIA_FRECCIABIANCA = "trenitalia_frecciabianca",
    TRENITALIA_FRECCIAROSSA = "trenitalia_frecciarossa",
    TRENITALIA_FRECCIAROSSA_1000 = "trenitalia",
    TRENITALIA_REGIONALEVELOCE = "regionaleveloce",
    TRENITALIA_EUROCITY = "trenitalia",
    NTV_ITALO = "italo",
    DISTRIBUSION_IRYO = "iryo",
    DISTRIBUSION_IXBS = "iryo",
    RHB = "rhb",
    DBAHN = "dbahn",
    DBAHN_DB_BAHN_INTERCITY = "dbahn",
    DBAHN_DB_BAHN_EUROCITY = "dbahn_db_bahn_eurocity",
    DBAHN_INTERCITY_EXPRESS = "dbahn_intercity_express",
    SBB_INTERCITY = "sbb_intercity",
    SBB_INTERCITYEXPRESS = "sbb_intercityexpress",
    SBB_INTERREGIO = "sbb_interregio",
    SBB_PANORAMAEXPRESS = "sbb_panoramaexpress",
    SBB_REGIOEXPRESS = "sbb_regioexpress",
    SBB_RAILJETEXPRESS = "sbb_railjetexpress",
    SBB_REGIO = "sbb_regio",
    SBB_URBAN_TRAIN = "sbb_urban_train",
    SBB_EUROCITY = "sbb_eurocity",
    RDG_ABELLIO_GREATER_ANGLIA = "rdg_abellio_greater_anglia",
    RDG_AVANTI_WEST_COAST = "rdg_avanti_west_coast",
    RDG_C2C = "rdg_c2c",
    RDG_CALEDONIAN_SLEEPER = "rdg_caledonian_sleeper",
    RDG_CROSSCOUNTRY = "rdg_crosscountry",
    RDG_EAST_MIDLANDS_RAILWAY = "rdg_east-midlands_railway",
    RDG_GATWICK_EXPRESS = "rdg_gatwick_express",
    RDG_GRAND_CENTRAL_RAIL = "rdg_grand_central_rail",
    RDG_GREAT_NORTHERN = "rdg_great_northern",
    RDG_GWR = "rdg_gwr",
    RDG_HEATHROW_EXPRESS = "rdg_heathrow_express",
    RDG_HULL_TRAINS = "rdg_hull_trains",
    RDG_LONDON_MIDLAND = "rdg_london_midland",
    RDG_LONDON_NORTHWESTERN_RAILWAY = "rdg_london_northwestern_railway",
    RDG_LONDON_NORTH_EASTERN_RAILWAY = "rdg_london_northe_astern_railway",
    RDG_LONDON_OVERGROUND = "rdg_london_overground",
    RDG_LONDON_UNDERGROUND = "rdg_london_underground",
    RDG_LUMO = "rdg_lumo",
    RDG_MERSEYRAIL = "rdg_merseyrail",
    RDG_NORTHERN_RAIL = "rdg_northern_rail",
    RDG_SCOTRAIL = "rdg_scotrail",
    RDG_SOUTHEASTERN = "rdg_southeastern",
    RDG_SOUTH_WEST_TRAINS = "rdg_south_west_trains",
    RDG_STANSTED_EXPRESS = "rdg_stansted_express",
    RDG_TFL_RAIL = "rdg_tfl_rail",
    RDG_THAMES_LINK = "rdg_thames_link",
    RDG_TRANSPORT_FOR_WALES = "rdg_transport_for_wales",
    RDG_CHILTERN_RAILWAYS = "RDG_CHILTERN_RAILWAYS",
    OBB_S_BAHN = "OBB",

    // PASS
    SBB = "Swiss e-Passes",
    EURAIL = "Eurail Passes",

    PAO_SNCF = "",
    UNKNOWN = "UNKNOWN"
  }

  export enum MarketingInformationIconPAO {
    "TGV INOUI" = "tgvinoui",
    TGV = "tgv",
    ICE = "dbsncf",
    Lyria = "lyria",
    Intercites = "intercites",
    Navette = "ter",
    Autocar = "ter",
    TER = "ter",
    Thalys = "thalys"
  }
  