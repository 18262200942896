import React from "react";
import { FC } from "react";
import { TripTrainLeg } from "./objects/trainCart";
import { TrainCartFromBackend } from "./objects/backendData/cart/trainCartFromBackend";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { cloneDeep } from "lodash";

interface TrainCartSegmentInfoPerPaxProps {
    train: TrainCartFromBackend;
    trip_leg: TripTrainLeg;
}

export const TrainCartSegmentInfoPerPax: FC<TrainCartSegmentInfoPerPaxProps> = ({ train, trip_leg }) => {
    const { t } = useTranslation();
    // const currency = useSelector((state: AppState) => state.trip.currency);
    // const language = useSelector((state: AppState) => state.header.tmp_language);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);

    let travelers_order = traveler_groups.reduce(function (flattenedArray, element) {
        return flattenedArray.concat(element.travelers_order ? element.travelers_order : []);
    }, [] as number[]);
    let trainTravelers = cloneDeep(train.group_passenger?.travelers_list ?? []);
    const tripTravelers = [...travelers ?? []];

    if (travelers_order.length !== 0) {
        trainTravelers.sort((a, b) => {
            return travelers_order.indexOf(a.id) - travelers_order.indexOf(b.id);
        });
        tripTravelers.sort((a, b) => {
            return travelers_order.indexOf(a.id) - travelers_order.indexOf(b.id);
        });
    }

    return (<Box
        sx={{
            flex: 1,
            marginRight: 3.5
        }}
    >
        {
            trainTravelers.map((traveler) => {
                const index = tripTravelers.findIndex((item) => {
                    return item.id === traveler.id;
                });
                return (
                    <Accordion sx={{ backgroundColor: 'inherit' }} key={index}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                {
                                    t(
                                        'cart-material.cart-construction-traveler-no',
                                        { no: index + 1 }
                                    )
                                }
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                color="GrayText"
                                component="div"
                            >
                                {t('cart-material.cart-construction-traveler-info-header')}
                            </Typography>
                            <Typography
                                color="InfoText"
                                component="div"
                            >
                                {traveler.title} / {traveler.first_name} / {traveler.last_name}
                            </Typography>
                            <Typography
                                color="GrayText"
                                component="div"
                            >
                                {
                                    t(
                                        'cart-material.cart-construction-traveler-birthdate',
                                        {
                                            date: window.moment.utc(traveler.birth_date, 'YYYY-MM-DD').format('L')
                                        }
                                    )
                                }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })
        }
    </Box>);
}